import { useState, useRef, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import InputAdornmentFiled from '@design-system/InputAdornmentFiled';
import InputAdornment from '@mui/material/InputAdornment';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';

import IconButton from '@design-system/IconButton';
import IconChevron from '@experimental-components/IconsComponents/ChevronBase';

import { getUnitTimeFromStr, fillArrayTime, formatUnitTime } from 'utils/times';

import useStyles from './styles';
import TextTimeMask from './textTimeMask';

const MAX_HOUR = 23;
const MAX_MINUTE = 59;

function TimeCustomPicker({
  id = 'timeCustomerPickerId',
  value,
  onChange,
  minutesInterval = 1,
  readOnly = true,
  ...others
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopOver, setOpenPopOver] = useState(false);
  const itemsHoursEls = useRef([]);
  const itemsMinutesEls = useRef([]);

  const MINUTES = useMemo(() => {
    return fillArrayTime(MAX_MINUTE, minutesInterval);
  }, [minutesInterval]);

  const HOURS = useMemo(() => {
    return fillArrayTime(MAX_HOUR);
  }, []);

  const scrollToElement = () => {
    const time = value.split(':');
    const hour = Number(time[0]);
    const minute = Number(time[1]);
    const indexHour = HOURS.findIndex((h) => h === hour);
    const indexMinute = MINUTES.findIndex((m) => m === minute);

    itemsHoursEls.current[indexHour]?.scrollIntoView();
    itemsMinutesEls.current[indexMinute]?.scrollIntoView();
  };

  function handleIcon() {
    if (openPopOver) {
      itemsHoursEls.current = [];
    }
    setOpenPopOver(!openPopOver);
  }

  function handleOnSetTime(type, newValue) {
    const time = value.split(':');
    let val = newValue;
    if (newValue <= 9) val = `0${newValue}`;
    if (type === 'hour') time[0] = val;
    else time[1] = val;
    onChange(time.join(':'));
  }

  function handleChangeTime(newValue) {
    onChange(newValue);
  }

  function setElementHour(element, index) {
    itemsHoursEls.current[index] = element;
  }

  function setElementMinute(element, index) {
    itemsMinutesEls.current[index] = element;
  }

  useEffect(() => {
    if (openPopOver) {
      setTimeout(scrollToElement, 100);
    }
    if (!openPopOver && itemsHoursEls.current.length > 0) {
      itemsHoursEls.current = [];
    }
    if (!openPopOver && itemsMinutesEls.current.length > 0) {
      itemsMinutesEls.current = [];
    }
  }, [openPopOver, itemsHoursEls, itemsMinutesEls]);

  return (
    <>
      <Box className={classes.inputSelect} onClick={handleIcon}>
        <InputAdornmentFiled
          data-testid={`input${id}`}
          endAdornment={
            <InputAdornment position="end">
              <IconButton data-testid={`button${id}`} size="large">
                <IconChevron className={clsx({ [classes.popupIndicatorOpen]: openPopOver })} color="#000" />
              </IconButton>
            </InputAdornment>
          }
          fullWidth
          inputComponent={TextTimeMask}
          onChange={(evt) => handleChangeTime(evt.target.value)}
          onClick={(evt) => setAnchorEl(evt.currentTarget)}
          readOnly={readOnly}
          type="text"
          value={value}
          {...others}
        />
      </Box>

      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        data-testid={`popover${id}`}
        onClose={() => setOpenPopOver(false)}
        open={openPopOver}
        style={{ marginTop: 6 }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          className={classes.menu}
          data-testid={`wrapper${id}`}
          id={`wrapper${id}`}
          style={{ width: anchorEl?.offsetWidth || '100%' }}
        >
          <Box className={classes.menuColumn}>
            {HOURS.map((h, index) => (
              <Box key={h} ref={(element) => setElementHour(element, index)} data-testid={`hour-${h}`}>
                <IconButton
                  className={clsx({ [classes.active]: h === getUnitTimeFromStr(value, 'hour') })}
                  onClick={() => handleOnSetTime('hour', h)}
                  size="large"
                >
                  {formatUnitTime(h)}
                </IconButton>
              </Box>
            ))}
          </Box>
          <Box className={classes.menuColumn}>
            {MINUTES.map((m, index) => (
              <Box key={m} ref={(element) => setElementMinute(element, index)} data-testid={`minute-${m}`}>
                <IconButton
                  className={clsx({ [classes.active]: m === getUnitTimeFromStr(value, 'minute') })}
                  onClick={() => handleOnSetTime('minute', m)}
                  size="large"
                >
                  {formatUnitTime(m)}
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default TimeCustomPicker;
