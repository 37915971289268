/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useFeatures } from 'FeatureProvider';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ChevronDown from '@experimental-components/IconsComponents/ChevronDown';
import ErrorCircle from '@experimental-components/IconsComponents/Alert';

import { getErrorPublishMenusInProviders } from 'utils/menuMaker';

import { getValidateItemsAvailabilityFlags } from 'utils/featureFlags/menus';

import SelectProvider from './SelectProvider';
import TableMenusForBrand from './TableMenusForBrand';
import useStyles from './styles';

function RowBrand({
  brand,
  openDefault = false,
  onConfirmPublish,
  onResetOptions,
  resetOptions,
  refScroll,
  onOpenErrorsDetails,
}) {
  const classes = useStyles();
  const [openRow, setOpenRow] = useState(openDefault);

  const { flags } = useFeatures();
  const { enableValidateItemsAvailability } = getValidateItemsAvailabilityFlags(flags);

  const handleOpenBrand = () => {
    setOpenRow((state) => !state);
  };

  function handelOnViewDetailsErrors(provider) {
    onOpenErrorsDetails(brand, provider);
  }

  return (
    <Box className={classes.rootRow}>
      <Box ref={refScroll} className={classes.headerRow} onClick={() => handleOpenBrand()}>
        <Box
          className={clsx(classes.labelRow, {
            [classes.brandPublishError]:
              enableValidateItemsAvailability &&
              (brand?.state === 'ERROR' ||
                brand?.state === 'REJECTED' ||
                getErrorPublishMenusInProviders(brand?.menus)),
          })}
        >
          {enableValidateItemsAvailability &&
            (brand?.state === 'ERROR' ||
              brand?.state === 'REJECTED' ||
              getErrorPublishMenusInProviders(brand?.menus)) && <ErrorCircle colorInherit="#FFF" size={20} />}
          {brand?.name}
        </Box>

        <Box className={classes.contentRightRow}>
          <SelectProvider
            brand={brand}
            disabled={!brand?.menus?.length}
            onResetProviderSelected={onResetOptions}
            onSelectProvider={onConfirmPublish}
            resetProviderSelected={resetOptions}
          />

          <ChevronDown
            className={clsx(classes.iconInitialState, { [classes.iconRotate]: openRow })}
            color="#000"
            lighter
            size={24}
          />
        </Box>
      </Box>
      <Collapse in={openRow}>
        <TableMenusForBrand menus={brand?.menus} onOpenErrorsDetails={handelOnViewDetailsErrors} />
      </Collapse>
    </Box>
  );
}

RowBrand.propTypes = {
  brand: PropTypes.object,
  openDefault: PropTypes.bool,
  refScroll: PropTypes.object,
};

export default RowBrand;
