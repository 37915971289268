import { useQuery } from 'react-query';
import { getProfile } from 'containers/SignIn/services';
import {
  removeAuthToken,
  removeCookieUser,
  removeOrganizationUser,
  removeStoreBackupKey,
  removeStoreDratfKey,
  removeUserCurrentStore,
  removeUserStoresStoreKey,
} from 'utils/authTyped';
import { removeBrandSelectedLocalStorage } from 'utils/menuMaker';
import history from 'utils/history';

export function useProfile(options: { isEnabled: boolean }) {
  const { data, isFetching, isFetched, isError, refetch, remove } = useQuery(['profile'], () => getProfile(), {
    enabled: options.isEnabled,
    onSuccess: () => {},
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      if (error.response && error.response.status === 401) {
        removeAuthToken();
        removeUserStoresStoreKey();
        removeUserCurrentStore();
        removeStoreDratfKey();
        removeStoreBackupKey();
        removeBrandSelectedLocalStorage();
        removeOrganizationUser();
        removeCookieUser();
        history.push({
          pathname: '/logged-out',
        });
      }
    },
  });

  return {
    profileDataState: {
      isError,
      isFetched,
      isFetching,
    },
    removeProfileData: remove,
    profileData: data,
    getProfileData: refetch,
  };
}
