/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { skuLabelsDefinition } from 'utils/menuMaker';
import Typography from '@mui/material/Typography';

import { useGetSkuList } from 'providers/menuMaker/Sku/hooks';

import SkuSkeleton from './SkuSkeleton';
import TransferSKU from './TransferSKU';
import useStyles from './styles';

interface Props {
  type: string;
  optionSelected: string;
  onSelectOption: (value: string) => void;
  t: (value: string) => string;
}

function SelectSKU({ type, optionSelected, onSelectOption, t }: Props) {
  const classes = useStyles();
  const scrollPaginationRef = useRef(null);
  const skuLabels = skuLabelsDefinition[type];

  const [filter, setFilter] = useState<null | string>(null);
  const { skuList, loadSkuList, skuListState, paginateSkuList, resetSkuList } = useGetSkuList(type, filter);

  useEffect(() => {
    loadSkuList();

    return () => {
      resetSkuList();
    };
  }, []);

  useEffect(() => {
    resetSkuList();
    loadSkuList();
  }, [filter]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting && skuListState.hasNextPage) {
          paginateSkuList();
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return () => {
      observer.disconnect();
    };
  }, [scrollPaginationRef, skuListState.hasNextPage, skuList]);

  const handleSearchOption = (value: string | null) => {
    setFilter(value);
  };

  const handleChange = (value) => {
    if (value) {
      const newValue = skuLabels.prefix !== 'AR-' && value.slice(0, 3) === skuLabels.prefix ? value.slice(3) : value;
      onSelectOption(newValue);
    } else {
      onSelectOption(value);
    }
  };

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography className={classes.subtitles}>{t(skuLabels.title)}</Typography>

        <Box mb={3}>
          <Typography variant="body2">{t(skuLabels.description)}</Typography>
        </Box>

        {skuListState.fetching && filter === null && !skuListState.fetched ? (
          <SkuSkeleton.Transfer />
        ) : (
          <TransferSKU
            isSearchFilter={Boolean(filter)}
            labelOptionsAvailable={skuLabels.optionsLabel}
            labelOptionsSelected={skuLabels.optionSelected}
            onChange={handleChange}
            onSearch={handleSearchOption}
            options={skuList}
            optionSelected={
              optionSelected && skuLabels.prefix !== 'AR-' ? `${skuLabels.prefix}${optionSelected}` : optionSelected
            }
            placeholderLabel={skuLabels.placeholderInputSearch}
            scrollPaginationRef={scrollPaginationRef}
            skuListState={skuListState}
            t={t}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default SelectSKU;
