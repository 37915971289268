import DialogActionsMui from '@mui/material/DialogActions';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 48,
    paddingTop: 0,

    '& > :not(:first-child)': {
      marginLeft: theme.spacing(5),
    },
  },
}));

function DialogActions({ ...others }) {
  const classes = useStyles();

  return <DialogActionsMui classes={{ root: classes.root }} {...others} />;
}

DialogActions.propTypes = {
  ...DialogActionsMui.propTypes,
};

export default DialogActions;
