import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import useStyles from './styles';

function HelperSkeleton({ type = null }) {
  const classes = useStyles();

  if (type === 'contentCategory') {
    return [...Array(3).keys()].map((rowProduct) => (
      <Box key={`skeleton-table-resume-row-category-det-product-${rowProduct}`} className={classes.headerRow}>
        <Box className={classes.contentLeftRowProduct} width="100%">
          <Skeleton height={40} variant="rectangular" width={40} />
          <Box width="90%">
            <Skeleton height={40} variant="rectangular" />
          </Box>
        </Box>
        <Box className={classes.contentRightRowProduct}>
          <Box>
            <Skeleton height={30} variant="rectangular" width={100} />
          </Box>
          <Box height={16} width={16}>
            <Skeleton height={16} variant="rectangular" width={4} />
          </Box>
        </Box>
      </Box>
    ));
  }

  if (type === 'contentMenu') {
    return [...Array(2).keys()].map((row) => (
      <Box key={`skeleton-table-resume-row-category-${row}`} mb={2}>
        <Box className={classes.headerRow}>
          <Box>
            <Skeleton height={30} variant="rectangular" width={200} />
          </Box>
          <Box className={classes.contentLeftRow}>
            <Box>
              <Skeleton height={30} variant="rectangular" width={100} />
            </Box>
            <Skeleton height={16} variant="rectangular" width={16} />
          </Box>
        </Box>
        {[...Array(3).keys()].map((rowProduct) => (
          <Box key={`skeleton-table-resume-row-category-product-${rowProduct}`} className={classes.headerRow}>
            <Box className={classes.contentLeftRowProduct} width="100%">
              <Skeleton height={40} variant="rectangular" width={40} />
              <Box width="90%">
                <Skeleton height={40} variant="rectangular" />
              </Box>
            </Box>
            <Box className={classes.contentRightRowProduct}>
              <Box>
                <Skeleton height={30} variant="rectangular" width={100} />
              </Box>
              <Box height={16} width={16}>
                <Skeleton height={16} variant="rectangular" width={4} />
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    ));
  }

  return (
    <>
      {[...Array(4).keys()].map((row) => (
        <Box key={`skeleton-table-resume-row-${row}`} className={classes.rootRow}>
          <Box className={classes.headerRow}>
            <Box>
              <Box mb={1}>
                <Skeleton height={40} variant="text" width={200} />
              </Box>
              <Box className={classes.wrapperTimesRow}>
                {[...Array(2).keys()].map((time) => (
                  <Skeleton key={`skeleton-table-resume-row-time-${time}`} height={20} variant="text" width={120} />
                ))}
              </Box>
            </Box>
            <Box className={classes.contentLeftRow}>
              <Box className={classes.providersRow}>
                {[...Array(4).keys()].map((provider) => (
                  <Skeleton
                    key={`skeleton-table-resume-row-provider-${provider}`}
                    height={30}
                    variant="circular"
                    width={30}
                  />
                ))}
              </Box>
              <Skeleton height={22} variant="rectangular" width={22} />
            </Box>
          </Box>
        </Box>
      ))}
    </>
  );
}

HelperSkeleton.propTypes = {
  type: PropTypes.string,
};

export default HelperSkeleton;
