import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  rootDialog: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    paddingInline: theme.spacing(3),
  },
  titleDialog: {
    fontSize: theme.typography.pxToRem(40),
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    marginTop: '3rem',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(24),
      marginBottom: theme.spacing(1),
    },
  },
  descriptionDialog: {
    fontSize: theme.typography.pxToRem(24),
    lineHeight: 1.7,
    textAlign: 'center',
    width: '40%',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      width: '80%',
      lineHeight: 1,
    },
  },
  wrapperButton: {
    marginTop: theme.spacing(4),
    '& > button': {
      width: 212,
    },
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      bottom: 0,
      padding: theme.spacing(3),
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& > button': {
        width: 300,
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& > button': {
        width: '100%',
      },
    },
  },
}));

export default useStyles;
