import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';

const TooltipBase = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 8px 40px #F2F2F4',
    borderRadius: theme.borderRadius,
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
    padding: theme.spacing(1),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))(Tooltip);

function Tootltip(props) {
  return <TooltipBase {...props} />;
}

export default Tootltip;
