/* eslint-disable no-unused-vars */
import { useMemo } from 'react';

import clsx from 'clsx';

import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import InputBase from '@mui/material/InputBase';
import SelectMui from '@mui/material/Select';
import Box from '@mui/material/Box';

import IconChevronDown from '@experimental-components/IconsComponents/ChevronDown';

import Input from './input';

const SelectRoot = withStyles((theme) => ({
  select: {
    borderRadius: theme.shape.borderRadius,

    '&&': {
      paddingBlock: theme.typography.pxToRem(9),
      paddingLeft: theme.typography.pxToRem(14),
      paddingRight: 32,
    },
  },

  filled: {
    backgroundColor: theme.palette.darkGrey[2],
    borderRadius: theme.shape.borderRadius,

    '&:hover': {
      borderRadius: theme.shape.borderRadius,
    },

    '&:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(SelectMui);

const useStyles = makeStyles((theme) => ({
  iconRoot: {
    right: 16,
    position: 'absolute',
    top: 'calc(50% - 6px)',
  },

  menuList: {
    color: '#1E2025',
    fontSize: theme.typography.pxToRem(16),
  },

  menuPaper: {
    maxHeight: theme.spacing(63),
    marginTop: theme.spacing(2),
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.darkGrey[5]}`,
    boxShadow: '0px 8px 40px rgba(133, 133, 133, 0.2)',
  },
}));

function SelectKitchenAreas({
  brandName,
  brandIconUrl,
  id,
  error,
  isFullWidth,
  helperText,
  errorMsg,
  isDisabled,
  required,
  variant,
  ...others
}) {
  const isNameAvatar = useMemo(() => {
    if (brandIconUrl?.length > 0) return false;
    return true;
  }, [brandIconUrl?.length]);

  const classes = useStyles({ isNameAvatar, isDisabled });
  const theme = useTheme();

  const menuProps = {
    classes: {
      list: classes.menuList,
      paper: classes.menuPaper,
    },
  };

  const iconComponent = (props) => (
    <IconChevronDown
      className={clsx(props.className, classes.iconRoot)}
      color={isDisabled ? theme.palette.darkGrey[50] : theme.palette.common.black}
    />
  );

  return (
    <Box alignItems="center" display="grid">
      <Box maxWidth={isFullWidth ? 'none' : 192} minWidth={192} width="100%">
        <SelectRoot
          disabled={isDisabled}
          error={error}
          fullWidth={isFullWidth}
          IconComponent={iconComponent}
          id={id}
          input={<Input />}
          MenuProps={{ ...menuProps, 'data-testid': 'menuKitchenAreasFormProduct' }}
          required={required}
          variant={variant}
          {...others}
        />
      </Box>
    </Box>
  );
}

SelectKitchenAreas.propTypes = {
  ...InputBase.propTypes,
};

export default SelectKitchenAreas;
