import { useTranslation } from 'react-i18next';
import { useIsFetching } from 'react-query';

import { ContentLayout } from 'Layout';

import {
  useGetAverageTicketSummary,
  useGetAverageTicketDetail,
  useDownloadAverageTicket,
} from 'containers/ReportAverageTicket/hooks';

import ContentTabsReports from 'components/ContentTabsReports';
import ExportToExcelButton from 'components/ExportToExcelButton';

import { getReportFileName } from 'utils/getReportFileName';

const Navigation = () => {
  const { t } = useTranslation('reports');
  const isFetchginDetail = useIsFetching({ queryKey: ['average-ticket-detail'] });
  const { downloadReport, downloadReportIsFetching } = useDownloadAverageTicket();
  const { data: detailData } = useGetAverageTicketDetail();
  const { data: summaryData, dataStatus: summaryStatus } = useGetAverageTicketSummary();

  const noDataSummary = !summaryData || summaryData?.summaries[0].value === '0.00';
  const noDataDetail = !detailData?.pages[0].data.rows.length;
  const disableButton =
    downloadReportIsFetching || summaryStatus.fetching || Boolean(isFetchginDetail) || (noDataSummary && noDataDetail);

  return (
    <ContentTabsReports mx={-4} pt={3} px={4}>
      <ContentTabsReports.Options>
        <ContentLayout.TabsNavigation>
          <ContentLayout.TabLink label={t('tabs.summary')} to="/reports/average-ticket/summary" value={0} />

          <ContentLayout.TabLink label={t('tabs.details')} to="/reports/average-ticket/detailed" value={1} />
        </ContentLayout.TabsNavigation>
      </ContentTabsReports.Options>

      <ContentTabsReports.ButtonExport>
        <ExportToExcelButton
          disabled={disableButton}
          fileName={getReportFileName({ name: 'Ticket promedio' })}
          isLoading={downloadReportIsFetching}
          isVisible
          mutateAsync={() => downloadReport()}
          name="average-ticket-report"
        />
      </ContentTabsReports.ButtonExport>
    </ContentTabsReports>
  );
};

export default Navigation;
