// @ts-nocheck
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';

import Select from '@design-system/Select';

import useStyles from './styles';

interface Props {
  onSelectSearchType?: () => void;
}

function SelectSearchTypeModifier({ onSelectSearchType }: Props) {
  const classes = useStyles();
  const { t } = useTranslation('menuMaker');

  function renderValue(value: string) {
    return (
      <span className={classes.contentSelected}>
        {t(`menuMaker:headerForm.searchByModifiersOptions.${value.toLowerCase()}`)}
      </span>
    );
  }

  return (
    <Select
      defaultValue="GROUP"
      displayEmpty
      fullWidth
      hideLabelHelperText
      label={t('menuMaker:headerForm.searchBy')}
      name="modifierSearchTypeFilter"
      onChange={onSelectSearchType}
      renderValue={(selected) => renderValue(selected as string)}
    >
      <MenuItem value="GROUP">
        <ListItemText primary={t('menuMaker:headerForm.searchByModifiersOptions.group')} />
      </MenuItem>
      <MenuItem value="OPTION">
        <ListItemText primary={t('menuMaker:headerForm.searchByModifiersOptions.option')} />
      </MenuItem>
    </Select>
  );
}

export default SelectSearchTypeModifier;
