import { useState } from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

import DisplayMessage from 'components/DisplayMessage';
import MenuMakerPreviewTable from 'components/MenuMakerPreviewTable';

import Tabs from '@design-system/Tabs';

import TabPanel from './TabPanel';

function TabsCategoriesProducts({
  categories,
  productsDetails,
  loadingProducts,
  onLoadProducts,
  onLoadProductDetails,
  loadingCategoryProductDetails,
  t,
}) {
  const theme = useTheme();
  const [categorySelected, setCategorySelected] = useState(Object.keys(categories)[0]);

  const handleChangeSelectedTab = (event, newValue) => {
    setCategorySelected(newValue);
    if (!categories[newValue].isLoaded) {
      onLoadProducts(newValue);
    }
  };

  if (!categories) {
    return (
      <Box pl={theme.typography.pxToRem(32)} pr={theme.typography.pxToRem(32)}>
        <Collapse in={!categories}>
          <DisplayMessage
            id="menusCategories"
            severity="searchInfo"
            title={t('menuMaker:previewMenu.emptyCategories')}
          />
        </Collapse>
      </Box>
    );
  }

  return (
    <Box display="inline-grid" width="100%">
      <Tabs
        aria-label="scrollable menu categories"
        indicatorColor="primary"
        onChange={handleChangeSelectedTab}
        scrollButtons="auto"
        textColor="primary"
        value={categorySelected}
        variant="scrollable"
      >
        {map(categories, (category) => (
          <Tabs.Tab key={category?.uuid} label={category?.name} value={category?.uuid} />
        ))}
      </Tabs>
      {map(categories, (category) => (
        <TabPanel key={category?.uuid} index={category?.uuid} pt={2} value={categorySelected}>
          <MenuMakerPreviewTable
            key={category?.uuid}
            isLoaded={category?.isLoaded}
            loading={loadingProducts}
            loadingProductsDetails={loadingCategoryProductDetails}
            onLoadProductModifiersGroups={onLoadProductDetails}
            products={category?.products}
            productsDetails={productsDetails}
            t={t}
          />
        </TabPanel>
      ))}
    </Box>
  );
}
TabsCategoriesProducts.propTypes = {
  categories: PropTypes.object,
  productsDetails: PropTypes.object,
  loadingProducts: PropTypes.bool,
  onLoadProducts: PropTypes.func,
  onLoadProductDetails: PropTypes.func,
  loadingCategoryProductDetails: PropTypes.bool,
  t: PropTypes.func,
};

export default TabsCategoriesProducts;
