import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

function ConfigFormSkeleton() {
  const { t } = useTranslation('settingsClockTime');

  return (
    <Box ml={4} mr={4}>
      <Grid container direction="column" spacing={8}>
        <Grid item md={7} sm={10} xs={12}>
          <Box color="text.primary" fontSize={16} fontWeight="500" mb={1}>
            {t('settingsClockTime:form.timeClockTitleSection')}
          </Box>
          <Box color="text.secondary" fontSize={14} fontWeight="400" mb={3}>
            {t('settingsClockTime:form.timeClockDescriptionSection')}
          </Box>
          <Skeleton height={55} variant="rectangular" width={200} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ConfigFormSkeleton;
