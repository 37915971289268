import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import CircularProgress from '@mui/material/CircularProgress';

import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.black,
    padding: '16px 24px',
    borderRadius: theme.typography.pxToRem(8),
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 221,
    width: '100%',
    height: '100%',
    maxHeight: 56,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },

  cancelRoot: {
    backgroundColor: theme.palette.background.default,
    padding: '24px',
    borderRadius: 0,
    boxShadow: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 221,
    width: '100%',
    height: '100%',
    maxHeight: 56,
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.secondary.main,
  },

  labelRoot: {
    alignItems: 'center',
    color: '#FFF',
    display: 'flex',
    fontSize: 16,
    fontWeight: 500,
    height: theme.spacing(3),
    textAlign: 'center',
  },

  disabledLabel: {
    color: theme.palette.darkGrey[15],
  },

  paperRoot: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: '0px 4px 40px rgba(211, 213, 218, 0.32)',
    paddingTop: 16,
    paddingBottom: 16,
    paddingRight: 0,
    paddingLeft: 16,
    fontSize: 16,
    width: '100%',
    minWidth: 290,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },

  startIcon: {
    display: 'inherit',
    marginRight: 16,
    marginLeft: -4,
    '&$iconSizeSmall': {
      marginLeft: -2,
    },
  },

  endIcon: {
    display: 'inherit',
    marginRight: -4,
    marginLeft: 8,
    '&$iconSizeSmall': {
      marginRight: -2,
    },
  },
}));

interface Props {
  children: React.ReactNode;
  cancelIcon?: React.ReactNode;
  cancelText?: string;
  startIcon?: React.ReactNode;
  helperText?: string;
  helperNumSelected?: number;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  onCancel: () => void;
  isLoading: boolean;
  hasCancelButton?: boolean;
  isCheckAll?: boolean;
}

function AcionableFloatingButton({
  children,
  startIcon,
  cancelIcon,
  cancelText,
  hasCancelButton = false,
  helperText,
  helperNumSelected,
  disabled,
  onClick,
  onCancel,
  isLoading,
  isCheckAll = false,
  ...others
}: Props) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box alignItems="center" display="flex" minWidth={560} width="100%" {...others}>
      <Box className={classes.paperRoot}>
        <Box mr={3}>
          {isLoading && !helperNumSelected ? (
            <CircularProgress color="inherit" size={18} />
          ) : (
            <Box
              alignItems="center"
              bgcolor="#F7F7F9"
              borderRadius="100px"
              color="#000"
              component="span"
              display="flex"
              fontSize={16}
              fontWeight={theme.typography.fontWeightMedium}
              height={28}
              justifyContent="center"
              minWidth={58}
              p="6px 16px"
              width="100%"
            >
              {isCheckAll ? 'Todos' : helperNumSelected}
            </Box>
          )}
        </Box>

        <Box minWidth={180} width="100%">
          <Box
            alignItems="center"
            color="#000"
            display="flex"
            fontSize={16}
            fontWeight={theme.typography.fontWeightRegular}
            height={theme.spacing(3)}
            width="100%"
          >
            {helperText}
          </Box>
        </Box>
      </Box>

      {hasCancelButton && (
        <ButtonBase
          classes={{ root: classes.cancelRoot }}
          data-testid="buttonSelecting"
          disabled={disabled}
          disableRipple
          onClick={onCancel}
        >
          <span
            className={clsx({
              [classes.disabledLabel]: disabled,
            })}
          >
            {cancelIcon && (
              <span className={classes.startIcon}>
                {isLoading ? <CircularProgress color="inherit" size={18} /> : cancelIcon}
              </span>
            )}
            {cancelText}
          </span>
        </ButtonBase>
      )}

      <ButtonBase
        classes={{ root: classes.root }}
        data-testid="buttonSelecting"
        disabled={disabled}
        disableRipple
        onClick={onClick}
      >
        <span
          className={clsx(classes.labelRoot, {
            [classes.disabledLabel]: disabled,
          })}
        >
          {startIcon && (
            <span className={classes.startIcon}>
              {isLoading ? <CircularProgress color="inherit" size={18} /> : startIcon}
            </span>
          )}

          {children}
        </span>
      </ButtonBase>
    </Box>
  );
}

export default AcionableFloatingButton;
