/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import NumberFormat from 'react-number-format';
import type { NumberFormatPropsBase, NumberFormatValues } from 'react-number-format';

import InputBase from './InputBase';

type Props = {
  inputRef?: React.Ref<any>;
  onChange?: (value: NumberFormatValues | string) => void;
};
type NumberFieldProps = NumberFormatPropsBase<unknown> & Props;

function NumberFieldGroup({
  inputRef,
  thousandSeparator,
  onChange,
  allowNegative = false,
  ...others
}: NumberFieldProps) {
  return (
    <NumberFormat
      {...others}
      allowNegative={allowNegative}
      customInput={InputBase}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (onChange) onChange(values.value || '');
      }}
      thousandSeparator={thousandSeparator}
    />
  );
}

NumberFieldGroup.input = InputBase;

export default NumberFieldGroup;
