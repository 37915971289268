/* eslint-disable react/no-array-index-key */
import Skeleton from '@mui/material/Skeleton';

import NestedTable from '@experimental-components/NestedTable';

const PlaceholderTable = () => {
  return null;
};

const Product = () => {
  return (
    <>
      {[...Array(5)].map((row, i) => (
        <NestedTable.Row key={i} divider>
          <NestedTable.Cell align="left">
            <Skeleton height={30} width={300} />
          </NestedTable.Cell>
          <NestedTable.Cell />
          <NestedTable.Cell />
          <NestedTable.Cell />
          <NestedTable.Cell>
            <Skeleton height={30} width={128} />
          </NestedTable.Cell>
        </NestedTable.Row>
      ))}
    </>
  );
};

const ProductDetail = () => {
  return (
    <>
      <NestedTable.Row bgColor="white" bold="500" divider>
        <NestedTable.Cell align="left" tab={1}>
          <Skeleton height={30} width={300} />
        </NestedTable.Cell>
        <NestedTable.Cell>
          <Skeleton height={30} width={128} />
        </NestedTable.Cell>
        <NestedTable.Cell>
          <Skeleton height={30} width={128} />
        </NestedTable.Cell>
        <NestedTable.Cell>
          <Skeleton height={30} width={128} />
        </NestedTable.Cell>
        <NestedTable.Cell>
          <Skeleton height={30} width={128} />
        </NestedTable.Cell>
      </NestedTable.Row>

      <NestedTable.Row bgColor="white" bold="500" divider>
        <NestedTable.Cell align="left" tab={1}>
          <Skeleton height={30} width={300} />
        </NestedTable.Cell>
        <NestedTable.Cell />
        <NestedTable.Cell />
        <NestedTable.Cell />
        <NestedTable.Cell>
          <Skeleton height={30} width={128} />
        </NestedTable.Cell>
      </NestedTable.Row>
    </>
  );
};

const Option = () => {
  return (
    <>
      {[...Array(5)].map((row, i) => (
        <NestedTable.Row key={i} bgColor="white" divider>
          <NestedTable.Cell align="left">
            <Skeleton height={30} width={300} />
          </NestedTable.Cell>
          <NestedTable.Cell>
            <Skeleton height={30} width={70} />
          </NestedTable.Cell>
          <NestedTable.Cell>
            <Skeleton height={30} width={70} />
          </NestedTable.Cell>
          <NestedTable.Cell>
            <Skeleton height={30} width={120} />
          </NestedTable.Cell>
          <NestedTable.Cell>
            <Skeleton height={30} width={100} />
          </NestedTable.Cell>
        </NestedTable.Row>
      ))}
    </>
  );
};

const OptionDetail = () => {
  return (
    <>
      {[...Array(3)].map((row, i) => (
        <NestedTable.Row key={i} bgColor="white">
          <NestedTable.Cell align="left" tab={1}>
            <Skeleton height={30} width={300} />
          </NestedTable.Cell>
          <NestedTable.Cell>
            <Skeleton height={30} width={70} />
          </NestedTable.Cell>
          <NestedTable.Cell>
            <Skeleton height={30} width={70} />
          </NestedTable.Cell>
          <NestedTable.Cell>
            <Skeleton height={30} width={120} />
          </NestedTable.Cell>
          <NestedTable.Cell>
            <Skeleton height={30} width={100} />
          </NestedTable.Cell>
        </NestedTable.Row>
      ))}
    </>
  );
};

PlaceholderTable.Product = Product;
PlaceholderTable.ProductDetail = ProductDetail;
PlaceholderTable.Option = Option;
PlaceholderTable.OptionDetail = OptionDetail;

export default PlaceholderTable;
