import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  backgroundColor?: string;
  colorInherit?: string;
  size?: string | number;
}
const SparkleWithBackground = forwardRef(function SparkleWithBackground(props: Props, ref: Ref<SVGSVGElement>) {
  const { backgroundColor = '#FFFFFF', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 32 33"
      width={Number(size) - 1}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <rect fill={backgroundColor} height="32" rx="16" width="32" y="0.5" />
      <g clipPath="url(#clip0_5_11313)">
        <path
          d="M20.5386 16.7167L22.5241 17.5L20.5386 18.2833C19.2775 18.7807 18.2803 19.778 17.7831 21.0392L17 23.0247L16.2169 21.0392C16.2169 21.0392 16.2169 21.0392 16.2169 21.0392C15.7196 19.778 14.7225 18.7807 13.4614 18.2833L11.4759 17.5L13.4614 16.7167C13.4614 16.7167 13.4614 16.7167 13.4615 16.7167C14.7225 16.2193 15.7196 15.222 16.2169 13.9608C16.2169 13.9608 16.2169 13.9608 16.2169 13.9608L17 11.9753L17.7831 13.9608C18.2803 15.222 19.2775 16.2193 20.5386 16.7167Z"
          stroke="#F04E4A"
          strokeWidth="1.5"
        />
        <path
          d="M10.8102 11.663L10.5009 12.229L10.1918 11.6632C10.1057 11.5008 9.97603 11.3842 9.84282 11.3115C9.84267 11.3114 9.84252 11.3113 9.84237 11.3112L9.27098 10.9991L9.83696 10.6898C9.98659 10.6104 10.1104 10.4866 10.1898 10.3369L10.4991 9.77098L10.8082 10.3368C10.8943 10.4992 11.024 10.6158 11.1572 10.6885C11.1573 10.6886 11.1575 10.6887 11.1576 10.6888L11.729 11.0009L11.163 11.3102C11.0134 11.3896 10.8896 11.5134 10.8102 11.663Z"
          stroke="#F04E4A"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_11313">
          <rect fill={backgroundColor} height="16" transform="translate(8 8.5)" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
});

export default SparkleWithBackground;
