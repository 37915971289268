import { takeLatest, put, call } from 'redux-saga/effects';

import { logEventRequest } from 'providers/root/AmplitudeProvider/helpers';

import baasRequest from 'utils/baasRequest';
import { getInvoicesUninvoicedOrdersRequest, generateInvoicingRequest } from 'utils/api/baas/invoicing/global';

import { getAllInvoicesUninvoicedOrders, getAllInvoicesUninvoicedOrdersPagination, generateInvoicing } from './actions';

export function* handleGetAllInvoicesUninvoicedOrdersRequest(action) {
  const filters = action.payload;

  try {
    yield put(getAllInvoicesUninvoicedOrders.request());

    const { data, pagination } = yield call(baasRequest, getInvoicesUninvoicedOrdersRequest, { ...filters });

    yield put(getAllInvoicesUninvoicedOrders.success({ data, pagination }));
  } catch (err) {
    yield put(getAllInvoicesUninvoicedOrders.failure(err));
  }
}

export function* handleGetAllInvoicesUninvoicedOrdersPaginationRequest(action) {
  const filters = action.payload;

  try {
    yield put(getAllInvoicesUninvoicedOrdersPagination.request());

    const { data, pagination } = yield call(baasRequest, getInvoicesUninvoicedOrdersRequest, { ...filters });

    yield put(getAllInvoicesUninvoicedOrdersPagination.success({ data, pagination, page: filters?.page }));
  } catch (err) {
    yield put(getAllInvoicesUninvoicedOrdersPagination.failure(err));
  }
}

export function* handleGenerateInvoicingRequest(action) {
  const { filters, metricsParams } = action.payload;

  try {
    yield put(generateInvoicing.request());

    const { data } = yield call(baasRequest, generateInvoicingRequest, { ...filters });

    logEventRequest('@event.$invoicing.invoiceGenerated', '', {
      has_edited_tickets: data.hasEditedTickets,
      has_iva: data.hasIva,
      has_ieps: data.hasIeps,
      no_tax: data.noTax,
      status: 'success',
    });
    yield put(generateInvoicing.success({ data, subtractOrders: filters?.includedOrders }));
  } catch (err) {
    logEventRequest('@event.$invoicing.invoiceGenerated', '', {
      has_edited_tickets: metricsParams.hasEditedTickets,
      has_iva: metricsParams.hasIva,
      has_ieps: metricsParams.hasIeps,
      no_tax: metricsParams.noTax,
      status: 'failed',
      error_message: err,
    });
    yield put(generateInvoicing.failure(err));
  }
}

export default function* InvoicesGlobalProviderRootSga() {
  yield takeLatest(getAllInvoicesUninvoicedOrders.TRIGGER, handleGetAllInvoicesUninvoicedOrdersRequest);
  yield takeLatest(
    getAllInvoicesUninvoicedOrdersPagination.TRIGGER,
    handleGetAllInvoicesUninvoicedOrdersPaginationRequest,
  );
  yield takeLatest(generateInvoicing.TRIGGER, handleGenerateInvoicingRequest);
}
