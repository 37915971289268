/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';

import { useGetPublishKeepItemsAvailability, useChangeStatusStoreProviderLog } from 'providers/menuMaker/Menus/hooks';

import Box from '@mui/material/Box';

import ButtonOutlined from '@design-system/ButtonOutlined';
import ButtonPrimary from '@design-system/ButtonPrimary';

import Dialog from '@experimental-components/Dialog';

import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';

import useStyles from './styles';

type ProviderLog = {
  uuid: string;
  status: 'REJECTED' | 'APPROVED_WITH_ERRORS';
  updatedAt: string;
};

type Provider = {
  uuid: string;
  providerName: 'RAPPI' | 'UBER_EATS' | 'DIDI_FOOD' | 'PARROT' | 'ONLINE';
  lastPublishDate: string;
  createdAt: string;
  updatedAt: string;
  storeProviderLog: ProviderLog | null;
};

interface Props {
  open: boolean;
  onClose: () => void;
  provider: Provider;
  onPublish: () => void;
  onReloadMenus: () => void;
  brandUuid: string;
}

function ModalPublishWithErrors({ open, onClose, provider, onPublish, onReloadMenus, brandUuid }: Props) {
  const { t } = useTranslation('menuMaker');
  const classes = useStyles();
  const [showMoreLink, setShowMoreLink] = useState(false);
  const [keepItemsPages, setKeepItemsPages] = useState([]);

  const {
    loadKeepItemsAvailability,
    removeKeepItemsAvalability,
    keepItemsAvailability,
    paginateKeepItemsState,
  } = useGetPublishKeepItemsAvailability(provider, brandUuid);

  const changeStoreProviderLog = useChangeStatusStoreProviderLog(onReloadMenus);

  const scrollPaginationRef = useRef(null);
  const enablePagination = keepItemsAvailability?.pages?.length && keepItemsAvailability?.pages[0]?.data?.length > 6;

  useEffect(() => {
    if (provider?.providerName && provider?.storeProviderLog?.status === 'APPROVED_WITH_ERRORS') {
      loadKeepItemsAvailability();
    }

    return () => {
      removeKeepItemsAvalability();
    };
  }, [provider]);

  useEffect(() => {
    setKeepItemsPages(keepItemsAvailability?.pages ?? []);

    if (!keepItemsPages?.length && keepItemsAvailability?.pages?.length) {
      setShowMoreLink(keepItemsAvailability?.pages[0]?.data?.length > 6);
    }
  }, [keepItemsAvailability?.pages]);

  useEffect(() => {
    if (changeStoreProviderLog.isSuccess && !changeStoreProviderLog.isLoading) {
      changeStoreProviderLog.reset();
      onClose();
    }
  }, [changeStoreProviderLog.isSuccess, changeStoreProviderLog.isLoading]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];
        if (
          isIntersecting &&
          paginateKeepItemsState?.hasNextPage &&
          enablePagination &&
          keepItemsPages?.length &&
          !showMoreLink
        ) {
          if (!paginateKeepItemsState?.isFetchingNextPage) {
            paginateKeepItemsState?.fetchNextPage();
          }
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return () => {
      observer.disconnect();
    };
  }, [
    scrollPaginationRef,
    paginateKeepItemsState?.hasNextPage,
    paginateKeepItemsState?.isFetchingNextPage,
    keepItemsPages,
    showMoreLink,
  ]);

  const renderBodyTableKeepItems = () => {
    if (!keepItemsPages?.length) {
      return renderPlaceholderKeepItems();
    }

    if (showMoreLink) {
      return keepItemsPages[0]?.data?.slice(0, 6).map((item: { uuid: string; name: string }) => (
        <Box key={item?.uuid} className={classes.rowkeepItems}>
          <Box>{item?.name}</Box>
        </Box>
      ));
    }

    return keepItemsPages.map((page) => {
      if (!page) return null;

      return page?.data?.map((item: { uuid: string; name: string }) => (
        <Box key={item?.uuid} className={classes.rowkeepItems}>
          <Box>{item?.name}</Box>
        </Box>
      ));
    });
  };

  const renderPlaceholderKeepItems = () => {
    return [...Array(4).keys()].map((item) => (
      <Box key={item} className={classes.rowkeepItems}>
        <Skeleton height={14} variant="text" width={150} />
      </Box>
    ));
  };

  const handleOpenList = () => {
    setShowMoreLink(false);
  };

  const handleActionRightButton = () => {
    if (provider?.storeProviderLog?.status === 'REJECTED') {
      onPublish();
    }

    if (provider?.storeProviderLog?.status === 'APPROVED_WITH_ERRORS') {
      changeStatus('APPROVED_IN_VALIDATION');
    }
  };

  const handleActionLeftButton = () => {
    if (provider?.storeProviderLog?.status === 'REJECTED') {
      changeStatus('CANCELLED');
    }

    if (provider?.storeProviderLog?.status === 'APPROVED_WITH_ERRORS') {
      changeStatus('APPROVED_NOT_SYNCED');
    }
  };

  const changeStatus = (status: string) => {
    changeStoreProviderLog.mutate({
      provider: provider?.providerName,
      storeProviderLogUuid: provider?.storeProviderLog?.uuid,
      status,
    });
  };

  return (
    <Dialog isOpen={open} onClose={onClose}>
      <Dialog.Head
        subtitle={
          <Trans
            i18nKey={`menuMaker:publishMenu.dialog.${
              provider?.storeProviderLog?.status === 'APPROVED_WITH_ERRORS'
                ? 'descriptionErrorAvailability'
                : 'descriptionErrorPublish'
            }`}
          />
        }
        title={t(`menuMaker:publishMenu.dialog.titleError`)}
      />
      {provider?.storeProviderLog?.status === 'APPROVED_WITH_ERRORS' && (
        <Box className={clsx(classes.wrapperkeepItems, classes.heightOverOfStock)}>
          <Box className={clsx(classes.rowkeepItems, classes.rowSticky)}>
            <Box className={classes.headKeepItems}>{t('menuMaker:publishMenu.dialog.article')}</Box>
          </Box>
          {renderBodyTableKeepItems()}

          {showMoreLink && (
            <Box textAlign="center" width="100%">
              <Link
                className={classes.linkMore}
                color="secondary"
                onClick={handleOpenList}
                underline="hover"
                variant="button"
              >
                {t('common:buttons.seeMore')}
              </Link>
            </Box>
          )}

          {paginateKeepItemsState?.isFetchingNextPage && renderPlaceholderKeepItems()}

          <Box ref={scrollPaginationRef} pb={1} />
        </Box>
      )}
      <Dialog.Actions>
        <ButtonOutlined
          className={classes.actionButtonRetry}
          disabled={changeStoreProviderLog.isLoading}
          disableElevation
          loading={changeStoreProviderLog.isLoading}
          onClick={handleActionLeftButton}
        >
          {t(
            `menuMaker:publishMenu.dialog.buttonsRetry.${
              provider?.storeProviderLog?.status === 'APPROVED_WITH_ERRORS' ? 'keepAvailable' : 'cancelPublish'
            }`,
          )}
        </ButtonOutlined>

        <ButtonPrimary
          className={classes.actionButtonRetry}
          disabled={changeStoreProviderLog.isLoading}
          disableElevation
          loading={changeStoreProviderLog.isLoading}
          onClick={handleActionRightButton}
        >
          {t(
            `menuMaker:publishMenu.dialog.buttonsRetry.${
              provider?.storeProviderLog?.status === 'APPROVED_WITH_ERRORS' ? 'outOfStock' : 'retryPublish'
            }`,
          )}
        </ButtonPrimary>
      </Dialog.Actions>
    </Dialog>
  );
}

export default ModalPublishWithErrors;
