import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  linkCell: {
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'left',
    width: theme.spacing(25),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
}));

export default useStyles;
