import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { ReactComponent as Parrot404 } from 'images/svg/brand/404-parrot.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    [theme.breakpoints.down('md')]: {
      padding: 16,
    },
  },

  parrotConnect: {
    width: '100%',
    height: '100%',
  },
}));

function NotFound({ t }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center">
        <Parrot404 className={classes.parrotConnect} />
      </Box>

      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        mt={6}
        sx={{
          '& > :not(:first-child)': {
            marginTop: theme.spacing(1),
          },
        }}
      >
        <Box>
          <Box fontSize={24} fontWeight="fontWeightMedium">
            {t('common:notFound.title')}
          </Box>
        </Box>

        <Box>
          <Box fontSize={18} fontWeight="fontWeightRegular">
            {t('common:notFound.message')}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

NotFound.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(NotFound);
