import queryString from 'query-string';

import request from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getStoreTableSettingsRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/store-table-settings/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function updateTableConfigRequest(
  { displayTableSelection, requireTableSelection, requireOccupationSelection, useCoursing },
  options = {},
) {
  const storeUuid = getUserCurrentStore()?.uuid;

  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/store-table-settings/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({
      displayTableSelection,
      requireTableSelection,
      requireOccupationSelection,
      useCoursing,
    }),
    ...options,
  });
}
