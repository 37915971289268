import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import ModalHeader from './ModalHeader';

interface Props {
  message?: string;
}

function ModalLoader({ message }: Props) {
  return (
    <Box alignItems="center" display="flex" flexDirection="column" height="100%" justifyContent="center" width="100%">
      <CircularProgress color="secondary" size={72} thickness={2.5} />
      <ModalHeader subtitle={message} title="Espera un momento" />
    </Box>
  );
}

export default ModalLoader;
