import PropTypes from 'prop-types';

import DialogMui from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';

import DialogHead from './DialogHead';
import DialogContent from './DialogContent';
import DialogActions from './DialogActions';
import PaperComponent from './PaperComponent';

const useStyles = makeStyles((theme) => ({
  paperWidthMd: {
    borderRadius: theme.shape.borderRadiusLarge,
    maxWidth: 786,
  },
}));

function Dialog({ isOpen, ...others }) {
  const classes = useStyles();

  return (
    <DialogMui
      classes={{ paperWidthMd: classes.paperWidthMd }}
      fullWidth
      maxWidth="md"
      open={isOpen}
      PaperComponent={PaperComponent}
      {...others}
    />
  );
}

Dialog.Head = DialogHead;
Dialog.Content = DialogContent;
Dialog.Actions = DialogActions;

Dialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Dialog;
