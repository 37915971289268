/* eslint-disable no-return-assign */
const FORMAT_HOURS = 'D/MM/YYYY, h:mm A';
const FORMAT_DEFAULT = 'LL';
const FORMAT_DEFAULT_WITH_HOURS = 'LL, h:mm A';
const FORMAT_CONVENTIONAL_HOURS = 'D/MM/YY HH:mm:ss';
const FORMAT_CONVENTIONAL_DEFAULT = 'DD/MM/YYYY';
const FORMAT_CONVENTIONAL_MONTH_STRING = 'DD MMM YYYY';
const FORMAT_EXPORT_DATE_WITH_HOURS = 'YYYY-MM-DD HH_mm_ss';
const FORMAT_CONVENTIONAL_DATE_WITH_HOURS = 'YYYY-MM-DD HH:mm:ss';

export const formatTypesDates = {
  HOURS: FORMAT_HOURS,
  DEFAULT: FORMAT_DEFAULT,
  DEFAULT_WITH_HOURS: FORMAT_DEFAULT_WITH_HOURS,
  SEND_FORM: 'YYYY-MM-DD',
  SEND_FORM_WITH_HOURS: FORMAT_CONVENTIONAL_DATE_WITH_HOURS,
  CONVENTIONAL: FORMAT_CONVENTIONAL_HOURS,
  CONVENTIONAL_DEFAULT: FORMAT_CONVENTIONAL_DEFAULT,
  CONVENTIONAL_MONTH_STRING: FORMAT_CONVENTIONAL_MONTH_STRING,
  EXPORT_DATE_WITH_HOURS: FORMAT_EXPORT_DATE_WITH_HOURS,
};

export const optionsTimes = [
  {
    label: '12:00 AM',
    value: '00:00',
  },
  {
    label: '12:30 AM',
    value: '00:30',
  },
  {
    label: '01:00 AM',
    value: '01:00',
  },
  {
    label: '01:30 AM',
    value: '01:30',
  },
  {
    label: '02:00 AM',
    value: '02:00',
  },
  {
    label: '02:30 AM',
    value: '02:30',
  },
  {
    label: '03:00 AM',
    value: '03:00',
  },
  {
    label: '03:30 AM',
    value: '03:30',
  },
  {
    label: '04:00 AM',
    value: '04:00',
  },
  {
    label: '04:30 AM',
    value: '04:30',
  },
  {
    label: '05:00 AM',
    value: '05:00',
  },
  {
    label: '05:30 AM',
    value: '05:30',
  },
  {
    label: '06:00 AM',
    value: '06:00',
  },
  {
    label: '06:30 AM',
    value: '06:30',
  },
  {
    label: '07:00 AM',
    value: '07:00',
  },
  {
    label: '07:30 AM',
    value: '07:30',
  },
  {
    label: '08:00 AM',
    value: '08:00',
  },
  {
    label: '08:30 AM',
    value: '08:30',
  },
  {
    label: '09:00 AM',
    value: '09:00',
  },
  {
    label: '09:30 AM',
    value: '09:30',
  },
  {
    label: '10:00 AM',
    value: '10:00',
  },
  {
    label: '10:30 AM',
    value: '10:30',
  },
  {
    label: '11:00 AM',
    value: '11:00',
  },
  {
    label: '11:30 AM',
    value: '11:30',
  },
  {
    label: '12:00 PM',
    value: '12:00',
  },
  {
    label: '12:30 PM',
    value: '12:30',
  },
  {
    label: '13:00 PM',
    value: '13:00',
  },
  {
    label: '13:30 PM',
    value: '13:30',
  },
  {
    label: '14:00 PM',
    value: '14:00',
  },
  {
    label: '14:30 PM',
    value: '14:30',
  },
  {
    label: '15:00 PM',
    value: '15:00',
  },
  {
    label: '15:30 PM',
    value: '15:30',
  },
  {
    label: '16:00 PM',
    value: '16:00',
  },
  {
    label: '16:30 PM',
    value: '16:30',
  },
  {
    label: '17:00 PM',
    value: '17:00',
  },
  {
    label: '17:30 PM',
    value: '17:30',
  },
  {
    label: '18:00 PM',
    value: '18:00',
  },
  {
    label: '18:30 PM',
    value: '18:30',
  },
  {
    label: '19:00 PM',
    value: '19:00',
  },
  {
    label: '19:30 PM',
    value: '19:30',
  },
  {
    label: '20:00 PM',
    value: '20:00',
  },
  {
    label: '20:30 PM',
    value: '20:30',
  },
  {
    label: '21:00 PM',
    value: '21:00',
  },
  {
    label: '21:30 PM',
    value: '21:30',
  },
  {
    label: '22:00 PM',
    value: '22:00',
  },
  {
    label: '22:30 PM',
    value: '22:30',
  },
  {
    label: '23:00 PM',
    value: '23:00',
  },
  {
    label: '23:30 PM',
    value: '23:30',
  },
  {
    label: '23:59 PM',
    value: '23:59',
  },
];

export const getUnitTimeFromStr = (str, type) => {
  if (!str) return -1;
  const time = str.split(':');
  if (type === 'hour') return Number(time[0]);
  if (type === 'minute') return Number(time[1]);
  if (type === 'seconds') return Number(time[2]);
  return -1;
};

export const fillArrayTime = (max, interval = 1, startAt = 0) => {
  const array = [];
  for (let i = startAt; i <= max; i += interval) {
    array.push(i);
  }
  return array;
};

export const formatUnitTime = (unit) => {
  const zero = unit <= 9 ? '0' : '';
  return `${zero}${unit}`;
};
