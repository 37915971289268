import { Box, Typography } from '@mui/material';

import useStyles from './styles';

interface Props {
  label: string;
  value: string;
  color: string;
}
const ScheduleChip = ({ label, value, color }: Props) => {
  const classes = useStyles({ backgroundChip: color });

  return (
    <Box className={classes.filterChip}>
      <Typography className={classes.labelChip}>{label}</Typography>

      <Box className={classes.chip}>{value}</Box>
    </Box>
  );
};

export default ScheduleChip;
