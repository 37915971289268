/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import MobileStepperMui from '@mui/material/MobileStepper';

const MobileStepperBase = withStyles((theme) => ({
  root: {
    background: 'transparent',
  },

  progress: {
    width: '100%',
    backgroundColor: '#262626',
    height: 8,
    borderRadius: 5,
  },
}))(MobileStepperMui);

function MobileStepper({ LinearProgressProps, ...props }) {
  return (
    <MobileStepperBase
      LinearProgressProps={{
        color: 'secondary',
        ...LinearProgressProps,
      }}
      {...props}
    />
  );
}

MobileStepper.propTypes = {
  ...MobileStepperMui.propTypes,
  fullWidthProgress: PropTypes.bool,
};

export default MobileStepper;
