/* eslint-disable react/prop-types */
import DialogContent from '@mui/material/DialogContent';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dialogContentRoot: {
    paddingBottom: theme.spacing(6),

    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
}));

function DialogLayoutContent({ children, classes: classesProp = '' }) {
  const classes = useStyles();

  return <DialogContent classes={{ root: classesProp || classes.dialogContentRoot }}>{children}</DialogContent>;
}

export default DialogLayoutContent;
