import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import IconButton from '@design-system/IconButton';
import ButtonPrimary from '@design-system/ButtonPrimary';
import IconClose from '@experimental-components/IconsComponents/Close';

import useStyles from './styles';

function StepperEndContentDialog({ children, activeScroll, onNext, onClose, disabledNextButton, labelButtonNext }) {
  const classes = useStyles();

  return (
    <Box>
      <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
        <Box position="sticky" top="0" zIndex="1500">
          <Box
            alignItems="center"
            bgcolor="#fff"
            boxShadow={activeScroll ? '0px 8px 40px rgba(196, 208, 237, 0.15)' : 'none'}
            display="flex"
            justifyContent="end"
            p="3rem"
          >
            <Box>
              <IconButton data-testid="btnCloseSteperDialog" onClick={onClose} size="large" startIcon={<IconClose />} />
            </Box>
          </Box>
        </Box>

        <Box className={classes.endContentStepper}>{children}</Box>

        <Box bgcolor="#fff" bottom="0" position="sticky">
          <Box margin="0px auto 0px 0px" maxWidth="25rem" p="0 1rem 5rem 5rem">
            <ButtonPrimary className={classes.buttonStep} disabled={disabledNextButton} onClick={onNext}>
              {labelButtonNext}
            </ButtonPrimary>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

StepperEndContentDialog.propTypes = {
  children: PropTypes.node,
  activeScroll: PropTypes.bool,
  onNext: PropTypes.func,
  onClose: PropTypes.func,
  disabledNextButton: PropTypes.bool,
  labelButtonNext: PropTypes.string,
};

export default StepperEndContentDialog;
