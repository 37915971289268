import React from 'react';
import DividerMUI from '@mui/material/Divider';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  divider: {
    background: theme.palette.darkGrey[15],
    marginBlock: '1.5rem',
    marginInline: 0,
    borderColor: '#CECDD1',
    borderBottomWidth: 0,
    border: 0,
    height: '0.03125rem',
    opacity: 0.5,
  },
}));

function Divider({ ...others }) {
  const classes = useStyles();

  return <DividerMUI classes={{ root: classes.divider }} {...others} />;
}

export default Divider;
