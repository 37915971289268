import clsx from 'clsx';

import Button from '@mui/material/Button';

import useStyles from './styles';

interface Props {
  label: string;
  startIcon: React.ReactNode;
  endIcon?: React.ReactNode;
  openMenu: boolean;
  onClick: () => void;
}

function ButtonInput({ label, onClick, startIcon, endIcon, openMenu }: Props) {
  const classes = useStyles({ isOpenMenu: openMenu });

  return (
    <Button
      classes={{ root: classes.buttonCard, startIcon: openMenu ? classes.startIcon : classes.startIconV2 }}
      className={clsx({
        [classes.buttonCardCollapseMenu]: !openMenu,
      })}
      onClick={onClick}
      startIcon={startIcon}
    >
      {openMenu && label && (
        <>
          <span className={classes.navLinkLabel} data-testid="navLinkLabel" style={{ textAlign: 'initial' }}>
            {label}
          </span>
          {endIcon && endIcon}
        </>
      )}
    </Button>
  );
}

export default ButtonInput;
