import queryString from 'query-string';

import type { PaymentMultiStoreResponse } from 'containers/Subscription/types';

import request, { requestParseErrors } from 'utils/request';
import { getUserCurrentStore } from 'utils/auth';
import { connectApi } from 'utils/api/baas/axiosBase';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

type PageType = { page: number };

export function getSuscriptionHomeRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/subscriptions/multi-store/home/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function deletePaymentMethodRequest(paymentMethodUuid: string, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/subscriptions/payment-method/${paymentMethodUuid}/?${query}`, {
    method: 'DELETE',
    ...options,
  });
}

// @ts-ignore
export function savePaymentMethodRequest(paymentMethod, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/subscriptions/payment-method/?${query}`, {
    method: 'POST',
    body: JSON.stringify(paymentMethod),
    ...options,
  });
}

export function getSubscriptionPaymentsRequest({ page }: PageType, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    page,
    page_size: 50,
  });

  return request(`${API_BASE_URL}/v1/subscriptions/payments/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export async function getSubscriptionPaymentsMultiStoreRequest({ page }: PageType) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
    page,
    page_size: 15,
  });

  const response = await connectApi.get<PaymentMultiStoreResponse>(`/v1/subscriptions/multi-store/payments/?${query}`);

  return response.data;
}

export function getSubscriptionInvoiceDataRequest(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/subscriptions/invoice-data/?${query}`, {
    method: 'GET',
    ...options,
  });
}

// @ts-ignore
export function updateSubscriptionInvoiceDataRequest(invoiceData, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/subscriptions/invoice-data/?${query}`, {
    method: 'PUT',
    body: JSON.stringify(invoiceData),
    ...options,
  });
}

export function getTokenEmptyConekta(options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });
  return request(`${API_BASE_URL}/v1/subscriptions/conekta-client-token/?${query}`, {
    method: 'POST',
    ...options,
  });
}

export function getLastPaymentStatusRequest(paymentMethodUuid: string, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({
    store_uuid: storeUuid,
  });

  return request(`${API_BASE_URL}/v1/subscriptions/payment-method/${paymentMethodUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

export function retryLicensePaymentRequest({ paymentMethodUuid }: { paymentMethodUuid: string }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({ store_uuid: storeUuid });

  return request(`${API_BASE_URL}/v1/subscriptions/payment-method/${paymentMethodUuid}/retry/?${query}`, {
    method: 'PUT',
    ...options,
  });
}

export function getOrganizationRequest({ organizationUuid }: { organizationUuid: string }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({ store_uuid: storeUuid });
  if (!organizationUuid) {
    return null;
  }

  return request(`${API_BASE_URL}/v1/organizations/${organizationUuid}/?${query}`, {
    method: 'GET',
    ...options,
  });
}

// @ts-ignore
export function updateOrganizationConfigRequest({ organizationUuid, groupPayments, groupInvoices }, options = {}) {
  const storeUuid = getUserCurrentStore()?.uuid;
  const query = queryString.stringify({ store_uuid: storeUuid });

  return requestParseErrors(`${API_BASE_URL}/v1/organizations/${organizationUuid}/?${query}`, {
    method: 'PUT',
    body: JSON.stringify({ group_payments: groupPayments, group_invoices: groupInvoices }),
    ...options,
  });
}
