import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useFeature } from 'FeatureProvider';
import useUserStore from 'hooks/useUserStore';
import { useAuth } from 'hooks/useAuth';
import history from 'utils/history';

export function HomeRedirect() {
  const { isAuthenticated } = useAuth();
  const {
    storeState: { selected: storeSelected },
  } = useUserStore();
  const superUserAccess = useFeature('adminStore');
  const menuMakerModuleAccess = useFeature('backofficeModuleMenu');
  const workforceModuleAccess = useFeature('backofficeModuleWorkforce');
  const invoicingModuleAccess = useFeature('backofficeModuleInvoicing');
  const settingsModuleAccess = useFeature('backofficeModuleSettings');
  const reportModuleAccess = useFeature('backofficeModuleReports');
  const inventoryModuleAccess = useFeature('backofficeModuleInventory');
  const subscriptionModuleAccess = useFeature('backofficeModuleSubscriptions');

  const pathDefaultRedirect = () => {
    if (isAuthenticated) {
      if (superUserAccess && storeSelected?.isDraft) return '/menus/resume';
      if (superUserAccess || reportModuleAccess) return '/reports';
      if (menuMakerModuleAccess) return '/menus/resume';
      if (workforceModuleAccess) return '/workforce';
      if (invoicingModuleAccess) return '/invoicing';
      if (settingsModuleAccess) return '/settings';
      if (inventoryModuleAccess) return '/inventory';
      if (subscriptionModuleAccess) return '/subscription';
    }

    if (!isAuthenticated) {
      return '/signin';
    }

    return '/permissions-denied';
  };

  history.push(pathDefaultRedirect());

  return (
    <Backdrop open>
      <CircularProgress color="secondary" />
    </Backdrop>
  );
}
