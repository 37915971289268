import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  headCell: {
    '&>th': {
      padding: 0,
      color: theme.palette.text.primary,
    },
  },

  cell: {
    '&>td': {
      padding: theme.spacing(2),
      width: theme.typography.pxToRem(350),
    },
  },

  actionButton: {
    minWidth: theme.typography.pxToRem(242),
  },

  wrapperContent: {
    maxHeight: theme.spacing(50),
    marginBottom: theme.spacing(2),
  },

  itemRemoved: {
    textDecoration: 'line-through',
  },
}));

export default useStyles;
