import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { datadogLogs } from '@datadog/browser-logs';
import { Box } from '@mui/material';

import Layout, { ViewPanelLayout } from 'Layout';

import SalesPerTime from 'components/ReportSalesPerTime';
import InitSetup from 'components/ReportSalesPerTime/InitSetup';
import ScheduleDrawer from 'components/ReportSalesPerTime/ScheduleDrawer';
import SkeletonReport from 'components/ReportSalesPerTime/SkeletonReport';
import { SchedulesType } from 'components/ReportSalesPerTime/types';
import PageError from 'components/PageError';

import { getUserCurrentStore } from 'utils/auth';

import { useGetSalesSchedules, useSaveSalesSchedules } from './hooks';

import { FiltersProvider } from './context';

const ReportSalesPerTime = () => {
  const storeUuid = getUserCurrentStore()?.uuid;
  const [openDrawer, setOpenDrawer] = useState(false);

  const { t } = useTranslation('reports');
  const { schedulesStatus, schedulesData, loadSchedules, removeSchedulesData } = useGetSalesSchedules();
  const {
    mutate: saveSalesSchedules,
    isLoading: isSaveSchedulesFetching,
    isSuccess: isSaveSchedulesFetched,
  } = useSaveSalesSchedules();

  useEffect(() => {
    datadogLogs.logger.info('Init sales per time report', { name: 'salesPerTimeReport', storeId: storeUuid });

    return () => {
      removeSchedulesData();
    };
  }, []);

  useEffect(() => {
    if (isSaveSchedulesFetched) {
      toggleDrawer();
    }
  }, [isSaveSchedulesFetched]);

  const toggleDrawer = () => {
    setOpenDrawer((currentState) => !currentState);
  };

  const handleSubmit = (data: SchedulesType[]) => {
    saveSalesSchedules(data);
  };

  const renderContent = () => {
    if (schedulesStatus.isFetching) {
      return <SkeletonReport />;
    }

    if (schedulesStatus.isError) {
      return (
        <Box alignContent="center" display="flex" height="70vh">
          <PageError
            id="schedulesError"
            labelAction={t('common:buttons.retry')}
            message={t('common:errors.loaded.data')}
            onAction={loadSchedules}
            title={t('common:errors.sorry')}
          />
        </Box>
      );
    }

    if (!schedulesData?.length) {
      return <InitSetup onOpenDrawer={toggleDrawer} t={t} />;
    }

    return <SalesPerTime onToggleDrawer={toggleDrawer} schedulesData={schedulesData} t={t} />;
  };

  return (
    <Layout>
      <FiltersProvider>
        <ViewPanelLayout goBackPath="/reports" pb={4} title={t('salesPerTimeReport.title')}>
          {renderContent()}

          <ScheduleDrawer
            isSaveSchedulesFetching={isSaveSchedulesFetching}
            onSubmit={handleSubmit}
            onToggleDrawer={toggleDrawer}
            openDrawer={openDrawer}
            schedulesData={schedulesData}
            t={t}
          />
        </ViewPanelLayout>
      </FiltersProvider>
    </Layout>
  );
};

export default ReportSalesPerTime;
