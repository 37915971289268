import React, { useCallback } from 'react';

import { useRouteMatch } from 'react-router-dom';
import history from 'utils/history';

import { TabProps } from '@mui/material/Tab';

import Tab from '@design-system/Tabs/Tab';

import useContentLayout from 'hooks/useContentLayout';

interface Props extends TabProps {
  to: string;
  value: string | number;
}

function TabLink({ to, value = 0, ...props }: Props) {
  const { url } = useRouteMatch();
  const { setValue } = useContentLayout();

  React.useEffect(() => {
    /*
     * This is needed in order to remove all the query string params from the url
     * Example:
     * '/dashboard?query=1&query=2'
     * is converted to:
     * '/dashboard'
     */
    const toUrl = to.split('?')[0];
    const urlCleaned = url.replace(/\/$/, '');

    if (toUrl === urlCleaned) {
      setValue(value);
    }
  }, [value, setValue, to, url]);

  const handleNavigation = useCallback(() => {
    history.push(to);
  }, [history, to]);

  return <Tab onClick={handleNavigation} value={value} {...props} />;
}

export default TabLink;
