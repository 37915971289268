import { datadogRum } from '@datadog/browser-rum';

import packageJson from '../package.json';

export const DD_RUM = () => {
  if (process.env.NODE_ENV !== 'development') {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_CLIENTE_TOKEN,
      site: 'datadoghq.com',
      service: 'parrot-connect-web',
      env: process.env.NODE_ENV,
      version: packageJson.version,
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask-user-input',
      startSessionReplayRecordingManually: true,
      sessionReplaySampleRate: 0,
      premiumSampleRate: 0,
      replaySampleRate: 0,
    });
  }
};
