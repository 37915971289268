import { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import camelCase from 'lodash/camelCase';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';

import DragHandleVertical from '@experimental-components/IconsComponents/DragHandleHorizontal';
import IconClose from '@experimental-components/IconsComponents/Close';

import { validateDuplicateSkuItems } from 'utils/menuMaker';
import arrayMove from 'utils/array';

import useStyles from './styles';

function ProductsSort({ hideSort, products, onChange, isValidateSku = false, messageError }) {
  const classes = useStyles();
  const [skuItemsRepeated, setSkuItemsRepeated] = useState(validateDuplicateSkuItems(products));

  useEffect(() => {
    if (isValidateSku) {
      const productsTmp = [...products];
      setSkuItemsRepeated(validateDuplicateSkuItems(productsTmp));
    }
  }, [products]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    onChange(arrayMove(products, oldIndex, newIndex));
  };

  const handleRemoveItem = (itemUuid) => {
    onChange(products.filter((item) => item?.uuid !== itemUuid));
  };

  function handleValidateItemError(itemSku) {
    let isError = false;
    if (isValidateSku && skuItemsRepeated.includes(itemSku)) {
      isError = true;
    }

    return isError;
  }

  const DraggableHandle = SortableHandle(({ children }) => children);

  const SortableItem = SortableElement(({ value }) => {
    return (
      <ListItem className={classes.listItem}>
        <Box className={clsx(classes.itemProduct, { [classes.grabCursor]: !hideSort })}>
          <Box>
            {!hideSort && (
              <DraggableHandle>
                <DragHandleVertical color={handleValidateItemError(value?.sku) ? '#F04E4A' : '#000'} size={14} />
              </DraggableHandle>
            )}

            <Box color={handleValidateItemError(value?.sku) ? '#F04E4A' : '#000'}>{value?.name}</Box>
          </Box>

          <IconButton
            aria-label="delete"
            className={classes.disableHover}
            data-testid={camelCase(`btnRemoveProduct_${value?.name}`)}
            edge="end"
            onClick={() => handleRemoveItem(value?.uuid)}
            size="large"
          >
            <IconClose color={handleValidateItemError(value?.sku) ? '#F04E4A' : '#000'} size={11} />
          </IconButton>
        </Box>
      </ListItem>
    );
  });

  const SortableList = SortableContainer(({ items }) => {
    if (!items?.length) {
      return <Box />;
    }
    return (
      <Box>
        <List className={classes.listContainer}>
          {items.map((value, index) => (
            <SortableItem key={value?.uuid} distance={1} index={index} value={value} />
          ))}
        </List>
      </Box>
    );
  });

  return (
    <>
      <SortableList items={products} lockAxis="y" onSortEnd={onSortEnd} useDragHandle />
      {isValidateSku && skuItemsRepeated.length > 0 && <Box className={classes.errorDuplicateSku}>{messageError}</Box>}
    </>
  );
}
ProductsSort.propTypes = {
  hideSort: PropTypes.bool,
  products: PropTypes.array,
  onChange: PropTypes.func,
  isValidateSku: PropTypes.bool,
  messageError: PropTypes.string,
};

export default ProductsSort;
