import { Checkbox as CheckboxMui, FormControlLabel, FormControlLabelProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';

import { ReactComponent as CheckboxCheckedIcon } from './icons/CheckboxCheckedIcon.svg';
import { ReactComponent as CheckboxCheckedDisabledIcon } from './icons/CheckboxCheckedDisabledIcon.svg';
import { ReactComponent as CheckboxIcon } from './icons/CheckboxIcon.svg';
import { ReactComponent as CheckboxIndeterminateIcon } from './icons/CheckboxIndeterminateIcon.svg';

const CheckboxBase = withStyles(() => ({
  root: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(CheckboxMui);

const FormControlLabelBase = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    marginRight: 16,
  },
  label: {
    paddingLeft: theme.spacing(1),
    '&$disabled': {
      color: theme.palette.darkGrey[15],
    },
  },

  disabled: {
    color: theme.palette.darkGrey[15],
  },
}))(FormControlLabel);

interface Props extends FormControlLabelProps {
  isCheckedDisabled?: boolean;
  indeterminate?: boolean;
  defaultChecked?: boolean;
}

function Checkbox(props: Omit<Props, 'control'>) {
  const { defaultChecked, indeterminate, isCheckedDisabled } = props;
  return (
    <FormControlLabelBase
      control={
        <CheckboxBase
          checkedIcon={isCheckedDisabled ? <CheckboxCheckedDisabledIcon /> : <CheckboxCheckedIcon />}
          defaultChecked={defaultChecked}
          disableRipple
          icon={<CheckboxIcon />}
          indeterminate={indeterminate}
          indeterminateIcon={<CheckboxIndeterminateIcon />}
        />
      }
      {...props}
    />
  );
}

export default Checkbox;
