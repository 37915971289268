import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import useStyles from './styles';

function PopupOptions({
  hasNextPage,
  isNextPageLoading,
  onLoadNextPage,
  renderListOption,
  noOptionsText,
  isFilterLoading,
}) {
  const classes = useStyles();

  const scrollPaginationRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting && hasNextPage) {
          onLoadNextPage();
        }
      },
      { threshold: 0.5 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return function cleanup() {
      observer.disconnect();
    };
  }, [scrollPaginationRef, onLoadNextPage, hasNextPage]);

  const SkeletonRows = () => (
    <ul className={classes.listBox}>
      {[...Array(4).keys()].map((element) => (
        <li key={`skeleton-option-${element}`} className={classes.optionLoaded}>
          <Skeleton height={60} variant="text" width="100%" />
        </li>
      ))}
    </ul>
  );

  function renderStateOptions() {
    if (isFilterLoading) {
      return <SkeletonRows />;
    }
    if (renderListOption().length === 0) {
      return <div className={classes.noOptions}>{noOptionsText}</div>;
    }
    if (renderListOption().length > 0) {
      return (
        <>
          <div>
            <ul className={classes.listBox}>{renderListOption()}</ul>
            {isNextPageLoading && <SkeletonRows />}
          </div>
          <Box ref={scrollPaginationRef} height={8} mb="0.5rem" name="actionListScroll" />
        </>
      );
    }

    return null;
  }

  return (
    <Box
      className={classes.containerListBox}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      {renderStateOptions()}
    </Box>
  );
}
PopupOptions.propTypes = {
  hasNextPage: PropTypes.bool,
  isNextPageLoading: PropTypes.bool,
  onLoadNextPage: PropTypes.func,
  renderListOption: PropTypes.func,
  noOptionsText: PropTypes.node,
  isFilterLoading: PropTypes.bool,
};

export default PopupOptions;
