import { Children } from 'react';
import PropTypes from 'prop-types';

import { SnackbarProvider } from 'notistack';

import Notification from '@design-system/Notification';

function NotificationProvider({ children }) {
  return (
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      Components={{
        success: Notification,
        error: Notification,
        warning: Notification,
      }}
      maxSnack={1}
      preventDuplicate
    >
      {Children.only(children)}
    </SnackbarProvider>
  );
}

NotificationProvider.propTypes = {
  children: PropTypes.element,
};

export default NotificationProvider;
