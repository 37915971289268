import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  backgroundColor?: string;
  size?: string | number;
}
const Sparkle = forwardRef(function Sparkle(props: Props, ref: Ref<SVGSVGElement>) {
  const { backgroundColor = '#F04E4A', size = 24, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        d="M12.5386 8.2167L14.5241 9L12.5386 9.7833C11.2775 10.2807 10.2803 11.278 9.78306 12.5392L9 14.5247L8.21694 12.5392C8.21693 12.5392 8.21692 12.5392 8.21691 12.5392C7.71962 11.278 6.72248 10.2807 5.46138 9.7833L3.47587 9L5.46138 8.2167C5.46141 8.21669 5.46143 8.21668 5.46145 8.21667C6.72251 7.71929 7.71963 6.72201 8.21691 5.46083C8.21692 5.46081 8.21693 5.46079 8.21694 5.46077L9 3.47529L9.78306 5.46077C10.2803 6.722 11.2775 7.71932 12.5386 8.2167Z"
        stroke={backgroundColor}
        strokeWidth="1.5"
      />
      <path
        d="M2.81016 3.16305L2.50094 3.72902L2.19183 3.16324C2.10569 3.00085 1.97603 2.88425 1.84282 2.81148C1.84267 2.8114 1.84252 2.81132 1.84237 2.81124L1.27098 2.49906L1.83696 2.18984C1.98659 2.11036 2.11037 1.98658 2.18984 1.83695L2.49906 1.27098L2.80817 1.83676C2.89432 1.99916 3.02397 2.11575 3.15718 2.18852C3.15733 2.1886 3.15748 2.18868 3.15763 2.18876L3.72902 2.50094L3.16305 2.81016C3.01341 2.88963 2.88963 3.01341 2.81016 3.16305Z"
        stroke={backgroundColor}
        strokeWidth="1.5"
      />
    </svg>
  );
});

export default Sparkle;
