import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    boxShadow: `0px 1px 0px 0px ${theme.palette.darkGrey[5]}`,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
