/* eslint-disable react/no-array-index-key */
// @ts-nocheck
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';

import InputAdornmentFiled from '@design-system/InputAdornmentFiled';
import IconButton from '@design-system/IconButton';

import IconSearchOutline from '@experimental-components/IconsComponents/SearchOutline';
import IconClose from '@experimental-components/IconsComponents/Close';

import PageError from 'components/PageError';
import DisplayMessage from 'components/DisplayMessageV2';

import { PropsTransferSKU, PropsItem, PropsTarget } from './utils';
import SkuSkeleton from './SkuSkeleton';
import useStyles from './styles';

function TransferSKU({
  isSearchFilter,
  labelOptionsAvailable,
  labelOptionsSelected,
  onChange,
  onSearch,
  options,
  optionSelected,
  placeholderLabel,
  scrollPaginationRef,
  skuListState,
  t,
}: PropsTransferSKU) {
  const classes = useStyles();
  const [skuSelected, setSkuSelected] = useState<PropsItem | null | string>(optionSelected);

  const handleOnSearch = ({ target: { value } }: PropsTarget) => {
    if (value.length >= 3) {
      onSearch(value);
    }

    if (!value.length) {
      onSearch('');
    }
  };

  const handleSelectSKU = (option: PropsItem) => {
    setSkuSelected(option);
    onChange(option?.sku);
  };

  const handleRemoveSKU = () => {
    setSkuSelected(null);
    onChange(null);
  };

  const handleRenderLeftOptions = () => {
    if (skuListState.error) {
      return (
        <Box className={classes.boxMessages}>
          <PageError
            id="productsListDataError"
            labelAction=""
            maxWidth={300}
            message={t('common:errors.loaded.errorConnection')}
            onAction=""
            title={t('common:errors.sorry')}
          />
        </Box>
      );
    }

    if (isSearchFilter && !skuListState?.fetching && !options[0]?.data?.length) {
      return (
        <Box className={classes.boxMessages}>
          <DisplayMessage
            height="90%"
            id="productsListEmptyData"
            maxWidth={300}
            message={t('common:messages.noResultsFoundSearchRetry')}
            sizeIcon={100}
            sizeMessage={16}
            sizeTitle={16}
            title={t('common:messages.titleNoResultsFoundSearch')}
          />
        </Box>
      );
    }

    if (skuListState.fetching && !skuListState.isFetchingNextPage) {
      return <SkuSkeleton.List />;
    }

    return (
      <>
        <List>
          {options?.map((group, i) => (
            <React.Fragment key={i}>
              {group?.data.map((store) =>
                store.items.map((option) => (
                  <ListItem
                    key={option?.uuid}
                    button
                    data-testid={`btnSelect_${option?.uuid}`}
                    disabled={Boolean(skuSelected)}
                    onClick={() => handleSelectSKU({ storeName: store?.name, ...option })}
                  >
                    <Box display="grid" rowGap={1}>
                      <Typography className={clsx(classes.itemText, { [classes.itemRemoved]: option?.removed })}>
                        {`${option?.name} (${option?.sku ? option?.sku : t('common:notAvailableDefault')})`}
                      </Typography>
                      <Typography className={classes.itemStore}>{store?.name}</Typography>
                    </Box>
                  </ListItem>
                )),
              )}
            </React.Fragment>
          ))}

          {skuListState.isFetchingNextPage && <SkuSkeleton.List />}
        </List>

        <Box ref={scrollPaginationRef} mb="0.5rem" />
      </>
    );
  };

  return (
    <Box className={classes.container} data-testid="transferSKU">
      <Box className={classes.inputSearch}>
        <InputAdornmentFiled
          fullWidth
          hideLabel
          hideLabelHelperTex
          id="header-form-search-box"
          onChange={handleOnSearch}
          placeholder={t(placeholderLabel)}
          startAdornment={
            <InputAdornment position="start">
              <IconSearchOutline />
            </InputAdornment>
          }
        />
      </Box>

      <Box className={classes.contentLists}>
        <Box className={classes.wrapperBox}>
          <Box className={classes.labelList}>{t(labelOptionsAvailable)}</Box>
          <Box className={classes.boxList}>{handleRenderLeftOptions()}</Box>
        </Box>

        <Box width="100%">
          <Box className={classes.labelList}>{t(labelOptionsSelected)}</Box>

          {skuSelected && (
            <Box className={classes.boxItem}>
              {skuSelected?.storeName ? (
                <Box display="grid" rowGap={1}>
                  <Typography className={clsx(classes.itemText, { [classes.itemRemoved]: skuSelected?.removed })}>
                    {`${skuSelected?.name} (${skuSelected?.sku ? skuSelected?.sku : t('common:notAvailableDefault')})`}
                  </Typography>
                  <Typography className={classes.itemStore}>{skuSelected?.storeName}</Typography>
                </Box>
              ) : (
                <Typography className={classes.itemText}>{skuSelected}</Typography>
              )}

              <IconButton onClick={() => handleRemoveSKU()} size="large">
                <IconClose size={11} />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default TransferSKU;
