import { NavigationType } from '@experimental-components/TopNavigationBar/types';

import { reportsTypes } from 'utils/reports';

const transFormTitle = (level: string, location?: Array<string> | string) => {
  let title = '';
  if (level) title += level;
  if (level && location) title += ' — ';
  if (Array.isArray(location)) title += location.join(', ');
  if (typeof location === 'string') title += location;
  return title;
};

export const getTitle = (type: string, level?: string, location?: Array<string> | string) => {
  let currentLevel = '';
  let currentLocation = location;
  if (level && type === NavigationType.ORGANIZATION) currentLevel = level;
  if (type === NavigationType.CEAL) currentLevel = 'Centros de almacenamiento';
  if (level && type === NavigationType.STORE_DRAFT) {
    currentLevel = level;
    currentLocation = '';
  }

  return transFormTitle(currentLevel, currentLocation);
};

export const getLabelButton = (type: string) => {
  if (type === NavigationType.ORGANIZATION || type === NavigationType.STORE_DRAFT) {
    return 'common:topNavigationBar.buttonGoStore';
  }

  return 'common:topNavigationBar.buttonGoOrg';
};

export const relocatePage = (
  location: { pathname: string; search: string },
  replace: (values: { pathname: string; search: string } | string) => void,
) => {
  const { pathname, search } = location;

  switch (true) {
    case pathname.includes(`/reports/${reportsTypes.PRODUCTS_SALES}`): {
      replace(`/reports/${reportsTypes.PRODUCTS_SALES}/summary`);
      break;
    }

    case pathname.includes(`/reports/${reportsTypes.CASH_REGISTER}`): {
      replace(`/reports/${reportsTypes.CASH_REGISTER}/list`);
      break;
    }

    case pathname.includes(`/reports/${reportsTypes.PAYMENTS}`): {
      replace({ pathname: `/reports/${reportsTypes.PAYMENTS}/summary`, search });
      break;
    }

    case pathname.includes(`/reports/${reportsTypes.CANCELLATIONS}`): {
      replace(`/reports/${reportsTypes.CANCELLATIONS}/summary`);
      break;
    }

    case pathname.includes(`/reports/${reportsTypes.DISCOUNTS}`): {
      replace(`/reports/${reportsTypes.DISCOUNTS}/summary`);
      break;
    }

    case pathname.includes(`/reports/${reportsTypes.CLIENT_DATA}`): {
      replace(`/reports/${reportsTypes.CLIENT_DATA}/summary`);
      break;
    }

    case pathname.includes(`/reports/${reportsTypes.TRANSACTIONS_PAY}`) ||
      pathname.includes('/reports/transfers-pay') ||
      pathname.includes('/reports/movements-pay'): {
      replace({ pathname: `/reports/${reportsTypes.TRANSACTIONS_PAY}`, search });
      break;
    }

    case pathname.includes('/workforce/roles'): {
      replace('/workforce/roles');
      break;
    }

    case pathname.includes('/workforce/people'): {
      replace('/workforce/people');
      break;
    }

    case pathname.includes('/invoicing'): {
      replace('/invoicing');
      break;
    }

    case pathname.includes('/settings'): {
      replace('/settings');
      break;
    }

    case pathname.includes('/subscription'): {
      replace('/subscription');
      break;
    }

    case pathname.includes('/online-ordering'): {
      replace('/online-ordering');
      break;
    }

    case pathname.includes('/menus'): {
      replace({ pathname, search: '' });
      break;
    }

    default:
  }
};
