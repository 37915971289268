import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getRemoteConfig } from 'firebase/remote-config';

import defaultFlags from 'utils/featureFlags/defaultFlags';

const firebaseConfig = {
  apiKey: 'AIzaSyCzeN2UU8JEp_H5zPmZ3zM8KEsRoIh6VWk',
  authDomain: 'featureflags-8034b.firebaseapp.com',
  projectId: 'featureflags-8034b',
  storageBucket: 'featureflags-8034b.appspot.com',
  messagingSenderId: '89160173915',
  appId: '1:89160173915:web:c690481b7c5b723ded727e',
};

const firebaseConfigStaging = {
  apiKey: 'AIzaSyC-rzT-myiPW2ZLpJc6HhqbDk8nMIzFDsU',
  authDomain: 'parrot-connectweb---staging.firebaseapp.com',
  projectId: 'parrot-connectweb---staging',
  storageBucket: 'parrot-connectweb---staging.appspot.com',
  messagingSenderId: '602020395686',
  appId: '1:602020395686:web:bc914b0b220a572345d267',
};

const app = initializeApp(process.env.REACT_APP_VERCEL_ENV === 'production' ? firebaseConfig : firebaseConfigStaging);
export const analytics = getAnalytics(app);

export const getRemoteConfigFlag = () => {
  const remoteConfig = getRemoteConfig(app);

  remoteConfig.settings.minimumFetchIntervalMillis = process.env.REACT_APP_VERCEL_ENV === 'production' ? 450 : 0;
  remoteConfig.defaultConfig = defaultFlags;
  return remoteConfig;
};
