import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  headerLayout: {
    marginBottom: '0px',
  },
  root: {
    width: ({ openDrawer }) => `calc(100% - ${openDrawer ? '496px' : '0px'})`,
    height: ({ isModeDraft }) => `calc(100vh - ${theme.typography.pxToRem(isModeDraft ? 193 : 137)})`,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: ({ openDrawer }) => `${openDrawer ? '0px' : '100%'}`,
    },
  },
  buttonReduce: {
    '& > div > button': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  floatingBar: {
    position: 'absolute',
    zIndex: 1400,
    top: '8px',
    right: '16px',
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 8px 40px rgba(133, 133, 133, 0.2)',
  },
  menuItem: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
    width: '100%',
    minWidth: 100,
  },
  paper: {
    backgroundColor: 'purple',
    maxWidth: '150px',
  },
}));

export default useStyles;
