import { memo } from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import getDisplayName from 'utils/getDisplayName';

import { getAllInvoicesUninvoicedOrders, getAllInvoicesUninvoicedOrdersPagination, generateInvoicing } from './actions';
import {
  selectAllUninvoicedOrders,
  selectAllUninvoicedOrdersState,
  selectPaginationScrollInvoicesStateState,
  selectGenerateInvoicingState,
  selectAllUninvoicedOrdersPaginationInfo,
  selectGenerateInvoicingInfo,
  selectInvoicedOrdersSortingState,
} from './selectors';

export function withInvoicesGlobalHoc(Component) {
  function withInvoicingGlobal(props) {
    return <Component {...props} />;
  }

  withInvoicingGlobal.displayName = `withInvoicingGlobal(${getDisplayName(Component)})`; // Display Name for Easy Debugging
  return withInvoicingGlobal;
}

const mapStateToProps = createStructuredSelector({
  allUninvoicedOrders: selectAllUninvoicedOrders,
  allUninvoicedOrdersState: selectAllUninvoicedOrdersState,
  allUninvoicedOrdersPaginationInfo: selectAllUninvoicedOrdersPaginationInfo,
  paginationScrollInvoicesStateState: selectPaginationScrollInvoicesStateState,
  generateInvoicingState: selectGenerateInvoicingState,
  generateInvoicingInfo: selectGenerateInvoicingInfo,
  invoicedOrdersSortingState: selectInvoicedOrdersSortingState,
});

export function mapDispatchToProps(dispatch) {
  return {
    loadAllInvoicesUninvoicedOrders: (filters = {}) => dispatch(getAllInvoicesUninvoicedOrders(filters)),
    sortingAllInvoicesUninvoicedOrders: (filters = {}) => dispatch(getAllInvoicesUninvoicedOrders.sorting(filters)),
    loadAllInvoicesUninvoicedOrdersPagination: (filters = {}) =>
      dispatch(getAllInvoicesUninvoicedOrdersPagination(filters)),
    generateInvoicing: (filters = {}, metricsParams = {}) => dispatch(generateInvoicing({ filters, metricsParams })),
    loadAllInvoicesUninvoicedOrdersReset: () => dispatch(getAllInvoicesUninvoicedOrders.reset()),
    generateInvoicingReset: () => dispatch(generateInvoicing.reset()),
  };
}

export const withInvoicingGlobalState = connect(mapStateToProps, mapDispatchToProps);
export default compose(memo, withInvoicingGlobalState, withInvoicesGlobalHoc);
