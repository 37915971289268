import { Filters, FiltersQueryParams } from 'containers/ReportModifiers/types';
import dayjs from 'dayjs';
import { formatTypesDates } from 'utils/times';

export const parseValueToString = (value: string[]) => {
  let filterArray: Array<string> = [];
  let valueFiltered = value;

  if (value !== undefined && value?.length > 0) {
    if (value.includes('')) {
      valueFiltered = valueFiltered.filter((item) => item !== '');
    }
    filterArray = [valueFiltered.join(',')];
  }

  return filterArray;
};

export const transformFilters = (filters: Filters) => {
  const starHours = '00:00:00';
  const endHours = '23:59:59';

  return {
    startDate: filters.startDate || `${dayjs().format(formatTypesDates.SEND_FORM)} ${starHours}`,
    endDate: filters.endDate || `${dayjs().format(formatTypesDates.SEND_FORM)} ${endHours}`,
    brands: parseValueToString(filters.brands || []),
    providers: parseValueToString(filters.providers || []),
  };
};

export const transformParamsToFilters = (filters: FiltersQueryParams) => {
  return {
    startDate: filters.startDate,
    endDate: filters.endDate,
    brands: filters.brands?.split(',') || [],
    providers: filters.providers?.split(',') || [],
  };
};
