import { useFeatures } from 'FeatureProvider';

import { getUserCurrentStore } from 'utils/auth';

type Flags = { [key: string]: boolean | { storeUuidList: string[] } };
type FlagName =
  | 'ffEnableWsOptExportModifierReport'
  | 'ffActiveConciliationBanner'
  | 'ffValidateItemsAvailabilityByStore'
  | 'ffEnableAverageTicketReport';

export const useGetEnableGeneralFlag = (flagName: FlagName): boolean => {
  const currentStore = getUserCurrentStore()?.uuid as string;
  const { flags } = useFeatures() as Flags;
  const flag = flags[flagName];

  if (flag === undefined) return true;

  if (typeof flag === 'boolean') return flag;

  return flag?.storeUuidList?.includes(currentStore);
};
