import React from 'react';
import type { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import InputAdornmentMui from '@mui/material/InputAdornment';

interface StyleProps {
  disabled?: boolean;
  error?: boolean;
}

const InputRoot = withStyles((theme: Theme) => {
  const placeholder = {
    color: theme.palette.darkGrey[50],
    opacity: 1,
  };

  const placeholderHidden = {
    opacity: 0.5,
  };

  const placeholderVisible = {
    opacity: 0.5,
  };

  const getBorder = (props: StyleProps) => {
    if (props?.disabled) {
      return `1px solid ${theme.palette.darkGrey[15]}`;
    }

    if (props?.error) {
      return `1px solid ${theme.palette.error.main}`;
    }

    return `1px solid ${theme.palette.common.black}`;
  };

  const borderColor = (props: StyleProps) => getBorder(props);

  const getBorderColor = (props: StyleProps) => {
    if (props?.disabled) {
      return theme.palette.darkGrey[15];
    }

    if (props?.error) {
      return theme.palette.error.main;
    }

    return theme.palette.darkGrey[50];
  };

  const getBackgroundColor = (props: StyleProps) => {
    if (props?.disabled) {
      return theme.palette.darkGrey[2];
    }

    if (props?.error) {
      return theme.palette.error.light;
    }

    return theme.palette.common.white;
  };

  return {
    root: {
      backgroundColor: (props) => getBackgroundColor(props),
      borderRadius: theme.shape.borderRadius,
      height: theme.typography.pxToRem(42),

      '&:hover $notchedOutline': {
        borderColor: (props: StyleProps) => getBorder(props),
        borderWidth: 1,
      },

      '@media (hover: none)': {
        '&:hover $notchedOutline': {
          borderColor: (props: StyleProps) => getBorderColor(props),
          borderWidth: 1,
        },
      },
      '&$focused $notchedOutline': {
        borderColor,
        borderWidth: 1,
      },
      '&$error $notchedOutline': {
        borderColor,
        borderWidth: 1,
      },
      '&$disabled $notchedOutline': {
        borderColor: (props: StyleProps) => getBorderColor(props),
        borderWidth: 1,
      },

      'label + &': {
        marginTop: theme.spacing(1),
      },
    },

    notchedOutline: {
      borderColor: (props) => getBorderColor(props),
      borderWidth: 1,
    },

    focused: {
      borderWidth: 1,
    },

    disabled: {
      color: theme.palette.darkGrey[15],
    },

    error: {
      color: theme.palette.error.main,
    },

    input: {
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontSize: theme.typography.pxToRem(14),
      padding: theme.typography.pxToRem(12),
      boxSizing: 'border-box',
      '&::-webkit-input-placeholder': placeholder,
      '&::-moz-placeholder': placeholder, // Firefox 19+
      '&:-ms-input-placeholder': placeholder, // IE 11
      '&::-ms-input-placeholder': placeholder, // Edge

      'label[data-shrink=false] + $formControl &': {
        '&::-webkit-input-placeholder': placeholderHidden,
        '&::-moz-placeholder': placeholderHidden, // Firefox 19+
        '&:-ms-input-placeholder': placeholderHidden, // IE 11
        '&::-ms-input-placeholder': placeholderHidden, // Edge
        '&:focus::-webkit-input-placeholder': placeholderVisible,
        '&:focus::-moz-placeholder': placeholderVisible, // Firefox 19+
        '&:focus:-ms-input-placeholder': placeholderVisible, // IE 11
        '&:focus::-ms-input-placeholder': placeholderVisible, // Edge
      },
    },

    inputAdornedStart: {
      paddingLeft: 16,
    },

    adornedEnd: {
      paddingRight: 0,
    },

    inputAdornedEnd: {
      paddingRight: 8,
    },
  };
})(OutlinedInput);

const InputLabelRoot = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: theme.palette.common.black,
    position: 'relative',
    lineHeight: 1,
  },

  shrink: {
    transform: 'translate(0, 1.5px) scale(1)',
    transformOrigin: 'top left',
  },

  disabled: {
    color: theme.palette.darkGrey[50],
  },
}))(InputLabel);

const FormHelperTextRoot = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.darkGrey[50],
    fontSize: theme.typography.pxToRem(14),
  },
}))(FormHelperText);

const InputAdornment = withStyles(() => ({
  root: {
    height: 'auto',
    maxHeight: '100%',
  },
  positionEnd: {
    marginLeft: 0,
  },
}))(InputAdornmentMui);

export interface InputBaseProps extends OutlinedInputProps {
  id?: string;
  error?: boolean;
  isFullWidth?: boolean;
  label?: string | null;
  helperText?: string;
  errorMsg?: string;
  disabled?: boolean;
  required?: boolean;
  endAdornment?: React.ReactNode;
}

function InputBase({
  id,
  error,
  isFullWidth,
  label = null,
  helperText,
  errorMsg,
  disabled,
  required,
  endAdornment,
  ...others
}: InputBaseProps) {
  return (
    <FormControl disabled={disabled} error={error} fullWidth={isFullWidth} required={required} variant="standard">
      {label && (
        <InputLabelRoot htmlFor={id} shrink>
          {label}
        </InputLabelRoot>
      )}

      <InputRoot
        disabled={disabled}
        endAdornment={<InputAdornment position="end">{endAdornment}</InputAdornment>}
        error={error}
        id={id}
        required={required}
        {...others}
      />

      <FormHelperTextRoot id={`${id}HelperText`}>{errorMsg || helperText}</FormHelperTextRoot>
    </FormControl>
  );
}

export default InputBase;
