import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  linkCell: {
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'left',
    width: theme.spacing(20),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  itemSku: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.darkGrey[50],
    marginTop: theme.spacing(1),
  },
  rowCheckbox: {
    paddingLeft: '2rem',
    paddingRight: 0,
  },
  rowImage: {
    padding: '2rem 1rem',
  },
}));

export default useStyles;
