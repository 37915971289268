import PropTypes from 'prop-types';

import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import Button from '@mui/material/Button';

import useEnvironment from 'hooks/useEnvironment';

import useStyles from './styles';

function SidebarLayoutButton({ disabled, label, onClick, startIcon, endIcon, openMenu, ...others }) {
  const { isTestEnv } = useEnvironment();
  const classes = useStyles({ isTestEnv, isOpenMenu: openMenu });

  return (
    <li className={classes.navLiButton} data-testid="navLinkButton">
      <NavLink
        className={clsx(classes.navButton, {
          [classes.disabled]: disabled,
        })}
        disabled={disabled}
        exact
        onClick={onClick}
        {...others}
      >
        <Button
          classes={{ root: classes.buttonCard, startIcon: openMenu ? classes.startIcon : classes.startIconV2 }}
          className={clsx({
            [classes.buttonCardCollapseMenu]: !openMenu,
          })}
          startIcon={startIcon}
        >
          {openMenu && label && (
            <>
              <span className={classes.navLinkLabel} data-testid="navLinkLabel" style={{ textAlign: 'initial' }}>
                {label}
              </span>
              {endIcon && endIcon}
            </>
          )}
        </Button>
      </NavLink>
    </li>
  );
}

SidebarLayoutButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  startIcon: PropTypes.node,
  onClick: PropTypes.func,
};

export default SidebarLayoutButton;
