import { useContext } from 'react';
import { SchedulesType } from 'components/ReportSalesPerTime/types';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

import useNotification from '@design-system/Notification/useNotification';

import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';

import { getUserCurrentStore } from 'utils/auth';
import { getSalesSchedules, saveSalesSchedules } from 'utils/api/baas/reports/salesPerTime';

import dayjs from 'dayjs';

import { formatTypesDates } from 'utils/times';

import { getSalesPerTimeLines, getSalesPerTimeSummary } from './services';
import { FiltersContext } from './context';

export function useFilters() {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFilters must be used within an FiltersProvider');
  }

  return context;
}

export function useGetSalesSchedules() {
  const { data, isError, isFetched, isFetching, remove, refetch } = useQuery(
    'schedules_sales_per_time',
    getSalesSchedules,
    { retry: 1, select: (response) => response.data },
  );

  return {
    schedulesStatus: { isError, isFetched, isFetching },
    schedulesData: data,
    removeSchedulesData: remove,
    loadSchedules: refetch,
  };
}

export function useSaveSalesSchedules() {
  const { t } = useTranslation('reports');
  const { setNotification } = useNotification();
  const queryClient = useQueryClient();
  const { logEvent } = useAmplitude();
  const storeUuid = getUserCurrentStore()?.uuid;

  const getSchedule = (item: SchedulesType) => `${item.name}: ${item.startHour} - ${item.endHour}`;

  return useMutation((values: SchedulesType[]) => saveSalesSchedules(values), {
    mutationKey: 'save-sales-schedules',
    retry: 1,
    onError: () => {
      setNotification(t('salesPerTimeReport.form.errors.saveError'), { variant: 'error' });
    },
    onSuccess: (response) => {
      const activeList = response.data?.filter(({ status }: SchedulesType) => status === 'ACTIVE').map(getSchedule);
      const deletedList = response.data?.filter(({ status }: SchedulesType) => status === 'DELETED').map(getSchedule);

      if (activeList?.length) {
        logEvent('@event.$reports.viewReport', ['@@section.salesPerTime', '@@type.summary'], {
          storeId: storeUuid,
          schedules: activeList,
        });
      }

      if (deletedList?.length) {
        logEvent('@event.$reports.viewReport', ['@@section.salesPerTime', '@@type.delete'], {
          storeId: storeUuid,
          schedules: deletedList,
        });
      }

      queryClient.invalidateQueries(['schedules_sales_per_time'], response);
      setNotification(t('salesPerTimeReport.form.errors.saveSuccess'), { variant: 'success' });
    },
  });
}

export function useGetReportSalesPerTimeSummary() {
  const { filters } = useFilters();

  const { data, isFetched, isFetching, isError, refetch, remove } = useQuery(
    'salesPerTimeSummary',
    () =>
      getSalesPerTimeSummary({
        startDate: dayjs(filters.startDate).format(formatTypesDates.SEND_FORM),
        endDate: dayjs(filters.endDate).format(formatTypesDates.SEND_FORM),
      }),
    {
      initialData: [],
      enabled: false,
      retry: 1,
    },
  );

  return {
    data: data ?? [],
    loadSalesPerTimeSummary: refetch,
    salesPerTimeSummaryState: {
      fetched: isFetched,
      fetching: isFetching,
      error: isError,
    },
    removeSalesPerTimeSummary: remove,
  };
}

export function useGetReportSalesPerTimeLines() {
  const { filters } = useFilters();

  const { data, isFetched, isFetching, isError, refetch, remove } = useQuery(
    'salesPerTimeLines',
    () =>
      getSalesPerTimeLines({
        startDate: dayjs(filters.startDate).format(formatTypesDates.SEND_FORM),
        endDate: dayjs(filters.endDate).format(formatTypesDates.SEND_FORM),
      }),
    {
      enabled: false,
      retry: 1,
    },
  );

  return {
    dataChartLines: data,
    loadSalesPerTimeLines: refetch,
    salesPerTimeLinesState: {
      fetched: isFetched,
      fetching: isFetching,
      error: isError,
    },
    removeSalesPerTimeLines: remove,
  };
}
