import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';

interface PropsChip {
  backgroundChip?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  rootInitSetup: {
    marginTop: '10%',
  },

  containerInitSetup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(3),
  },

  title: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 500,
  },

  subtitle: {
    marginTop: '-10px',
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.darkGrey.main,
  },

  descriptionInitSetup: {
    fontSize: theme.typography.pxToRem(16),
    whiteSpace: 'break-spaces',
    textAlign: 'center',
    lineHeight: theme.typography.pxToRem(20),
    marginBottom: theme.spacing(1),
  },

  messageInitSetup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  titleDrawer: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500,
  },

  descriptionDrawer: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.darkGrey.main,
  },

  close: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },

  scheduleError: {
    marginTop: '3px',
    paddingInline: theme.typography.pxToRem(24),
    color: theme.palette.error.main,
    '& > p': {
      fontSize: theme.typography.pxToRem(12),
    },
  },

  filters: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(4),
    paddingBlock: theme.spacing(4),

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  wrapperChip: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),
  },

  filterChip: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },

  labelChip: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
  },

  chip: {
    display: 'flex',
    alignItems: 'center',
    height: 42,
    width: 'fit-content',
    padding: theme.typography.pxToRem(12),
    backgroundColor: ({ backgroundChip }: PropsChip) => backgroundChip,
    color: theme.palette.background.default,
    fontWeight: 500,
    borderRadius: '30px',
    textWrap: 'nowrap',
    lineHeight: 'normal',
  },

  containerReport: {
    marginTop: theme.spacing(1),
    display: 'grid',
    gap: theme.spacing(4),
    gridTemplateColumns: 'repeat(2, minmax(48.5%, 48.5%))',

    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(2, minmax(47%, 47%))',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '99%',
    },
  },

  titleSection: {
    paddingLeft: theme.spacing(4),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 500,
  },
  deletePaddingLeft: {
    paddingLeft: 0,
  },
  summaryRow: {
    '& > :first-child': {
      paddingLeft: `${theme.typography.pxToRem(32)} !important`,
    },
    '& > :last-child': {
      paddingRight: `${theme.typography.pxToRem(32)} !important`,
    },
  },

  summaryCell: {
    padding: `${theme.typography.pxToRem(11)} !important`,
  },

  boldCell: {
    'font-weight': '500 !important',
  },
}));

export default useStyles;
