import { Box } from '@mui/material';

import ButtonOutlined from '@design-system/ButtonOutlined';
import ButtonPrimary from '@design-system/ButtonPrimary';

interface Props {
  onCancel: () => void;
  onSubmit?: () => void;
  isDisabled: boolean;
  onSubmitLabel?: string;
}

function Buttons({ onCancel, isDisabled, onSubmit, onSubmitLabel = 'Agregar' }: Props) {
  return (
    <>
      <Box maxWidth={242} width="100%">
        <ButtonOutlined fullWidth onClick={onCancel}>
          Cancelar
        </ButtonOutlined>
      </Box>
      <Box maxWidth={242} width="100%">
        <ButtonPrimary
          disabled={isDisabled}
          fullWidth
          onClick={() => {
            if (onSubmit) onSubmit();
          }}
          type={onSubmit ? 'button' : 'submit'}
        >
          {onSubmitLabel}
        </ButtonPrimary>
      </Box>
    </>
  );
}

export default Buttons;
