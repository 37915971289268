import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Typography from '@mui/material/Typography';

import capitalize from 'utils/capitalize';

import useStyles from './styles';

const typeIconComponents = {
  error: CancelOutlinedIcon,
  info: InfoOutlinedIcon,
  searchInfo: SearchOutlinedIcon,
  success: CheckCircleOutlineOutlinedIcon,
  warning: ReportProblemOutlinedIcon,
};

function DisplayMessage({ severity, labelAction, title, message, onAction }) {
  const classes = useStyles();

  const TypeIconComponent = typeIconComponents[severity];

  return (
    <div className={classes.root}>
      <TypeIconComponent className={clsx(classes[`icon${capitalize(severity)}`])} size="large" />
      <Box mt={3}>
        <Typography variant="h5">{title}</Typography>
      </Box>

      <div className={classes.divider} />

      <Box mb={5} width="50%">
        <Typography align="center" variant="body1">
          {message}
        </Typography>
      </Box>

      {onAction && (
        <Button color="primary" onClick={onAction} variant="contained">
          {labelAction}
        </Button>
      )}
    </div>
  );
}

DisplayMessage.propTypes = {
  title: PropTypes.string,
  labelAction: PropTypes.string,
  message: PropTypes.string,
  onAction: PropTypes.func,
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning', 'searchInfo']),
};

export default DisplayMessage;
