import Box from '@mui/material/Box';

import { warningsListLabels } from 'utils/menus/menuCopy';

import useStyles from './styles';

interface Props {
  t: (value: string) => string;
}

function WarningsList({ t }: Props) {
  const classes = useStyles({ selectedCard: undefined });

  return (
    <Box display="grid" pl={10} pr={12} rowGap={0.5}>
      {warningsListLabels.map((item, index) => (
        <Box key={item} alignItems="center" display="flex" gap={3}>
          <Box className={classes.warningNumber}>{index + 1}</Box>

          <Box component="p" fontSize={18}>
            {t(item)}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default WarningsList;
