import { debounce } from 'lodash';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import InputAdornment from '@mui/material/InputAdornment';

import InputAdornmentFiled from '@design-system/InputAdornmentFiled';
import IconSearchOutline from '@experimental-components/IconsComponents/SearchOutline';
import ButtonPlus from '@experimental-components/ButtonPlus';

interface Props {
  children?: React.ReactDOM;
  buttonLabel?: string;
  delay?: number;
  mb?: number;
  mt?: number;
  onButtonClick?: (event: React.MouseEvent<HTMLElement>) => void;
  onChangeSearch: (value: string) => void;
  searchLabel?: string;
  searchPlaceHolder?: string;
  title?: string;
}

const useStyles = makeStyles(() => ({
  searchItem: {
    maxWidth: '375px',
    width: '100%',
  },
}));

function CatalogHeader({
  children,
  buttonLabel,
  delay = 300,
  mb = 6,
  mt = 2,
  onButtonClick,
  onChangeSearch,
  searchLabel,
  searchPlaceHolder,
  title,
}: Props) {
  const classes = useStyles();

  function handleOnSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement;
    onChangeSearch(target.value || '');
  }

  return (
    <Box mb={mb} mt={mt} pl={4} pr={4}>
      <Box fontSize={24} fontWeight="fontWeightMedium" mb={4}>
        {title}
      </Box>
      <Box>
        <Grid container spacing={2}>
          <>
            <Grid item sm={6}>
              <InputAdornmentFiled
                className={classes.searchItem}
                fullWidth
                hideLabel={Boolean(!searchLabel)}
                id="header-form-search-box"
                label={searchLabel}
                name="stockCategoryCodeFiled"
                onChange={debounce(handleOnSearch, delay)}
                placeholder={searchPlaceHolder}
                startAdornment={
                  <InputAdornment position="start">
                    <IconSearchOutline />
                  </InputAdornment>
                }
              />
            </Grid>
            {buttonLabel && (
              <Grid item sm={6}>
                <Box display="flex" justifyContent="flex-end">
                  <ButtonPlus disabled={false} height={56} isDisabled={false} onClick={onButtonClick}>
                    {buttonLabel}
                  </ButtonPlus>
                </Box>
              </Grid>
            )}
            {children}
          </>
        </Grid>
      </Box>
    </Box>
  );
}

export default CatalogHeader;
