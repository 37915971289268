import { Switch, Route } from 'react-router-dom';

import { useFeature } from 'FeatureProvider';

import Roles from 'containers/Roles/Loadable';
import Role from 'containers/Role/Loadable';
import Employees from 'containers/Employees/Loadable';
import EmployeesDetails from 'containers/EmployeesDetails/Loadable';

import NotFound from 'components/NotFound';

import useUserStore from 'hooks/useUserStore';

import AppRoute from '../PrivateRoute';

export function WorkforceRoutes() {
  const {
    storeState: { selected: storeSelected },
  } = useUserStore();
  const superUserAccess = useFeature('adminStore');
  const workforceModuleAccess = useFeature('backofficeModuleWorkforce');
  const permissionWorkforceModule = (superUserAccess || workforceModuleAccess) && !storeSelected?.isDraft;

  return (
    <Switch>
      <AppRoute accessRoute={permissionWorkforceModule} component={Roles} exact path="/workforce/roles/(create)?" />
      <AppRoute
        accessRoute={permissionWorkforceModule}
        component={Role}
        exact
        path="/workforce/roles/details/:roleUuid/edit"
      />
      <AppRoute
        accessRoute={permissionWorkforceModule}
        component={Employees}
        exact
        path="/workforce/people/:actionType(create)?"
      />
      <AppRoute
        accessRoute={permissionWorkforceModule}
        component={EmployeesDetails}
        exact
        path="/workforce/people/details/:employeeUuid/:actionType(duplicate|change-password)?/(edit)?"
      />
      <Route component={NotFound} />
    </Switch>
  );
}
