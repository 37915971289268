import { ClassNameMap } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import SwitchMui, { SwitchClassKey, SwitchProps } from '@mui/material/Switch';
import FormControlLabelMui, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import type { Theme } from '@mui/material/styles';

interface SwitchPropsCustom extends SwitchProps {
  defaultChecked: boolean;
  classes: Partial<ClassNameMap<SwitchClassKey>>;
}

const SwitchStyle = withStyles((theme: Theme) => ({
  root: {
    width: 48,
    height: 24,
    padding: 0,
  },

  switchBase: {
    padding: 2,
    color: theme.palette.darkGrey[50],

    '&$checked': {
      transform: 'translateX(24px)',
      color: (props: { disabled: boolean }) =>
        props.disabled ? theme.palette.darkGrey[50] : theme.palette.secondary.main,

      '& + $track': {
        backgroundColor: theme.palette.darkGrey[2],
        opacity: 1,
        border: `0.5px solid ${theme.palette.darkGrey[50]}`,
      },
    },

    '&$focusVisible $thumb': {
      color: theme.palette.darkGrey[50],
      border: `0.5px solid ${theme.palette.darkGrey[50]}`,
    },
  },

  thumb: {
    width: 20,
    height: 20,
    boxShadow: 'none',
  },

  track: {
    borderRadius: 26 / 2,
    border: `0.5px solid ${theme.palette.darkGrey[15]}`,
    backgroundColor: theme.palette.darkGrey[2],
    opacity: 1,
  },

  checked: {
    transform: 'translateX(24px)',
    color: (props: { disabled: boolean }) =>
      props.disabled ? theme.palette.darkGrey[50] : theme.palette.secondary.main,

    '& + $track': {
      backgroundColor: theme.palette.darkGrey[2],
      opacity: 1,
      border: `0.5px solid ${theme.palette.darkGrey[50]}`,
    },
  },
  focusVisible: {},
  disabled: {},
}))(({ classes, ...props }: SwitchPropsCustom) => {
  return (
    <SwitchMui
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      disableRipple
      {...props}
    />
  );
});

const FormControlLabel = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
  },
  label: {
    fontWeight: 500,
    marginLeft: ({ label }: { label: React.ReactNode }) => theme.spacing(label ? 2 : 0),
    fontSize: 14,
    '&$disabled': {
      color: theme.palette.darkGrey[15],
    },
  },
  disabled: {
    color: theme.palette.darkGrey[15],
  },
}))(FormControlLabelMui);

interface Props extends FormControlLabelProps {
  defaultChecked: boolean;
  disabled?: boolean;
}

function Switch({ defaultChecked, disabled = false, ...others }: Props) {
  const formCtrlLabel = {
    ...others,
    control: <SwitchStyle defaultChecked={defaultChecked} disabled={disabled} />,
    disabled,
  };

  return <FormControlLabel {...formCtrlLabel} />;
}

export default Switch;
