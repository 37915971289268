import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import SignIn from 'containers/SignIn/Loadable';
import SignOut from 'containers/SignOut';
import AccessDeniedAdmin from 'containers/AccessDeniedAdmin/Loadable';

import LoggedOutPage from 'components/LoggedOutPage';
import NotFound from 'components/NotFound';
import WithoutStore from 'components/WithoutStore';

import { useAuth } from 'hooks/useAuth';
import useCommandBar from 'hooks/useCommandBar';

import AppRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { HomeRedirect } from './HomeRedirect';
import { InventoryRoutes } from './AppRoutesModules/InventoriesRoutes';
import { InvoicingRoutes } from './AppRoutesModules/InvoicingRoutes';
import { ReportsRoutes } from './AppRoutesModules/ReportsRoutes';
import { OnlineOrderingRoutes } from './AppRoutesModules/OnlineOrderingRoutes';
import { SettingsRoutes } from './AppRoutesModules/SettingsRoutes';
import { WorkforceRoutes } from './AppRoutesModules/WorkforceRoutes';
import { MenuMakerRoutes } from './AppRoutesModules/MenuMakerRoutes';
import { SubscriptionRoutes } from './AppRoutesModules/SubscriptionRoutes';

function App() {
  const { isAuthenticated, user, store } = useAuth();
  useCommandBar();
  const { i18n } = useTranslation();
  const isInternal = /@parrotsoftware.io/.test(user?.email || '') ? ';internal' : '';
  const location = useLocation();

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (window._cio) {
      _cio.page(`${window.location.origin}${location.pathname}`);
    }
  }, [location]);

  return (
    <>
      <Helmet defaultTitle="ParrotConnect" htmlAttributes={{ lang: i18n.language }} titleTemplate="%s - ParrotConnect">
        <meta content="ParrotConnect" name="ParrotConnect" />
        {isAuthenticated && (
          <>
            {user?.email && store?.organizationUuid ? (
              <script type="text/javascript">
                {`
                  _cio.identify({
                    id: '${store?.uuid}',
                    name: '${store?.name}',
                    email: '${user?.email}',
                    organization: '${store?.organizationUuid}',
                    isInternal: '${!!isInternal}',
                    });
                  `}
              </script>
            ) : null}
            {user?.email && user?.uuid && store?.uuid ? (
              <script>
                {`Canny(
                  'identify',
                  {
                    appID: '6698575a7db4ab6da5905a38',
                    user: {
                      email: '${user.email}',
                      id: '${user.uuid}',
                      name: '${user.firstName || user.email} ${user.lastName || ''}',
                      customFields: {
                        storeUuid: '${store.uuid}',
                      },
                    },
                  })`}
              </script>
            ) : null}
          </>
        )}
      </Helmet>

      <Switch>
        <Route component={HomeRedirect} exact path="/" />
        <Redirect exact from="/workforce" to="/workforce/roles" />
        <Route component={WithoutStore} exact path="/without-store" />
        <PublicRoute component={SignIn} exact path="/signin" />
        <Route component={LoggedOutPage} exact path="/logged-out" />
        <Route component={SignOut} exact path="/signout" />
        <AppRoute component={AccessDeniedAdmin} exact path="/permissions-denied" />

        {/* Menu Maker Module */}
        <Route component={MenuMakerRoutes} path="/menus" />

        {/* Workforce Module */}
        <Route component={WorkforceRoutes} path="/workforce" />

        {/* Invoicing Module */}
        <Route component={InvoicingRoutes} path="/invoicing" />

        {/* Settings Module */}
        <Route component={SettingsRoutes} path="/settings" />

        {/* Reports Module */}
        <Route component={ReportsRoutes} path="/reports" />

        {/* Inventories Module */}
        <Route component={InventoryRoutes} path="/inventories" />

        {/* Online Ordering Module */}
        <Route component={OnlineOrderingRoutes} path="/online-ordering" />

        {/* Suscription Module */}
        <Route component={SubscriptionRoutes} path="/subscription" />

        {/* Not found page */}
        <Route component={NotFound} />
      </Switch>
    </>
  );
}

export default App;
