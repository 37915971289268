import { useState, createContext, Dispatch, SetStateAction, ReactNode } from 'react';

import type { FilterProps } from 'components/ReportAverageTicket/types';

interface Context {
  filters: FilterProps;
  setFilters: Dispatch<SetStateAction<FilterProps>>;
}

interface Props {
  children: ReactNode;
}

export const FiltersContext = createContext<Context | null>(null);

export const FilterProvider = ({ children }: Props) => {
  const [filters, setFilters] = useState<FilterProps>({});

  return <FiltersContext.Provider value={{ filters, setFilters }}>{children}</FiltersContext.Provider>;
};
