import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';

import { ReactComponent as IconDidi } from 'images/svg/brand/icons/icn-Didi.svg';
import { ReactComponent as IconPOS } from 'images/svg/brand/icons/icn-POS.svg';
import { ReactComponent as IconRappi } from 'images/svg/brand/icons/icn-Rappi.svg';
import { ReactComponent as IconUBEREATS } from 'images/svg/brand/icons/icn-UberEats.svg';

import MenuOrdering from '@experimental-components/IconsComponents/MenuOrdering';

import { providersTypes } from 'utils/menuMaker';

import useStyles from './styles';

const iconComponents = {
  [providersTypes.PARROT]: IconPOS,
  [providersTypes.ONLINE]: MenuOrdering,
  [providersTypes.UBER_EATS]: IconUBEREATS,
  [providersTypes.DIDI_FOOD]: IconDidi,
  [providersTypes.RAPPI]: IconRappi,
};

function CheckBoxChanel({ value, defaultChecked, name, ...rest }) {
  const classes = useStyles();

  const IconComponent = iconComponents[value];

  return (
    <Paper
      className={clsx(classes.checkBoxChanelPaper, { [classes.checkBoxChanelActive]: defaultChecked })}
      variant="outlined"
    >
      <FormControlLabel
        classes={{ root: classes.checkBoxLabel, label: classes.formControlLabel }}
        control={
          <Checkbox
            checkedIcon={<IconComponent height={24} width={24} />}
            defaultChecked={defaultChecked}
            icon={<IconComponent height={24} width={24} />}
            name={name}
          />
        }
        value={value}
        {...rest}
      />
    </Paper>
  );
}

CheckBoxChanel.propTypes = {
  defaultChecked: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
};

export default CheckBoxChanel;
