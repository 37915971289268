import { useContext } from 'react';
import { useQuery, useInfiniteQuery, useMutation } from 'react-query';
import dayjs from 'dayjs';

import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';

import {
  getAverageTicketSummaryRequest,
  getAverageTicketDetailRequest,
  downloadAverageTicketReportRequest,
} from 'utils/api/baas/reports/averageTicket';

import { FiltersContext } from './context';

export const useFilters = () => {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFilters must be used within a FilterProvider');
  }

  return context;
};

export const useGetAverageTicketSummary = () => {
  const { filters } = useFilters();
  const { logEvent } = useAmplitude();

  const { data, isFetching, isFetched, isError, refetch, remove } = useQuery(
    'average-ticket-summary',
    () => getAverageTicketSummaryRequest(filters),
    { enabled: false, retry: 1 },
  );

  const handleLoadSummary = () => {
    const startDateTmp = dayjs(filters.startDate);
    const endDateTmp = dayjs(filters.endDate);

    logEvent('@event.$reports.reportViewed', ['@@section.averageTicket', '@@type.summary'], {
      channel_type: filters.providers?.length ? 'custom' : 'empty',
      days_viewed: endDateTmp.diff(startDateTmp, 'day') + 1,
      order_type: filters.orderTypes?.length ? 'custom' : 'empty',
    });

    refetch();
  };

  return {
    data: data?.data,
    dataStatus: { fetched: isFetched, fetching: isFetching, error: isError },
    loadAverageTicketSummary: handleLoadSummary,
    removeAverageTicketSummary: remove,
  };
};

export const useGetAverageTicketDetail = () => {
  const { filters } = useFilters();
  const { logEvent } = useAmplitude();

  const {
    data,
    isFetched,
    isFetching,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    remove,
  } = useInfiniteQuery(
    'average-ticket-detail',
    ({ pageParam = 1 }) => getAverageTicketDetailRequest({ ...filters, page: pageParam }),
    {
      getNextPageParam: (lastPage, pages) => (lastPage.pagination.next ? pages.length + 1 : undefined),
      enabled: false,
      retry: 1,
    },
  );

  const handleLoadDetail = () => {
    const startDateTmp = dayjs(filters.startDate);
    const endDateTmp = dayjs(filters.endDate);

    logEvent('@event.$reports.reportViewed', ['@@section.averageTicket', '@@type.complete'], {
      channel_type: filters.providers?.length ? 'custom' : 'empty',
      days_viewed: endDateTmp.diff(startDateTmp, 'day') + 1,
      order_type: filters.orderTypes?.length ? 'custom' : 'empty',
    });

    refetch();
  };

  return {
    data,
    loadAverageTicketDetail: handleLoadDetail,
    paginate: fetchNextPage,
    dataStatus: { fetched: isFetched, fetching: isFetching, error: isError, isFetchingNextPage, hasNextPage },
    removeAverageTicketDetail: remove,
  };
};

export const useDownloadAverageTicket = () => {
  const { filters } = useFilters();
  const { logEvent } = useAmplitude();

  const { isLoading, mutateAsync } = useMutation(
    'average-ticket-export',
    () => downloadAverageTicketReportRequest(filters),
    {
      onSuccess: () => {
        logEvent('@event.$reports.reportExported', '@@section.averageTicket');
      },
    },
  );

  return {
    downloadReportIsFetching: isLoading,
    downloadReport: mutateAsync,
  };
};
