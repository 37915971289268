import { FormControlLabelProps } from '@mui/material';
import clsx from 'clsx';

import Box from '@mui/material/Box';

import Checkbox from '@design-system/Checkbox';

import useStyles from './styles';

interface Props extends FormControlLabelProps {
  title?: string;
  description?: string;
  disabled?: boolean;
}

function RowCheckbox(props: Omit<Props, 'control'>) {
  const { title, description, disabled: disabledProps, ...others } = props;
  const classes = useStyles({ disabled: disabledProps });

  return (
    <Box className={classes.rowCheckbox}>
      <Checkbox disabled={disabledProps} {...others} />
      <Box width="100%">
        {title && (
          <Box
            className={clsx({ [classes.textDisabledItem]: disabledProps })}
            fontSize="0.875rem"
            fontWeight="fontWeightMedium"
          >
            {title}
          </Box>
        )}
        {description && (
          <Box className={clsx({ [classes.textDisabledItem]: disabledProps })} fontSize="0.875rem">
            {description}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default RowCheckbox;
