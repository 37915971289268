/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { SortableElement, SortableHandle, SortableContainer } from 'react-sortable-hoc';
import { Controller, useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';

import InputAdornmentField from '@design-system/InputAdornmentFiled';
import Table from '@experimental-components/Table';
import DragHandleVertical from '@experimental-components/IconsComponents/DragHandleHorizontal';
import IconClose from '@experimental-components/IconsComponents/Close';
import IconButton from '@design-system/IconButton';

import PriceFormatOnlyNumbersField from 'components/PriceFormatField/PriceFormatOnlyNumbersField';

import { maxValueAllowed } from 'utils/currency';
import { regexTypes, formatTextAllowedValues } from 'utils/formatField';
import { validateUppercase } from 'utils/menuMaker';

import useStyles from './styles';

const DraggableHandle = SortableHandle(({ children }) => children);

const handleValidateAllowedValues = (target) => {
  target.value = formatTextAllowedValues(target.value, regexTypes.REGEX_PRODUCT_NAME_ALLOWED);
  return target.value;
};

const TableRowOption = SortableElement(({ defaultValue, onRemoveOption, position, register, t }) => {
  const classes = useStyles();
  const { control, errors } = useFormContext();

  return (
    <Table.Row className={clsx(classes.row, classes.optionRow)}>
      <Table.Cell width={24}>
        <DraggableHandle>
          <Box pt={1.875}>
            <DragHandleVertical color={defaultValue?.isErrorItem ? '#F04E4A' : '#000'} size={16} />
          </Box>
        </DraggableHandle>
      </Table.Cell>

      <Table.Cell width={240}>
        {defaultValue?.uuid ? (
          <Box color={defaultValue?.isErrorItem ? '#F04E4A' : '#000'} pt={1.625}>
            {defaultValue?.name}
          </Box>
        ) : (
          <InputAdornmentField
            className={classes.texField}
            defaultValue={defaultValue?.name}
            error={Boolean(errors?.modifierOptionsListField?.at(position)?.name)}
            errorMsg={errors?.modifierOptionsListField?.at(position)?.name?.message}
            fullWidth
            hideLabel
            inputProps={{ maxLength: 30 }}
            inputRef={register({
              required: true,
              minLength: { value: 3, message: t('common:errors.minLength3Field') },
              validate: {
                controlUppercase: (value) => validateUppercase(value) || t('common:errors.controlUppercase'),
              },
            })}
            name={`modifierOptionsListField.${position}.name`}
            onChange={({ target }) => handleValidateAllowedValues(target)}
          />
        )}
      </Table.Cell>

      <Table.Cell>
        <Controller
          control={control}
          defaultValue={defaultValue?.additionalPrice || 0}
          name={`modifierOptionsListField.${position}.additionalPrice`}
          render={({ onChange, name, ref, value }) => (
            <InputAdornmentField
              className={clsx(classes.texField, { [classes.inputOptionError]: defaultValue?.isErrorItem })}
              defaultValue={value}
              error={Boolean(
                errors?.modifierOptionsListField?.at(position)?.additionalPrice || defaultValue?.isErrorItem,
              )}
              fullWidth
              hideLabel
              inputComponent={PriceFormatOnlyNumbersField}
              inputProps={{ isAllowed: maxValueAllowed(9999999.99) }}
              inputRef={ref}
              name={name}
              onChange={onChange}
              size="small"
              startAdornment={
                <InputAdornment position="start">
                  <span className={clsx({ [classes.inputOptionError]: defaultValue?.isErrorItem })}>$</span>
                </InputAdornment>
              }
              variant="outlined"
            />
          )}
          rules={{ required: true }}
        />
      </Table.Cell>

      <Table.Cell>
        <Box pt={1.6}>
          <IconButton data-testid={`btnRemoveOption${position}`} onClick={() => onRemoveOption(position)} size="large">
            <IconClose color={defaultValue?.isErrorItem ? '#F04E4A' : '#000'} size={11} />
          </IconButton>
        </Box>
      </Table.Cell>
    </Table.Row>
  );
});

TableRowOption.propTypes = {
  index: PropTypes.number,
  handleRemoveOption: PropTypes.func,
};

const TableBodySortable = SortableContainer(({ onRemoveOption, options, register, ...props }) => (
  <Table.Body>
    {options.map((option, index) => (
      <TableRowOption
        key={option.id}
        defaultValue={option}
        distance={1}
        index={index}
        onRemoveOption={onRemoveOption}
        position={index}
        register={register}
        {...props}
      />
    ))}
  </Table.Body>
));

const OptionsTable = ({ isQuestionType, onRemoveOption, onSortEnd, options, t }) => {
  const classes = useStyles();
  const { register, errors } = useFormContext();

  if (isQuestionType) {
    return (
      <>
        <Table aria-label="optionsTable" className={classes.table}>
          <Table.Head>
            <Table.Row className={classes.row}>
              <Table.HeadCell align="left">{t('menuMaker:modifierGroupForm.labels.option')}</Table.HeadCell>
              <Table.HeadCell width={11} />
            </Table.Row>
          </Table.Head>

          <Table.Body>
            {options.map((option, index) => (
              <Table.Row key={option.id} className={clsx(classes.row)}>
                <Table.Cell>
                  <InputAdornmentField
                    className={classes.texField}
                    defaultValue={option?.name}
                    error={Boolean(errors?.modifierOptionsListField?.at(index)?.name)}
                    errorMsg={errors?.modifierOptionsListField?.at(index)?.name?.message}
                    fullWidth
                    hideLabel
                    inputProps={{ maxLength: 30 }}
                    inputRef={register({
                      required: true,
                      minLength: { value: 3, message: t('common:errors.minLength3Field') },
                      validate: {
                        controlUppercase: (value) => validateUppercase(value) || t('common:errors.controlUppercase'),
                      },
                    })}
                    name={`modifierOptionsListField.${index}.name`}
                    onChange={({ target }) => handleValidateAllowedValues(target)}
                  />
                </Table.Cell>

                <Table.Cell>
                  <Box pt={1.6}>
                    <IconButton
                      data-testid={`btnRemoveOption${index}`}
                      onClick={() => onRemoveOption(index)}
                      size="large"
                    >
                      <IconClose size={11} />
                    </IconButton>
                  </Box>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Divider className={classes.divider} />
      </>
    );
  }

  return (
    <>
      <Table aria-label="optionsTable" className={classes.table}>
        <Table.Head>
          <Table.Row className={classes.row}>
            <Table.HeadCell width={40} />
            <Table.HeadCell align="left" width={240}>
              {t('menuMaker:modifierGroupForm.labels.option')}
            </Table.HeadCell>
            <Table.HeadCell align="left">{t('menuMaker:modifierGroupForm.labels.price')}</Table.HeadCell>
            <Table.HeadCell />
          </Table.Row>
        </Table.Head>

        <TableBodySortable
          onRemoveOption={onRemoveOption}
          onSortEnd={onSortEnd}
          options={options}
          register={register}
          t={t}
          useDragHandle
        />
      </Table>
      <Divider className={classes.divider} />
    </>
  );
};

OptionsTable.propTypes = {
  isQuestionType: PropTypes.bool,
  onRemoveOption: PropTypes.func,
  onSortEnd: PropTypes.func,
  options: PropTypes.array,
  t: PropTypes.func,
};

export default compose(withTranslation('menuMaker'))(OptionsTable);
