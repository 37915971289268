import { memo } from 'react';
import PropTypes from 'prop-types';

import { CircularProgress } from '@mui/material';
import { compose } from 'redux';
import Box from '@mui/material/Box';
import LinksContainers from 'components/LinksContainers';
import ButtonCard from '@experimental-components/ButtonCard';
import dayjs from 'dayjs';
import { useQueryParams } from 'hooks';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import { Feature, useFeature } from 'FeatureProvider';

import { withRequiredLicense } from 'providers/root/AuthProvider';

import { useStoreSettings } from 'providers/settings/Common/hooks';

import { reportsTypes } from 'utils/reports';
import { useGetEnableGeneralFlag } from 'utils/featureFlags/reports';

import LayoutReport from './LayoutReport';

function Reports({ t }) {
  const history = useHistory();
  const theme = useTheme();
  const { queryParams } = useQueryParams();
  const { data: settingsStore, isLoading, isSuccess: settingsStoreSuccess } = useStoreSettings();

  const enableAverageTicketReport = useGetEnableGeneralFlag('ffEnableAverageTicketReport');

  const handleGoRoute = (route, query) => () => {
    if (query?.startDate && query?.endDate) {
      history.push({
        pathname: route,
        search: `?${new URLSearchParams(query).toString()}`,
      });
      return;
    }

    history.push({
      pathname: route,
    });
  };

  function createQueryParamDates({ startDate, endDate }) {
    if (!startDate || !endDate) {
      return undefined;
    }

    return {
      startDate: dayjs(decodeURI(startDate)).format('YYYY-MM-DDTHH:mm:ss'),
      endDate: dayjs(decodeURI(endDate)).format('YYYY-MM-DDTHH:mm:ss'),
    };
  }
  const hasInventory = useFeature('@module.inventory');

  return (
    <LayoutReport path="/reports">
      <Box pb={theme.typography.pxToRem(32)} pl={theme.typography.pxToRem(32)} pr={theme.typography.pxToRem(32)}>
        <LinksContainers>
          <Box color="primary.main" fontSize={theme.typography.pxToRem(20)} fontWeight={500} mb={3} pt={4}>
            {t('reports:index.title')}
          </Box>

          <LinksContainers.Row>
            <LinksContainers.Item>
              <ButtonCard onClick={handleGoRoute(`/reports/${reportsTypes.PRODUCTS_SALES}/summary`)}>
                {t('reports:navigation.tabProductsSales')}
              </ButtonCard>
            </LinksContainers.Item>

            <LinksContainers.Item>
              <ButtonCard onClick={handleGoRoute(`/reports/${reportsTypes.SALES_PER_CATEGORY}`)}>
                {t('reports:navigation.tabSalesPerCategory')}
              </ButtonCard>
            </LinksContainers.Item>

            {enableAverageTicketReport && (
              <LinksContainers.Item>
                <ButtonCard onClick={handleGoRoute(`/reports/${reportsTypes.AVERAGE_TICKET}/summary`)}>
                  {t('reports:navigation.averageTicketReport')}
                </ButtonCard>
              </LinksContainers.Item>
            )}

            <LinksContainers.Item>
              <ButtonCard onClick={handleGoRoute(`/reports/${reportsTypes.CASH_REGISTER}/list`)}>
                {t('reports:navigation.tabCashRegister')}
              </ButtonCard>
            </LinksContainers.Item>

            <LinksContainers.Item>
              <ButtonCard onClick={handleGoRoute(`/reports/${reportsTypes.ORDERS}/summary`)}>
                {t('reports:navigation.tabOrders')}
              </ButtonCard>
            </LinksContainers.Item>

            <LinksContainers.Item>
              <ButtonCard
                onClick={handleGoRoute(`/reports/${reportsTypes.PAYMENTS}/summary`, {
                  ...createQueryParamDates({
                    startDate: queryParams.startDate,
                    endDate: queryParams.endDate,
                  }),
                  status: queryParams?.status,
                })}
              >
                {t('reports:navigation.tabPayments')}
              </ButtonCard>
            </LinksContainers.Item>

            <LinksContainers.Item onClick={handleGoRoute(`/reports/${reportsTypes.SALES_PER_TIME}`)}>
              <ButtonCard>{t('reports:navigation.salesPerTime')}</ButtonCard>
            </LinksContainers.Item>

            <LinksContainers.Item>
              <ButtonCard onClick={handleGoRoute(`/reports/${reportsTypes.MODIFIERS}/modifiersByProduct`)}>
                {t('reports:navigation.modifiersReport')}
              </ButtonCard>
            </LinksContainers.Item>

            <LinksContainers.Item>
              <ButtonCard onClick={handleGoRoute(`/reports/${reportsTypes.CANCELLATIONS}/summary`)}>
                {t('reports:navigation.tabCancellations')}
              </ButtonCard>
            </LinksContainers.Item>

            <LinksContainers.Item>
              <ButtonCard onClick={handleGoRoute(`/reports/${reportsTypes.DISCOUNTS}/summary`)}>
                {t('reports:navigation.tabDiscounts')}
              </ButtonCard>
            </LinksContainers.Item>

            <LinksContainers.Item>
              <ButtonCard onClick={handleGoRoute(`/reports/${reportsTypes.CLIENT_DATA}/summary`)}>
                {t('reports:navigation.tabClientData')}
              </ButtonCard>
            </LinksContainers.Item>

            <LinksContainers.Item>
              <ButtonCard data-testid="users-tab" onClick={handleGoRoute(`/reports/${reportsTypes.USERS}`)}>
                {t('reports:navigation.reportUsers')}
              </ButtonCard>
            </LinksContainers.Item>

            <Feature name="@module.timeClock">
              <LinksContainers.Item>
                <ButtonCard onClick={handleGoRoute(`/reports/${reportsTypes.ASSISTANCE}`)}>
                  {t('reports:navigation.reportAssistance')}
                </ButtonCard>
              </LinksContainers.Item>
            </Feature>

            {hasInventory ? (
              <LinksContainers.Item>
                <ButtonCard onClick={handleGoRoute(`/reports/${reportsTypes.COSTS_AND_MARGINS}`)}>
                  {t('reports:navigation.reportCostsAndMargins')}
                </ButtonCard>
              </LinksContainers.Item>
            ) : (
              <LinksContainers.Item>
                <ButtonCard disabledAction tooltipTitle="Contrata inventarios para ver este reporte">
                  {t('reports:navigation.reportCostsAndMargins')}
                </ButtonCard>
              </LinksContainers.Item>
            )}

            <LinksContainers.Item>
              <ButtonCard onClick={handleGoRoute(`/reports/${reportsTypes.MOVEMENTS_CASH}`)}>
                {t('reports:navigation.movementsCash')}
              </ButtonCard>
            </LinksContainers.Item>

            {isLoading ? (
              <LinksContainers.Item>
                <ButtonCard>
                  <CircularProgress color="inherit" size={18} />
                </ButtonCard>
              </LinksContainers.Item>
            ) : (
              settingsStoreSuccess &&
              settingsStore?.parrotPay?.isEnabled && (
                <LinksContainers.Item data-cy="transactionsPayItem">
                  <ButtonCard
                    onClick={handleGoRoute(`/reports/${reportsTypes.TRANSACTIONS_PAY}`, {
                      ...createQueryParamDates({
                        startDate: queryParams.startDate,
                        endDate: queryParams.endDate,
                      }),
                      status: queryParams?.status,
                    })}
                  >
                    {t('reports:navigation.transactionsPay')}
                  </ButtonCard>
                </LinksContainers.Item>
              )
            )}
          </LinksContainers.Row>
        </LinksContainers>
      </Box>
    </LayoutReport>
  );
}

Reports.propTypes = {
  t: PropTypes.func,
};

export default compose(memo, withRequiredLicense(), withTranslation('reports'))(Reports);
