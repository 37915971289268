/* eslint-disable react-hooks/exhaustive-deps */
import { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify } from 'query-string';

import Box from '@mui/material/Box';

import Layout, { ContentLayout } from 'Layout';

import { withKitchenAreas } from 'providers/settings/KitchenAreas';
import { withRequiredLicense } from 'providers/root/AuthProvider';
import { withCategories } from 'providers/menuMaker/Categories';
import { withCategoryDetails } from 'providers/menuMaker/CategoryDetails';
import useBrands from 'providers/root/BrandsProvider/useBrands';
import { useGetMenusCatalog } from 'providers/menuMaker/Menus/hooks';
import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';
import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';

import useNotification from '@design-system/Notification/useNotification';

import MenuMakerTabsNavigation from 'components/MenuMakerTabsNavigation';
import CategoriesTable from 'components/CategoriesTable';
import PageError from 'components/PageError';
import HeaderForm from 'components/HeaderForm';
import CategoryDrawer from 'components/CategoryDrawer';

import useUserStore from 'hooks/useUserStore';

import { getErrorCode, errorsCodeTypes } from 'utils/parrotErrorCode';
import { getEnabledKitchenAreas } from 'utils/kitchenAreas/makeKitchenAreasFields';
import {
  drawerActions,
  drawerActionList,
  drawerEntities,
  drawerEntityList,
  getQueryParamsMenuMaker,
} from 'utils/drawerMenuMaker';

import useStyles from './styles';

export function Categories({
  categoriesError,
  categoriesFetching,
  categoriesList,
  categoriesPagination,
  categoryDetailObject,
  categoryDetailFetching,
  categoryDetailFetched,
  categoryEditError,
  categoryEditFetched,
  categoryEditFetching,
  categoryEditObject,
  createCategoryError,
  createCategoryFetched,
  createCategoryFetching,
  createCategoryObject,
  createNewCategory,
  deleteCategory,
  deleteCategoryError,
  deleteCategoryFetched,
  deleteCategoryFetching,
  duplicateCategory,
  duplicateCategoryError,
  duplicateCategoryFetched,
  duplicateCategoryFetching,
  editCategory,
  kitchenAreas,
  kitchenAreasState,
  loadCategories,
  loadCategoryDetail,
  loadKitchenAreas,
  loadProductsSortCategory,
  paginateCategory,
  paginationCategoryState,
  productsSortCategory,
  productsSortCategoryState,
  resetCategories,
  resetCategoryDetail,
  resetCreateCategory,
  resetDeleteCategory,
  resetDuplicateCategory,
  resetEditCategory,
  resetKitchenAreas,
  resetProductsSortCategory,
  t,
}) {
  const { modeDraft, isPublishMenuError } = useSidebarLayout();
  const history = useHistory();
  const location = useLocation();
  const {
    storeId,
    storeState: { selected: storeSelected },
  } = useUserStore();
  const { setNotification, closeAllSnackbar } = useNotification();
  const { brandSelected, brandsState } = useBrands();
  const [categorySearched, setCategorySearched] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedCategoryUuid, setSelectedCategoryUuid] = useState(null);
  const classes = useStyles({ openDrawer, isModeDraft: modeDraft || isPublishMenuError });
  const {
    allMenus,
    getAllMenus,
    getAllMenusFetching,
    getAllMenusFetched,
    getAllMenusError,
    resetGetAllMenus,
  } = useGetMenusCatalog({
    storeUuid: storeId,
  });
  const { logEvent } = useAmplitude();

  const { action, entity, uuid } = getQueryParamsMenuMaker(location);
  const isEdition = action === drawerActions.EDIT;
  const withoutBrand = !brandSelected?.uuid;

  useEffect(() => {
    logEvent('@event.$menuMaker.viewMenuMaker', '@@section.categories');
  }, []);

  useEffect(() => {
    loadCategories({ storeUuid: storeId, brand: brandSelected?.uuid, name: categorySearched, withoutBrand });
  }, [storeId, brandSelected]);

  useEffect(() => {
    const isValid = drawerActionList.includes(action) && drawerEntityList.includes(entity);

    setOpenDrawer(Boolean(isEdition ? isValid && uuid : isValid));
  }, [action, entity, uuid, isEdition]);

  useEffect(() => {
    if (createCategoryFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.categories', '@@type.new', '@@item.categories'], {
        itemId: createCategoryObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      loadCategories({ storeUuid: storeId, brand: brandSelected?.uuid, name: categorySearched, withoutBrand });
      setNotification(
        t('categories:createCategory.createNewCategorySuccess', { categoryName: createCategoryObject?.name }),
        { variant: 'success' },
      );
      history.replace({ search: '' });
      resetCreateCategory();
    }
  }, [createCategoryFetched]);

  useEffect(() => {
    if (createCategoryError && !getErrorCode(createCategoryError, errorsCodeTypes.MENU_CATEGORY_NAME_EXISTING)) {
      setNotification(t('categories:createCategory.createNewCategoryError'), { variant: 'error' });
      resetCreateCategory();
    }

    if (getErrorCode(createCategoryError, errorsCodeTypes.MENU_CATEGORY_NAME_EXISTING)) {
      setNotification(t('categories:createCategory.createNewCategoryDuplicateNameError'), { variant: 'warning' });
      resetCreateCategory();
    }
  }, [createCategoryError]);

  useEffect(() => {
    if (deleteCategoryFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.categories', '@@type.delete', '@@item.categories'], {
        itemId: selectedCategoryUuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      setNotification(t('categories:deleteCategory.deleteCategorySuccess'), { variant: 'success' });
      setSelectedCategoryUuid(null);
      resetDeleteCategory();
    }

    if (deleteCategoryError) {
      setNotification(t('categories:deleteCategory.deleteCategoryError'), { variant: 'error' });
      resetDeleteCategory();
    }
  }, [deleteCategoryError, deleteCategoryFetched, setNotification, t]);

  useEffect(() => {
    if (categoryEditFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.categories', '@@type.edit', '@@item.categories'], {
        itemId: categoryEditObject?.uuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      loadCategories({ storeUuid: storeId, brand: brandSelected?.uuid, name: categorySearched, withoutBrand });
      setNotification(
        t('categories:editCategory.editCategorySuccess', {
          categoryName: categoryEditObject?.name,
        }),
        { variant: 'success' },
      );
      history.replace({ search: '' });
      resetEditCategory();
    }

    if (categoryEditError && !getErrorCode(categoryEditError, errorsCodeTypes.MENU_CATEGORY_NAME_EXISTING)) {
      setNotification(t('categories:editCategory.editCategoryError'), { variant: 'error' });
      resetEditCategory();
    }

    if (getErrorCode(categoryEditError, errorsCodeTypes.MENU_CATEGORY_NAME_EXISTING)) {
      setNotification(t('categories:createCategory.createNewCategoryDuplicateNameError'), { variant: 'error' });
      resetEditCategory();
    }
  }, [categoryEditFetched, categoryEditError]);

  useEffect(() => {
    if (duplicateCategoryFetched) {
      logEvent('@event.$menuMaker.menuMaker', ['@@section.categories', '@@type.duplicate', '@@item.categories'], {
        itemId: selectedCategoryUuid,
        store: storeSelected?.uuid,
        isDraft: storeSelected?.isDraft,
      });
      loadCategories({ storeUuid: storeId, brand: brandSelected?.uuid, name: categorySearched, withoutBrand });
      setNotification(t('categories:duplicateCategory.success'), { variant: 'success' });
      setSelectedCategoryUuid(null);
      resetDuplicateCategory();
    }
    if (duplicateCategoryError) {
      setNotification(t('categories:duplicateCategory.error'), { variant: 'error' });
      resetDuplicateCategory();
    }
  }, [duplicateCategoryFetched, duplicateCategoryError]);

  useEffect(() => {
    return () => {
      resetGetAllMenus();
      resetCategories();
      resetCreateCategory();
      resetEditCategory();
      resetDuplicateCategory();
      resetDeleteCategory();
      closeAllSnackbar();
    };
  }, []);

  const handleLoadMenus = () => {
    getAllMenus();
  };

  const handleSubmitCategory = (data) => {
    if (isEdition) {
      editCategory({ storeUuid: storeId, categoryUuid: uuid, category: { ...data } });
    } else {
      createNewCategory({ ...data, store: storeId });
    }
  };

  const handleOpenNewCategory = () => {
    const query = { action: drawerActions.CREATE, entity: drawerEntities.CATEGORY };

    history.replace({ search: stringify(query) });
  };

  const handleDeleteCategory = (categoryUuid, canRemove) => {
    if (canRemove) {
      setSelectedCategoryUuid(categoryUuid);
      deleteCategory({ storeUuid: storeId, categoryUuid });
    } else {
      setNotification(t('categories:messages.canNotRemoveCategory'), {
        variant: 'warning',
      });
    }
  };

  const handleDuplicateCategory = (categoryUuid) => {
    setSelectedCategoryUuid(categoryUuid);
    duplicateCategory({ storeUuid: storeId, categoryUuid });
  };

  const handleSearchByTextCategories = () => (value) => {
    const { filterByText } = value;

    if (filterByText?.length >= 3) {
      logEvent('@event.$menuMaker.menuMakerFilters', '@@section.categories', {
        search: filterByText,
      });
      setCategorySearched(filterByText);
      loadCategories({ storeUuid: storeId, brand: brandSelected?.uuid, name: filterByText, withoutBrand });
    }

    if (!filterByText) {
      setCategorySearched(null);
      loadCategories({ storeUuid: storeId, brand: brandSelected?.uuid, name: null, withoutBrand });
    }
  };

  const handleLoadCategories = () => {
    loadCategories({ storeUuid: storeId, brand: brandSelected?.uuid, name: categorySearched, withoutBrand });
    getAllMenus();
  };

  const handleLoadCategoryDetails = () => {
    loadCategoryDetail(uuid);
    loadProductsSortCategory({ storeUuid: storeId, categoryUuid: uuid });
  };

  const handleResetCategoryDetails = () => {
    resetCategoryDetail();
    resetProductsSortCategory();
  };

  const handlePaginate = () => {
    paginateCategory({
      storeUuid: storeId,
      brand: brandSelected?.uuid,
      name: categorySearched,
      page: categoriesPagination?.page + 1,
    });
  };

  return (
    <Layout>
      <ContentLayout>
        <ContentLayout.Header className={classes.headerLayout} sticky>
          <MenuMakerTabsNavigation />
        </ContentLayout.Header>

        <ContentLayout.Container pb="0" pl="0" pr="0">
          <Box className={classes.root}>
            <HeaderForm
              addButtonLabel={t('menuMaker:buttons.category')}
              isShowFilterBrands
              onAddButtonClick={handleOpenNewCategory}
              onSearchBoxChange={handleSearchByTextCategories()}
              openDrawer={openDrawer}
              px={4}
              searchLabel={t('categories:headerActions.searchCategory')}
            />

            {(categoriesError || getAllMenusError) && (
              <PageError
                data-testid="categoriesLoadError"
                id="categoriesError"
                labelAction={t('common:buttons.retry')}
                message={t('common:errors.loaded.categories')}
                onAction={handleLoadCategories}
              />
            )}

            {!categoriesError && !getAllMenusError && (
              <CategoriesTable
                categories={categoriesList}
                categoriesPagination={categoriesPagination}
                deleteError={Boolean(deleteCategoryError)}
                deleteFetched={deleteCategoryFetched}
                deleteLoading={deleteCategoryFetching}
                duplicateFetched={duplicateCategoryFetched}
                duplicateLoading={duplicateCategoryFetching}
                isSearchFilter={categorySearched?.length > 0}
                loading={categoriesFetching || brandsState.isLoading}
                onDeleteCategory={handleDeleteCategory}
                onDuplicateCategory={handleDuplicateCategory}
                onPaginate={handlePaginate}
                openDrawer={openDrawer}
                paginationCategoryState={paginationCategoryState}
              />
            )}
          </Box>

          <CategoryDrawer
            actionState={
              isEdition
                ? { fetched: categoryEditFetched, fetching: categoryEditFetching }
                : { fetched: createCategoryFetched, fetching: createCategoryFetching }
            }
            categoryState={{
              data: categoryDetailObject,
              fetching: categoryDetailFetching,
              fetched: categoryDetailFetched,
            }}
            isEdition={isEdition}
            kitchenAreasState={{ list: getEnabledKitchenAreas(kitchenAreas), ...kitchenAreasState }}
            menusState={{ list: allMenus, fetching: getAllMenusFetching, fetched: getAllMenusFetched }}
            onLoadCategoryDetails={handleLoadCategoryDetails}
            onLoadKitchenAreas={loadKitchenAreas}
            onLoadMenus={handleLoadMenus}
            onResetCategoryDetails={handleResetCategoryDetails}
            onResetGetKitchenAreas={resetKitchenAreas}
            onResetGetMenus={resetGetAllMenus}
            onSubmit={handleSubmitCategory}
            openDrawer={openDrawer}
            productsCategoryState={{ list: productsSortCategory, ...productsSortCategoryState }}
            uuidSelected={uuid}
          />
        </ContentLayout.Container>
      </ContentLayout>
    </Layout>
  );
}

Categories.propTypes = {
  categoriesError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  categoriesFetching: PropTypes.bool,
  categoriesList: PropTypes.array,
  categoriesPagination: PropTypes.object,
  categoryDetailObject: PropTypes.object,
  categoryDetailFetching: PropTypes.bool,
  categoryDetailFetched: PropTypes.bool,
  categoryEditError: PropTypes.bool,
  categoryEditFetched: PropTypes.bool,
  categoryEditFetching: PropTypes.bool,
  categoryEditObject: PropTypes.object,
  createCategoryError: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.bool]),
  createCategoryFetched: PropTypes.bool,
  createCategoryFetching: PropTypes.bool,
  createCategoryObject: PropTypes.object,
  createNewCategory: PropTypes.func,
  deleteCategory: PropTypes.func,
  deleteCategoryError: PropTypes.bool,
  deleteCategoryFetched: PropTypes.bool,
  deleteCategoryFetching: PropTypes.bool,
  duplicateCategory: PropTypes.func,
  duplicateCategoryError: PropTypes.bool,
  duplicateCategoryFetched: PropTypes.bool,
  duplicateCategoryFetching: PropTypes.bool,
  editCategory: PropTypes.func,
  kitchenAreas: PropTypes.array,
  kitchenAreasState: PropTypes.object,
  loadCategories: PropTypes.func,
  loadCategoryDetail: PropTypes.func,
  loadKitchenAreas: PropTypes.func,
  loadProductsSortCategory: PropTypes.func,
  paginateCategory: PropTypes.func,
  paginationCategoryState: PropTypes.object,
  productsSortCategory: PropTypes.array,
  productsSortCategoryState: PropTypes.object,
  resetCategories: PropTypes.func,
  resetCategoryDetail: PropTypes.func,
  resetCreateCategory: PropTypes.func,
  resetDeleteCategory: PropTypes.func,
  resetDuplicateCategory: PropTypes.func,
  resetEditCategory: PropTypes.func,
  resetKitchenAreas: PropTypes.func,
  resetProductsSortCategory: PropTypes.func,
  t: PropTypes.func,
};

export default compose(
  memo,
  withRequiredLicense(),
  withCategories,
  withCategoryDetails,
  withKitchenAreas,
  withTranslation('categories'),
)(Categories);
