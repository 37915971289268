import { useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';

import Box from '@mui/material/Box';

import { useGetReportSalesPerTimeSummary } from 'containers/ReportSalesPerTime/hooks';
import { DataResponseChart } from 'containers/ReportSalesPerTime/types';

import TooltipChart from 'components/ReportsResume/TooltipChart';

import { getDataGraphPie, kFormatter, themeChartBars } from 'utils/reports/resume';

import { ComputedDatumBar } from './types';

interface Props {
  dataChart?: DataResponseChart;
}

const ChartBarPerTime = ({ dataChart }: Props) => {
  const { data } = useGetReportSalesPerTimeSummary();

  const dataTransformed = useMemo(() => {
    return getDataGraphPie(dataChart?.layers);
  }, [data]);

  function renderTooltip(dataE: ComputedDatumBar) {
    const tooltip = dataE?.data?.tooltip;
    return (
      <TooltipChart>
        {(tooltip?.tickValue || dataE?.data?.label) && <span>{tooltip?.tickValue ?? dataE?.data?.label}: </span>}
        {`${tooltip?.symbol}${tooltip?.value}`}
      </TooltipChart>
    );
  }

  return (
    <Box alignItems="center" display="flex" height={380} justifyContent="center">
      <ResponsiveBar
        axisBottom={{
          format: (v) => kFormatter(v),
          tickValues: 5,
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 0,
        }}
        axisLeft={{
          format: () => '',
          tickSize: 0,
          tickPadding: 0,
          tickRotation: 0,
          legendOffset: 0,
        }}
        colorBy="indexValue"
        colors={dataTransformed.map((item) => item?.color)}
        data={dataTransformed}
        enableGridX
        enableGridY={false}
        enableLabel={false}
        groupMode="grouped"
        indexBy="id"
        indexScale={{ type: 'band', round: true }}
        innerPadding={1.5}
        keys={['value']}
        labelSkipHeight={12}
        labelSkipWidth={12}
        layout="horizontal"
        margin={{ top: 30, right: 10, bottom: 35, left: 10 }}
        padding={0.3}
        theme={themeChartBars}
        tooltip={renderTooltip}
        // valueScale={{ type: 'linear' }}
      />
    </Box>
  );
};
export default ChartBarPerTime;
