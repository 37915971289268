import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
  const getMarginTop = ({ fullDrawer, isModeDraft, isPathSubscription }) => {
    if (fullDrawer) {
      return 'auto';
    }
    if (isPathSubscription && !fullDrawer) {
      return 112;
    }
    return theme.typography.pxToRem(isModeDraft ? 168 : 112);
  };
  return {
    rootDrawer: {
      zIndex: 1300,
    },
    drawerPaper: {
      marginTop: ({ isModeDraft, fullDrawer }) =>
        fullDrawer ? 'auto' : theme.typography.pxToRem(isModeDraft ? 193 : 137),
      width: theme.typography.pxToRem(496.8),
      [theme.breakpoints.down('sm')]: {
        marginTop: ({ isModeDraft, fullDrawer }) =>
          fullDrawer ? 'auto' : theme.typography.pxToRem(isModeDraft ? 193 : 137),
        width: theme.typography.pxToRem(496.8),
        [theme.breakpoints.down('xs')]: {
          marginTop: (props) => getMarginTop(props),
          width: '100%',
        },
      },
    },
    drawerContainer: ({ isActive, isModeDraft, fullDrawer }) => ({
      display: `${isActive ? 'flex' : 'none'}`,
      height: fullDrawer ? 'auto' : `calc(100vh - ${theme.typography.pxToRem(isModeDraft ? 179.2 : 123.2)})`,
      overflow: 'auto',
      [theme.breakpoints.down('sm')]: {
        height: fullDrawer ? 'auto' : `calc(100vh - ${theme.typography.pxToRem(isModeDraft ? 168 : 112)})`,
      },
    }),
    drawerHeader: {
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'space-between',
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      background: theme.palette.background.default,
      position: 'sticky',
      top: '0',
      zIndex: '1350',
    },
    drawerHeaderButton: {
      [theme.breakpoints.down('xs')]: {
        '& > button': {
          width: '100%',
          marginBottom: 24,
        },
        width: '100%',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'center',
        marginTop: 0,
        paddingTop: '1.5rem',
        paddingInline: 24,
        position: 'fixed',
        bottom: 0,
        left: 0,
      },
    },
    drawerContent: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(5),
      paddingInline: theme.spacing(3),
    },
  };
});

export default useStyles;
