import type { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import { TooltipStyled } from 'components/ReportSales/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: ({ disabledAction, isDisbanded }: { disabledAction: boolean; isDisbanded: boolean }) =>
      disabledAction || isDisbanded ? `1px solid ${theme.palette.darkGrey[5]}` : `1px solid #C8C7CF`,
    borderRadius: theme.typography.pxToRem(8),
    boxShadow: '0px 8px 40px rgba(196, 208, 237, 0.15)',
    '&:hover': {
      cursor: 'pointer',
      border: '1px solid black',
    },
    '&:active': {
      transform: 'scale(.9)',
    },
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 150,
    minWidth: 150,
    padding: 12,
    width: '100%',
    height: '100%',
    maxHeight: 80,
    minHeight: 80,
    fontFamily: 'Roboto',
  },
}));

interface Props extends ButtonBaseProps {
  children: React.ReactNode;
  isDisbanded?: boolean;
  disabledAction?: boolean;
  onClick: () => void;
  onClickHelper?: () => void;
  isLoading?: boolean;
  tooltipTitle?: React.ReactNode;
}

function ButtonCard({
  children,
  isDisbanded = false,
  disabledAction = false,
  onClick,
  onClickHelper,
  isLoading = false,
  tooltipTitle,
  ...others
}: Props) {
  const theme = useTheme();
  const classes = useStyles({ disabledAction, isDisbanded });

  function handleClick() {
    if (onClick && !disabledAction) onClick();
    if (disabledAction && onClickHelper) onClickHelper();
  }

  if (isLoading) {
    return <Skeleton height={104} variant="rectangular" width={211} />;
  }

  if (!isLoading && tooltipTitle) {
    return (
      <TooltipStyled title={tooltipTitle}>
        <ButtonBase
          classes={{ root: classes.root }}
          disabled={isDisbanded}
          disableRipple
          onClick={handleClick}
          {...others}
        >
          <Box
            color={disabledAction || isDisbanded ? theme.palette.darkGrey[15] : theme.palette.text.secondary}
            fontSize={theme.typography.pxToRem(16)}
            fontWeight={400}
            textAlign="center"
          >
            {children}
          </Box>
        </ButtonBase>
      </TooltipStyled>
    );
  }
  return (
    <ButtonBase classes={{ root: classes.root }} disabled={isDisbanded} disableRipple onClick={handleClick} {...others}>
      <Box
        color={disabledAction || isDisbanded ? theme.palette.darkGrey[15] : theme.palette.text.secondary}
        fontSize={theme.typography.pxToRem(16)}
        fontWeight={400}
        textAlign="center"
      >
        {children}
      </Box>
    </ButtonBase>
  );
}

export default ButtonCard;
