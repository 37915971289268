import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Checkbox from '@design-system/Checkbox';
import Select from '@design-system/Select';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import useBrands from 'providers/root/BrandsProvider/useBrands';

import useStyles from './styles';
import { menuProps } from './utils';

function SelectBrands({ onSelectedBrands }) {
  const classes = useStyles();
  const { t } = useTranslation('menuMaker');
  const { brands, getBrands } = useBrands();
  const [brandsSelected, setBrandsSelected] = useState([]);
  const [withOutBrandSelected, setWithOutBrandSelected] = useState(true);

  const stateSelectedAllBrands = (() => {
    return brandsSelected.length === brands.length;
  })();

  useEffect(() => {
    setTimeout(() => {
      getBrands();
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBrandsSelected([...brands]?.map((brand) => brand?.uuid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands]);

  function handleRenderNamesBrands(brandsByUuid) {
    let renderSelected = t('menuMaker:headerForm.placeholderBrands');
    if (brandsByUuid?.length > 0) {
      const brandsNames = [...brands].filter((brand) => {
        return brandsByUuid.includes(brand?.uuid);
      });
      renderSelected = brandsNames?.map((brand) => brand?.name).join(', ');
    }
    if (stateSelectedAllBrands) renderSelected = t('menuMaker:headerForm.filterProducts.allBrands');

    if (withOutBrandSelected) {
      renderSelected =
        brandsByUuid?.length > 0
          ? `${renderSelected}, ${t('menuMaker:headerForm.filterProducts.withoutBrand')}`
          : t('menuMaker:headerForm.filterProducts.withoutBrand');
    }

    return <span className={classes.contentSelected}>{renderSelected}</span>;
  }

  const handleSelectedBrands = (value) => {
    let newState = [...brandsSelected];
    if (newState.includes(value)) {
      newState = [...newState].filter((item) => item !== value);
    } else {
      newState.push(value);
    }
    setBrandsSelected(newState);
    onSelectedBrands(newState, withOutBrandSelected);
  };

  const handleSelectAllBrands = (isAll) => {
    setBrandsSelected([]);
    const brandsSelectedAll = [];
    if (!isAll) {
      brands.forEach((brand) => {
        brandsSelectedAll.push(brand?.uuid);
      });
      setBrandsSelected(brandsSelectedAll);
    }

    onSelectedBrands(brandsSelectedAll, withOutBrandSelected);
  };

  const handleSelectWithOutBrand = () => {
    const tmpState = !withOutBrandSelected;
    setWithOutBrandSelected(tmpState);
    onSelectedBrands([...brandsSelected], tmpState);
  };

  return (
    <Select
      data-testid="filterByBrandsProducts"
      displayEmpty
      fullWidth
      hideLabelHelperText
      id="filterByBrandsProducts"
      label={t('menuMaker:headerForm.brands')}
      MenuProps={{ ...menuProps }}
      multiple
      name="brandsFiltersField"
      renderValue={(selected) => handleRenderNamesBrands(selected)}
      value={brandsSelected}
    >
      <MenuItem key="withoutBrand" data-testid="selectedWithoutBrands" onClick={handleSelectWithOutBrand}>
        <Checkbox checked={withOutBrandSelected} />
        <ListItemText primary={t('menuMaker:headerForm.filterProducts.withoutBrand')} />
      </MenuItem>
      <MenuItem
        key="allBrands"
        data-testid="selectedAllBrands"
        onClick={() => handleSelectAllBrands(stateSelectedAllBrands)}
      >
        <Checkbox checked={stateSelectedAllBrands} />
        <ListItemText primary={t('menuMaker:headerForm.filterProducts.allBrands')} />
      </MenuItem>
      <Divider />
      {brands?.map((brand) => (
        <MenuItem
          key={brand.slug}
          data-testid={`filter-brand-${brand.uuid}`}
          onClick={() => handleSelectedBrands(brand.uuid)}
          value={brand.uuid}
        >
          <Checkbox checked={brandsSelected.includes(brand.uuid)} />
          <ListItemText primary={brand.name} />
        </MenuItem>
      ))}
    </Select>
  );
}

SelectBrands.propTypes = {
  onSelectedBrands: PropTypes.func,
};

export default SelectBrands;
