import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'clsx';
import DateInput from 'react-date-picker/dist/DateInput';
import Box from '@mui/material/Box';
import Fit from 'react-fit';
import dayjs, { Dayjs } from 'dayjs';
import { InputLabel, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import minMax from 'dayjs/plugin/minMax';
import type { Theme } from '@mui/material/styles';

import Calendar from '@experimental-components/MaterialPickersCalendar';

import { CalendarIcon } from './components/CalendarIcon';
import MenuItem from './components/MenuItem';

dayjs.extend(minMax);
const RANGE_DATE_PRESET = 'dateRange';

export const InputLabelRoot = withStyles((theme: Theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(1),
  },

  shrink: {
    transform: 'translate(0, 1.5px) scale(1)',
    transformOrigin: 'top left',
  },

  disabled: {
    color: theme.palette.darkGrey[50],
  },
}))(InputLabel);

interface Props {
  label: string;
  isFullWidth?: boolean;
  disabled?: boolean;
  onFocus?: () => void;
  className?: string;
  autoFocus?: boolean;
  onChange?: (data: Dayjs[]) => void;
  calendarAriaLabel?: string;
  ranges: {
    [x: string]: Dayjs[];
  };
  minDate?: Date;
  value: Dayjs[];
  maxNumDays?: number;
}

export default function DateRangePicker({
  label,
  isFullWidth,
  disabled,
  onFocus,
  className,
  autoFocus,
  onChange,
  calendarAriaLabel,
  minDate,
  ranges,
  value,
  maxNumDays,
}: Props) {
  const { t } = useTranslation();
  const menuRef = useRef<HTMLDivElement | null>(null);
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const [presetSelected, setPresetSelected] = useState<string | null>(null);
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [isPresetsOpen, setIsPresetsOpen] = useState<boolean>(false);
  const [onChangeCalledFrom, setOnChangeCalledFrom] = useState<'calendar' | 'preset' | null>(null);
  const [valueFrom, valueTo] = [...value];

  const handleOpenPresets = () => {
    if (disabled) return;
    if (presetSelected === RANGE_DATE_PRESET) setIsCalendarOpen(true);
    setIsPresetsOpen(true);
  };

  const handleCloseAll = () => {
    setIsPresetsOpen(false);
    setIsCalendarOpen(false);
  };

  const handleOnChange = (values: Dayjs[]) => {
    if (onChange) onChange(values);
  };

  function Inputs() {
    const today = dayjs();
    const realValueFrom = valueFrom ? dayjs.min(valueFrom, today) : null;
    const realValueTo = valueTo ? dayjs.min(valueTo, today) : null;

    const onChangeFrom = (newValueFrom: Dayjs) => {
      handleOnChange([newValueFrom, valueTo]);
    };
    const onChangeTo = (newValueTo: Dayjs) => {
      handleOnChange([valueFrom, newValueTo]);
    };

    return (
      <Box className="react-daterange-picker-v2__wrapper" onClick={handleOpenPresets}>
        <div className="inputsWrapper">
          <DateInput
            autoFocus={autoFocus}
            className="react-daterange-picker-v2__inputGroup"
            disabled={disabled}
            isCalendarOpen={isCalendarOpen}
            name="daterange_from"
            onChange={onChangeFrom}
            returnValue="start"
            value={realValueFrom}
          />
          <span className="react-daterange-picker-v2__range-divider">-</span>
          <DateInput
            className="react-daterange-picker-v2__inputGroup"
            disabled={disabled}
            isCalendarOpen={isCalendarOpen}
            name="daterange_to"
            onChange={onChangeTo}
            returnValue="end"
            value={realValueTo}
          />
        </div>

        <div>
          <button
            aria-label={calendarAriaLabel}
            className="react-daterange-picker-v2__calendar-button react-daterange-picker-v2__button"
            data-testid="butonDataPickerMain"
            disabled={disabled}
            onFocus={(e) => e.stopPropagation()}
            type="button"
          >
            <CalendarIcon />
          </button>
        </div>
      </Box>
    );
  }

  function SelectPresets() {
    const rangesList = Object.entries(ranges);

    function onChangeRangeItem(labelKey: string, values: Dayjs[]) {
      setPresetSelected(labelKey);
      setOnChangeCalledFrom('preset');
      handleOnChange(values);
      setIsCalendarOpen(false);
      setIsPresetsOpen(false);
    }

    const openCalendarHandler = () => {
      setPresetSelected(RANGE_DATE_PRESET);
      setIsCalendarOpen(true);
    };

    const handleClick = useCallback((event: MouseEvent) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const outsideMenuClick = !menuRef.current?.contains(event.target as any);

      if (outsideMenuClick) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const outsideCalendarClick = !calendarRef.current?.contains(event.target as any);

        if (isCalendarOpen) {
          if (outsideCalendarClick) {
            return handleCloseAll();
          }
          return;
        }

        handleCloseAll();
      }
    }, []);

    const handleEscapeKeyPressed = useCallback((event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleCloseAll();
      }
    }, []);

    useEffect(() => {
      window.addEventListener('mousedown', handleClick);
      window.addEventListener('keydown', handleEscapeKeyPressed, false);

      return () => {
        window.removeEventListener('mousedown', handleClick);
        window.removeEventListener('keydown', handleEscapeKeyPressed, false);
      };
    }, []);

    if (isPresetsOpen) {
      return (
        <Fit>
          <Box ref={menuRef} maxWidth={189} mt={2} width="100%" zIndex={1600}>
            <Box
              bgcolor="#fff"
              border="1px solid #F2F2F4"
              borderRadius="4px"
              boxShadow="0px 8px 40px rgb(133 133 133 / 20%)"
              className="modal"
              component="ul"
              id="modal-dialog"
              m={0}
              p="0px"
              style={{ listStyle: 'none' }}
            >
              {rangesList?.map(({ 0: labelKey, 1: values }) => (
                <MenuItem
                  key={labelKey}
                  isActive={presetSelected === labelKey}
                  onClick={() => onChangeRangeItem(labelKey, [values[0], values[1]])}
                >
                  <ListItemText primary={labelKey} />
                </MenuItem>
              ))}
              <MenuItem isActive={presetSelected === RANGE_DATE_PRESET} onClick={openCalendarHandler}>
                <ListItemText primary={t('common:forms.rangeDate')} />
              </MenuItem>
            </Box>
          </Box>
        </Fit>
      );
    }

    return null;
  }

  function CalendarFit() {
    const valueDate = (() => {
      if (onChangeCalledFrom === 'calendar' && value) {
        return value;
      }
      return null;
    })();

    function onChangeCalendar(values: Dayjs[]) {
      setOnChangeCalledFrom('calendar');
      handleOnChange(values);
      handleCloseAll();
    }

    if (isCalendarOpen) {
      return (
        <Fit>
          <div ref={calendarRef} className="react-daterange-picker-v2__calendar react-daterange-picker-v2__presets">
            <Calendar
              maxDate={new Date()}
              maxNumDays={maxNumDays}
              minDate={minDate}
              onChange={onChangeCalendar}
              presets
              selectRange
              value={valueDate}
            />
          </div>
        </Fit>
      );
    }

    return null;
  }

  return (
    <>
      <InputLabelRoot shrink>{label}</InputLabelRoot>
      <div
        className={classNames(
          'react-daterange-picker-v2',
          isFullWidth ? 'react-daterange-picker-v2__fullWidth' : '',
          disabled ? 'react-daterange-picker-v2__disabled' : '',
          className,
        )}
        onFocus={onFocus}
      >
        <Inputs />
        <SelectPresets />
        <CalendarFit />
      </div>
    </>
  );
}
