import { useState } from 'react';
import PropTypes from 'prop-types';

import { useDebounce } from '@open-react-hooks/use-debounce';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';

import InputAdornmentFiled from '@design-system/InputAdornmentFiled';

import ButtonPlus from '@experimental-components/ButtonPlus';

import MenuBrands from 'components/MenuBrands';
import useIconParrot from 'hooks/useIconParrot';

import SelectModifierType from './SelectModifierType';
import SelectSearchTypeModifier from './SelectSearchTypeModifier';

import useStyles from './styles';

function HeaderForm({
  addButtonLabel,
  isShowFilterBrands,
  isShowModifierTypeFilter,
  isShowSearchTypeModifierFilter,
  onAddButtonClick,
  onSearchBoxChange,
  onModifierTypeChange,
  onModifierSeacrhTypeChange,
  searchLabel,
  openDrawer,
  ...others
}) {
  const filters = Number(isShowModifierTypeFilter ?? 0) + Number(isShowSearchTypeModifierFilter ?? 0);
  const classes = useStyles({ openDrawer, filters });
  const { IconSearch } = useIconParrot({ icon: 'search' });
  const { debounce } = useDebounce({ delay: 300 });

  const [filtersLocals, setFiltersLocals] = useState(isShowSearchTypeModifierFilter ? { searchType: 'GROUP' } : null);

  const handleChange = (event) => {
    const { value } = event.target;

    if (onSearchBoxChange) {
      onSearchBoxChange({ ...filtersLocals, filterByText: value });
      setFiltersLocals({ ...filtersLocals, filterByText: value });
    }
  };

  const handleChangeModifierType = (value) => {
    if (onModifierTypeChange) {
      onModifierTypeChange({ ...filtersLocals, filterByType: value });
      setFiltersLocals({ ...filtersLocals, filterByType: value });
    }
  };

  const handleChangeSearchTypeModifiers = ({ target: { value } }) => {
    onModifierSeacrhTypeChange({ ...filtersLocals, searchType: value });
    setFiltersLocals({ ...filtersLocals, searchType: value });
  };

  return (
    <Box className={classes.root} {...others}>
      {isShowSearchTypeModifierFilter && (
        <SelectSearchTypeModifier onSelectSearchType={handleChangeSearchTypeModifiers} />
      )}

      <InputAdornmentFiled
        fullWidth
        hideLabelHelperTex
        id="header-form-search-box"
        onChange={debounce(handleChange)}
        placeholder={searchLabel}
        startAdornment={
          <InputAdornment position="start">
            <IconSearch />
          </InputAdornment>
        }
      />

      {isShowModifierTypeFilter && <SelectModifierType onSelectModifierType={handleChangeModifierType} />}

      <Box className={classes.actions}>
        {onAddButtonClick && (
          <ButtonPlus className={classes.middlePrimaryButton} data-testid="btnAddNewElement" onClick={onAddButtonClick}>
            {!openDrawer ? addButtonLabel : ''}
          </ButtonPlus>
        )}

        {isShowFilterBrands && <MenuBrands />}
      </Box>
    </Box>
  );
}

HeaderForm.propTypes = {
  addButtonLabel: PropTypes.string,
  isShowFilterBrands: PropTypes.bool,
  onAddButtonClick: PropTypes.func,
  onSearchBoxChange: PropTypes.func,
  onModifierTypeChange: PropTypes.func,
  searchLabel: PropTypes.string,
  openDrawer: PropTypes.bool,
  isShowSearchTypeModifierFilter: PropTypes.bool,
  onModifierSeacrhTypeChange: PropTypes.func,
};

export default HeaderForm;
