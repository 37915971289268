import { Trans, useTranslation } from 'react-i18next';
import { Select } from '@design-system';
import { Box, Divider, Grid, MenuItem } from '@mui/material';
import type { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import DialogLayout from 'components/DialogLayout';
import DialogButtons from 'components/Inventories/Stock/SupplyModal/Buttons';
import { ProductType } from 'containers/SettingsTypeOfProducts/types';

const useStyles = makeStyles((theme: Theme) => ({
  contentModal: {
    padding: '40px !important',
  },
  subtitle: {
    color: theme.palette.text.primary,
    fontSize: 18,
    fontWeight: 500,
    marginBottom: '16px',
    maxWidth: '400px',
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: 24,
    fontWeight: 500,
    textAlign: 'center',
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: 16,
    lineHeight: '24px',
    textAlign: 'center',
    marginBottom: '1rem',
    '& strong': {
      fontWeight: 500,
    },
    whiteSpace: 'break-spaces',
  },
  hideItem: {
    visibility: 'hidden',
    height: 0,
    padding: 0,
  },
}));

interface Props {
  isOpen: boolean;
  isDisabled?: boolean;
  modalTitle: string;
  onCancel: () => void;
  onSubmit: () => void;
  productNumber: number;
  typeOfProduct: string;
  handleTypeOfProduct: (value: string) => void;
  typesOfProducts: Array<ProductType>;
  isLoading: boolean;
}

function TypeOfProductModal({
  isOpen,
  isDisabled = false,
  modalTitle,
  onCancel,
  onSubmit,
  productNumber,
  typeOfProduct,
  handleTypeOfProduct,
  typesOfProducts,
  isLoading,
}: Props) {
  const styles = useStyles();
  const { t } = useTranslation('menuMaker');

  return (
    <DialogLayout open={isOpen}>
      <DialogLayout.Content classes={styles.contentModal}>
        <Box display="flex" fontSize="16px" justifyContent="space-between">
          <Box>
            <Box className={styles.title} mb="8px">
              {modalTitle}
            </Box>
            <Box className={styles.description}>
              <Trans
                i18nKey={
                  productNumber > 1
                    ? 'menuMaker:typeOfProduct.modal.description1a'
                    : 'menuMaker:typeOfProduct.modal.description1b'
                }
                values={{ productNumber }}
              />
            </Box>
            <Box className={styles.description}>{t('menuMaker:typeOfProduct.modal.description2')}</Box>
          </Box>
        </Box>
        <Divider light />
        {!isLoading && typesOfProducts && typesOfProducts.length > 0 && (
          <Grid container justifyContent="center" style={{ marginTop: '2rem' }}>
            <Grid item xs={6}>
              <Select
                displayEmpty
                fullWidth
                label={t('menuMaker:typeOfProduct.modal.selectLabel')}
                onChange={(e) => handleTypeOfProduct(e.target.value as string)}
                value={typeOfProduct}
              >
                <MenuItem value="">{t('menuMaker:typeOfProduct.modal.selectPlaceholder')}</MenuItem>
                {typesOfProducts.map((type) => (
                  <MenuItem key={type.uuid} value={type.uuid}>
                    {t(type.name)}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        )}
      </DialogLayout.Content>
      <DialogLayout.Actions>
        <DialogButtons
          isDisabled={isDisabled}
          onCancel={() => onCancel()}
          onSubmit={onSubmit}
          onSubmitLabel="Confirmar"
        />
      </DialogLayout.Actions>
    </DialogLayout>
  );
}

export default TypeOfProductModal;
