import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(20),
  },

  subtitles: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
  },

  formMenus: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(3),
  },

  checkboxPaper: {
    display: 'flex',
    height: theme.typography.pxToRem(50),
    marginBottom: theme.spacing(2),
  },

  checkboxLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: 0,
    paddingBlock: theme.typography.pxToRem(5),
    paddingInline: theme.typography.pxToRem(14),
    textAlign: 'center',
    wordBreak: 'break-word',
    '&>span:first-child': {
      display: 'none',
    },
    '&>span': {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: '1.2',
    },
  },

  checkboxActive: {
    borderColor: theme.palette.error.main,
  },

  label: {
    color: theme.palette.darkGrey.main,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: theme.typography.pxToRem(22),
  },

  dividerItem: {
    border: 'none',
    height: '1px',
    background: theme.palette.darkGrey[5],
    margin: 0,
  },

  contianerProducts: {
    border: `1px solid ${theme.palette.darkGrey[5]}`,
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    width: 'fit-content',
    maxWidth: '100%',
    minWidth: '70%',
  },

  grayBox: {
    fontSize: theme.typography.pxToRem(14),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.darkGrey[2],
    borderRadius: theme.typography.pxToRem(4),
    color: theme.palette.darkGrey.main,
  },

  disableSection: {
    display: 'none',
  },
}));

export default useStyles;
