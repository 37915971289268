/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { withModifierGroups } from 'providers/menuMaker/ModifierGroups';

import ButtonPlus from '@experimental-components/ButtonPlus';

import { modifierTypes } from 'utils/modifierGroups';
import { handleFormatValidateItems, validateDuplicateSkuItems } from 'utils/menuMaker';

import OptionsTable from './OptionsTable';
import OptionSelect from './OptionSelect';
import useStyles from './styles';

function ModifierOptions({ fieldArrayMethods, modifierType, options, t }) {
  const classes = useStyles();
  const isCustomizableType = modifierType === modifierTypes.CUSTOMIZABLE;
  const { append, move, remove } = fieldArrayMethods;

  const handleChangeOptions = (value) => {
    append(value);
  };

  const handleAddOption = () => {
    append({ name: '', additionalPrice: 0 });
  };

  const handleSortOptions = ({ oldIndex, newIndex }) => {
    move(oldIndex, newIndex);
  };

  return (
    <Grid container direction="column" spacing={3}>
      {isCustomizableType && (
        <Grid item>
          <OptionSelect onChange={handleChangeOptions} selectedOptions={options} />
        </Grid>
      )}

      <Grid item>
        <OptionsTable
          isQuestionType={modifierType === modifierTypes.QUESTION}
          onRemoveOption={remove}
          onSortEnd={handleSortOptions}
          options={handleFormatValidateItems(options)}
        />
        {validateDuplicateSkuItems(options).length > 0 && (
          <Box className={classes.errorDuplicateSku}>
            {t('menuMaker:skuLabels.modifierGroup.errorDuplicateProductsOptionSku')}
          </Box>
        )}
      </Grid>

      <Grid item>
        <ButtonPlus data-testid="btnAddOption" onClick={handleAddOption}>
          {t(`menuMaker:buttons.${isCustomizableType ? 'newProduct' : 'newOption'}`)}
        </ButtonPlus>
      </Grid>
    </Grid>
  );
}

ModifierOptions.propTypes = {
  fieldArrayMethods: PropTypes.object,
  modifierType: PropTypes.string,
  options: PropTypes.array,
  t: PropTypes.func,
};

export default compose(withModifierGroups, withTranslation('menuMaker'), memo)(ModifierOptions);
