import { Box, Typography } from '@mui/material';

import Table from '@experimental-components/Table';

import StripedSkeleton from './StripedSkeleton';
import StripedRow from './StripedRow';
import AccordionRow from './AccordionRow';
import type { StripedTableProps } from './types';
import useStyles from './styles';

const StripedTable = ({ title, loading, loadingRows = 5, children }: StripedTableProps) => {
  const classes = useStyles();

  return (
    <Box px={4}>
      {title && <Typography className={classes.title}>{title}</Typography>}

      <Box className={classes.wrapperSummary}>
        <Table>{loading ? <StripedSkeleton rows={loadingRows} /> : <Table.Body>{children}</Table.Body>}</Table>
      </Box>
    </Box>
  );
};

StripedTable.Row = StripedRow;
StripedTable.AccordionRow = AccordionRow;

export default StripedTable;
