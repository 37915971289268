import ListMui from '@mui/material/List';

import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  paper: {
    width: '100%',
    minWidth: 200,
  },

  listbox: {
    listStyle: 'none',
    margin: 0,
    padding: '16px 0',
    maxHeight: '40vh',
    overflow: 'auto',
  },
}));

interface Props {
  anchorEl: HTMLElement | null;
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
  customWidth?: string;
}

function Menu({ anchorEl, children, onClose, open, customWidth }: Props) {
  const classes = useStyles();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
      open={open && Boolean(anchorEl)}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Paper className={classes.paper} style={{ minWidth: customWidth || 200 }}>
        <ListMui component="nav">{children}</ListMui>
      </Paper>
    </Popover>
  );
}

export default Menu;
