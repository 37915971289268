import { useState, createContext, ReactNode, Dispatch, SetStateAction, useEffect } from 'react';

import dayjs from 'dayjs';
import { formatTypesDates } from 'utils/times';

import { useQueryParams } from 'hooks';

import { Filters } from './types';

interface FilterContextType {
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
}
export const FiltersContext = createContext<FilterContextType | null>(null);

interface Props {
  children: ReactNode;
}

export function FiltersProvider({ children }: Props) {
  const initialFilters: Filters = {
    startDate: dayjs().format(formatTypesDates.SEND_FORM),
    endDate: dayjs().format(formatTypesDates.SEND_FORM),
  };

  const { queryParams, setManyQueryParams } = useQueryParams<Filters>();
  const queryParamsTransformed: Filters = {
    startDate: queryParams.startDate
      ? dayjs(queryParams.startDate).format(formatTypesDates.SEND_FORM)
      : dayjs().format(formatTypesDates.SEND_FORM),
    endDate: queryParams.endDate
      ? dayjs(queryParams.endDate).format(formatTypesDates.SEND_FORM)
      : dayjs().format(formatTypesDates.SEND_FORM),
  };
  const defaultFilters = Object.assign(initialFilters, queryParamsTransformed);

  const [filters, setFilters] = useState<Filters>(defaultFilters);

  useEffect(() => {
    setManyQueryParams({
      startDate: filters.startDate,
      endDate: filters.endDate,
    });
  }, [filters]);

  return <FiltersContext.Provider value={{ filters, setFilters }}>{children}</FiltersContext.Provider>;
}
