/* eslint-disable react/prop-types */
import DialogActions from '@mui/material/DialogActions';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  dialogActionsRoot: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 48,
    paddingTop: 0,

    '& > :not(:first-child)': {
      marginLeft: theme.spacing(5),
    },
  },
}));

function DialogLayoutActions({ children }) {
  const classes = useStyles();

  return <DialogActions classes={{ root: classes.dialogActionsRoot }}>{children}</DialogActions>;
}

export default DialogLayoutActions;
