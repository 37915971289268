import clsx from 'clsx';

import useStyles, { RowStyled } from './styles';
import { RowProps } from './types';

const StripedRow = ({ bold = false, evenStyle = false, children, ...props }: RowProps) => {
  const classes = useStyles();
  return (
    <RowStyled className={clsx({ [classes.boldRow]: bold }, evenStyle ? classes.evenRow : classes.oddRow)} {...props}>
      {children}
    </RowStyled>
  );
};

export default StripedRow;
