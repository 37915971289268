import PropTypes from 'prop-types';

import Box, { BoxProps } from '@mui/material/Box';

import Title from './Title';
import Row from './Row';
import Item from './Item';

interface Props extends BoxProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  css?: any;
  children: React.ReactNode;
}

function LinksContainers({ css, children, ...others }: Props) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        '& > :not(:first-child)': {},
        ...css,
      }}
      {...others}
    >
      {children}
    </Box>
  );
}

LinksContainers.Title = Title;
LinksContainers.Row = Row;
LinksContainers.Item = Item;

LinksContainers.propTypes = {
  children: PropTypes.node,
  css: PropTypes.object,
};

export default LinksContainers;
