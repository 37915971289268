import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

function TabPanel({ children, value, index, ...other }) {
  return (
    <Box hidden={value !== index} role="tabpanel" {...other}>
      {children}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;
