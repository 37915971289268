import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: 500,
    lineHeight: '24px',
    marginBottom: theme.spacing(2),
  },
  description: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.darkGrey.main,
  },
}));

export default useStyles;
