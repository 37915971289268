import React from 'react';
import clsx from 'clsx';
import type { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';

import FormControl from '@mui/material/FormControl';
import SelectMui, { BaseSelectProps } from '@mui/material/Select';

import IconChevronDown from '@experimental-components/IconsComponents/ChevronDown';

import InputBaseSelect from './InputBaseSelect';

const SelectRoot = withStyles((theme: Theme) => ({
  select: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.typography.pxToRem(12),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1,
    minWidth: '70px !important',
    minHeight: 'auto !important',
  },

  filled: {
    backgroundColor: theme.palette.darkGrey[2],
    borderRadius: theme.shape.borderRadius,

    '&:hover': {
      borderRadius: theme.shape.borderRadius,
    },

    '&:focus': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(SelectMui);

const useStyles = makeStyles((theme: Theme) => ({
  iconRoot: {
    right: 16,
    position: 'absolute',
    top: 'calc(50% - 6px)',
  },

  menuList: {
    color: '#1E2025',
    fontSize: theme.typography.pxToRem(16),
  },

  menuPaper: {
    marginTop: theme.spacing(2),
    boxSizing: 'border-box',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #F2F2F4',
    boxShadow: '0px 8px 40px rgba(133, 133, 133, 0.2)',
  },
}));

interface Props extends BaseSelectProps {
  id?: string;
  error?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  children?: React.ReactNode;
}

function SelectFieldGroup({ id, error, fullWidth, disabled, required, children, ...others }: Props) {
  const classes = useStyles();

  const menuProps: BaseSelectProps['MenuProps'] = {
    classes: {
      list: classes.menuList,
      paper: classes.menuPaper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  };

  const iconComponent = (props: { className: string }) => (
    <IconChevronDown className={clsx(props.className, classes.iconRoot)} color="#000" />
  );
  const inputSelect = React.createElement(InputBaseSelect);

  return (
    <FormControl
      disabled={disabled}
      error={error}
      fullWidth={fullWidth}
      hiddenLabel
      required={required}
      variant="standard"
    >
      <SelectRoot
        disabled={disabled}
        error={error}
        IconComponent={iconComponent}
        id={id}
        input={inputSelect}
        MenuProps={menuProps}
        required={required}
        {...others}
      >
        {children}
      </SelectRoot>
    </FormControl>
  );
}

export default SelectFieldGroup;
