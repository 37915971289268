import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(5),
    paddingInline: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
  },

  actionButton: {
    minWidth: theme.typography.pxToRem(242),
  },
  actionButtonRetry: {
    minWidth: theme.typography.pxToRem(285),
    padding: '1rem',
  },

  wrapperkeepItems: {
    marginInline: theme.spacing(8),
    marginTop: theme.spacing(6),
    minWidth: '592px',
    overflow: 'auto',
    paddingBottom: theme.spacing(1),
  },

  rowkeepItems: {
    display: 'grid',
    marginBottom: theme.spacing(1),
    justifyContent: 'center',
  },

  rowSticky: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.default,
    zIndex: 2,
  },

  headKeepItems: {
    color: theme.palette.darkGrey.main,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
  },

  table: {
    '& th': {
      padding: theme.spacing(4),
      paddingBlock: 0,
      fontSize: theme.typography.pxToRem(14),
    },

    '& td': {
      paddingInline: theme.spacing(4),
      paddingBlock: theme.spacing(1),
    },
  },

  heightOverOfStock: {
    maxHeight: '250px',
  },

  linkMore: {
    cursor: 'pointer',
    lineHeight: '1',
  },
}));

export default useStyles;
