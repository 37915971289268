import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';
import ToggleButtonGroupMui, { ToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
  },

  groupedHorizontal: {
    '&:not(:first-child)': {
      borderLeft: `1px solid ${theme.palette.darkGrey[50]}`,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,

      marginLeft: (props: { spacing: number }) => theme.spacing(props.spacing),
    },

    '&:not(:last-child)': {
      borderTopRightRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
  },
}));

interface Props extends ToggleButtonGroupProps {
  spacing?: number;
  name?: string;
}

function ToggleButtonGroup({ spacing = 3, ...others }: Props) {
  const classes = useStyles({ spacing });

  return (
    <ToggleButtonGroupMui classes={{ root: classes.root, groupedHorizontal: classes.groupedHorizontal }} {...others} />
  );
}

export default ToggleButtonGroup;
