import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(20),
  },

  placeholder: {
    color: theme.palette.darkGrey[50],
  },

  modifierTypeDesc: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.darkGrey.main,
  },

  grayBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.darkGrey[2],
    borderRadius: theme.typography.pxToRem(4),
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
  },

  subtitles: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
  },

  checkboxForceSelection: {
    marginTop: theme.spacing(3),
    '&>span': {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.pxToRem(14),
    },
  },

  optionRule: {
    display: 'grid',
    gridTemplateColumns: '1fr 82px',
    columnGap: theme.spacing(2),
    alignItems: 'center',
    '&:first-child': {
      '&>div>p': {
        fontSize: theme.typography.pxToRem(13),
      },
    },
  },

  hideArrows: {
    '&>input[type=number]': {
      MozAppearance: 'textfield',
    },
    '&>input::-webkit-outer-spin-button, &>input::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
  },

  disabledField: {
    '&>div': {
      '&>div': {
        '&>div': {
          '&>div': {
            backgroundColor: '#F2F2F4',
          },
        },
      },
    },
  },
}));

export default useStyles;
