import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}
const ChevronLogo = forwardRef(function ChevronLogo(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#000', size = 14, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 14 14"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M8.05813 2.13525L8.74207 2.86479L2.73116 8.50002L8.74207 14.1353L8.05813 14.8648L1.26904 8.50002L8.05813 2.13525Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M14.0581 2.13525L14.7421 2.86479L8.73116 8.50002L14.7421 14.1353L14.0581 14.8648L7.26904 8.50002L14.0581 2.13525Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

export default ChevronLogo;
