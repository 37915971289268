import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useNotification from '@design-system/Notification/useNotification';

import IconButton from '@design-system/IconButton';
import IconClose from '@experimental-components/IconsComponents/Close';
import IconUploadImage from '@experimental-components/IconsComponents/UploadImage';

import { inWidthSizeImage, inHeightSizeImage } from '../../utils/files';

import useStyles from './styles';

function ImageDropzone({ initialImageUrl, onImageChange, loadDisabled, openDrawer, isEdition }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const [errorFileDimens, setErrorFileDimens] = useState(false);
  const { closeAllSnackbar, setNotification } = useNotification();

  function updateFile(newFile) {
    try {
      setFile({ ...newFile, preview: URL.createObjectURL(newFile) });
      // eslint-disable-next-line no-empty
    } catch (err) {}
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: 'image/jpeg, image/jpg, image/png',
    maxFiles: 1,
    maxSize: 10000000, // 10 MB
    onDrop: (acceptedFiles) => {
      closeAllSnackbar();
      const acceptedFile = acceptedFiles[0];
      const image = new Image();

      image.addEventListener('load', () => {
        setErrorFileDimens(false);

        if (inWidthSizeImage(image.width) && inHeightSizeImage(image.height)) {
          updateFile(acceptedFile);

          if (onImageChange) {
            onImageChange(acceptedFile);
          }
        } else {
          setErrorFileDimens(true);
        }
      });

      if (acceptedFile) image.src = URL.createObjectURL(acceptedFile);
    },
  });

  useEffect(() => {
    if (openDrawer && !isEdition && isEmpty(initialImageUrl)) {
      setFile(null);
    }
  }, [initialImageUrl]);

  useEffect(() => {
    if (fileRejections?.length > 0) {
      const latestReason = fileRejections[fileRejections.length - 1].errors[0].code;

      if (latestReason) {
        setNotification(t(`common:errors.image.all`), { variant: 'warning' });
      }
    }

    if (errorFileDimens) {
      setNotification(t(`common:errors.image.all`), { variant: 'warning' });
      setErrorFileDimens(false);
    }
  }, [setNotification, errorFileDimens, fileRejections, t]);

  function getPreview() {
    if (file?.preview || initialImageUrl) {
      return <img alt="Preview" src={file?.preview ?? initialImageUrl} />;
    }
    return (
      <div className={classes.withoutImage}>
        <IconUploadImage />
        <div className={classes.addPhoto}>{t('menuMaker:productForm.tabGeneral.addPhoto')}</div>
      </div>
    );
  }

  const handleRemoveImage = () => {
    setFile(null);
    if (onImageChange) onImageChange('');
  };

  return (
    <Box>
      <div className={clsx(classes.container, { [classes.loadDisabled]: loadDisabled })}>
        <div className={classes.wrapperPhoto}>
          <div {...getRootProps({ className: classes.photoContainer })}>
            {!loadDisabled && <input {...getInputProps()} />}
            {getPreview()}
          </div>

          {(file || initialImageUrl) && (
            <IconButton className={classes.removeImage} onClick={handleRemoveImage} size="small">
              <IconClose size={12} />
            </IconButton>
          )}
        </div>
      </div>

      {loadDisabled && (
        <Typography color="error" variant="caption">
          {t('menuMaker:productForm.image.override.limitExceeded')}
        </Typography>
      )}
    </Box>
  );
}

ImageDropzone.defaultProps = {
  shouldNotifyOnInitialImageLoad: false,
};

ImageDropzone.propTypes = {
  initialImageUrl: PropTypes.string,
  onImageChange: PropTypes.func,
  loadDisabled: PropTypes.bool,
  shouldNotifyOnInitialImageLoad: PropTypes.bool,
  openDrawer: PropTypes.bool,
  isEdition: PropTypes.bool,
};

export default ImageDropzone;
