import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import camelCase from 'lodash/camelCase';
import dayjs from 'dayjs';
import { useFileSaver } from '@open-react-hooks/core';

import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

import ButtonExportExcel from '@design-system/ButtonExportExcel';
import useNotification from '@design-system/Notification/useNotification';
import ChanelItem from 'components/ChanelItem';

import { daysOrdered } from 'utils/menuMaker';
import { formatTypesDates } from 'utils/times';

import TabsCategoriesProducts from './TabsCategoriesProducts';
import useStyles from './styles';

function PreviewMenuDetails({
  categories,
  exportMenu,
  exportMenuState,
  loading,
  loadingCategoryProducts,
  loadingMenuProductsDetails,
  menu,
  onLoadCategoryProducts,
  onLoadCategoryProductsDetails,
  productsDetails,
  t,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { setSaveAs } = useFileSaver();
  const { closeAllSnackbar, setNotification } = useNotification();

  useEffect(() => {
    if (exportMenuState.fetched) {
      try {
        const menuName = `${menu?.name} - ${dayjs(new Date()).format(formatTypesDates.EXPORT_DATE_WITH_HOURS)}.xlsx`;

        setSaveAs(exportMenuState.document, {
          mode: 'url',
          fileName: menuName,
        });
      } catch (error) {
        setNotification(t('menuMaker:messages.exportMenuError'), { variant: 'error' });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportMenuState.fetched]);

  useEffect(() => {
    if (exportMenuState.error) {
      setNotification(t('menuMaker:messages.exportMenuError'), { variant: 'error' });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportMenuState.error]);

  useEffect(() => {
    return () => {
      closeAllSnackbar();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Box className={classes.root} pl={theme.typography.pxToRem(32)} pr={theme.typography.pxToRem(32)}>
        <Box mt={6}>
          <Grid alignItems="center" container justifyContent="space-between">
            <Skeleton height={55} variant="text" width="30%" />
            <Skeleton height={30} variant="text" width="10%" />
          </Grid>

          <Box mb={1} mt={5}>
            <Skeleton height={40} variant="text" width="20%" />
          </Box>

          <Skeleton height={110} variant="rectangular" width="60%" />
          <br />
          <Skeleton height={110} variant="rectangular" width="60%" />

          <Box mt={6}>
            <Skeleton height={40} variant="text" width="25%" />

            <div className={classes.containerProviders}>
              <Skeleton height={15} variant="rectangular" width="10%" />
              <Skeleton height={15} variant="rectangular" width="10%" />
              <Skeleton height={15} variant="rectangular" width="10%" />
            </div>
          </Box>

          <Box mt={6}>
            <Skeleton height={40} variant="text" width="30%" />

            <div className={classes.containerProviders}>
              <Skeleton height={15} variant="rectangular" width="10%" />
              <Skeleton height={15} variant="rectangular" width="10%" />
              <Skeleton height={15} variant="rectangular" width="10%" />
            </div>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Box mb={4} mt={6} px={theme.typography.pxToRem(32)}>
        <Grid alignItems="center" container justifyContent="space-between">
          <Box
            className={classes.title}
            fontSize={theme.typography.pxToRem(32)}
            fontWeight={theme.typography.fontWeightMedium}
          >
            {menu?.name}
          </Box>

          <Grid className={classes.wrapperExcel} item md={3} sm={12} xs={12}>
            <ButtonExportExcel
              disabled={exportMenuState.fetching}
              loading={exportMenuState.fetching}
              loadingText={t('reports:messages.exportToExcelLoadingText')}
              onClick={exportMenu}
            >
              {t('reports:buttons.exportToExcel')}
            </ButtonExportExcel>
          </Grid>
        </Grid>

        <Box className={classes.subtitles} mt={4}>
          {t('menuMaker:menuMakerTable.columns.schedule')}
        </Box>

        <Box className={classes.containerTimers}>
          {menu?.operationTimes?.length ? (
            menu?.operationTimes.map((timer) => (
              <Paper key={timer.uuid} className={classes.paper} variant="outlined">
                {daysOrdered(timer?.days).map((day) => (
                  <Box key={day} className={classes.paperDays}>
                    <Typography variant="subtitle1">{t(`common:days.${camelCase(day)}`)}</Typography>
                    <Typography variant="subtitle1">{`${timer.startHour} - ${timer.endHour}`}</Typography>
                  </Box>
                ))}
              </Paper>
            ))
          ) : (
            <Typography variant="subtitle1">{t('common:messages.unassigned')}</Typography>
          )}
        </Box>

        <Box mt={6}>
          <Box className={classes.subtitles}>{t('menuMaker:menuDetailsSections.salesChannels')}</Box>

          <Box className={classes.containerProviders}>
            {menu?.providers?.length ? (
              menu?.providers.map((provider) => (
                <ChanelItem key={provider.uuid} size={32} value={provider?.providerName} />
              ))
            ) : (
              <Typography variant="subtitle1">{t('common:messages.unassigned')}</Typography>
            )}
          </Box>
        </Box>
      </Box>
      <TabsCategoriesProducts
        categories={categories}
        loading={loadingCategoryProducts}
        loadingCategoryProductDetails={loadingMenuProductsDetails}
        onLoadProductDetails={onLoadCategoryProductsDetails}
        onLoadProducts={onLoadCategoryProducts}
        productsDetails={productsDetails}
        t={t}
      />
    </Box>
  );
}

PreviewMenuDetails.propTypes = {
  categories: PropTypes.object,
  exportMenu: PropTypes.func,
  exportMenuState: PropTypes.object,
  loading: PropTypes.bool,
  loadingCategoryProducts: PropTypes.bool,
  loadingMenuProductsDetails: PropTypes.bool,
  menu: PropTypes.object,
  onLoadCategoryProducts: PropTypes.func,
  onLoadCategoryProductsDetails: PropTypes.func,
  productsDetails: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation(['menuMaker', 'reports'])(PreviewMenuDetails);
