import { useTranslation } from 'react-i18next';
import type { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

import ButtonPrimary from '@design-system/ButtonPrimary';
import ButtonOutlined from '@design-system/ButtonOutlined';

import { REMOVE_ACTIONS } from '../removeActionTypes';

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitleRoot: {
    textAlign: 'center',
    fontSize: 24,
    marginTop: 16,
    marginButton: 0,
  },

  paperWidthMd: {
    borderRadius: 8,
    maxWidth: 786,
  },

  dialogContentRoot: {
    textAlign: 'center',
    paddingRight: 48,
    paddingLeft: 48,

    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },

  dialogActionsRoot: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '& button': {
      minWidth: 242,
    },

    '& > div > :not(:first-child)': {
      marginLeft: theme.spacing(5),
    },
  },
}));

interface Props {
  open: boolean;
  associations: string[];
  onConfirmDelete: (action: string) => void;
  onCancel: () => void;
  isLoading: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function PaperComponent(props: any) {
  return <Paper {...props} elevation={0} />;
}

function DialogConfirmDelete({ associations, open, onConfirmDelete, onCancel, isLoading }: Props) {
  const classes = useStyles();
  const { t } = useTranslation('menuMaker');
  return (
    <Dialog
      aria-labelledby="dialogConfirm"
      classes={{ paperWidthMd: classes.paperWidthMd }}
      fullWidth
      maxWidth="md"
      open={open}
      PaperComponent={PaperComponent}
    >
      <DialogTitle classes={{ root: classes.dialogTitleRoot }} data-testid="confirmationDialogInvoicingGlobal">
        {t('menuMaker:productForm:confirmRemoveDialog.title')}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentRoot }}>
        <Box color="text.secondary" fontSize={18} mb={3}>
          {t('menuMaker:productForm:confirmRemoveDialog.description')}{' '}
          <Box component="span" fontWeight={500}>
            {t('menuMaker:productForm:confirmRemoveDialog.emphasis')}
          </Box>{' '}
          {t('menuMaker:productForm:confirmRemoveDialog.description2')}
        </Box>
        <Box color="text.secondary" fontSize={18}>
          {associations.map((p) => p).join(', ')}
        </Box>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Box mt={4}>
          {isLoading && <CircularProgress color="inherit" size={24} />}
          {!isLoading && (
            <>
              <ButtonPrimary onClick={() => onConfirmDelete(REMOVE_ACTIONS.RELATION_RECEIPT)}>
                {t('menuMaker:productForm:confirmRemoveDialog.remove')}
              </ButtonPrimary>
              <ButtonPrimary onClick={() => onConfirmDelete(REMOVE_ACTIONS.RELATION_RECEIPT_AND_RECEIPT)}>
                {t('menuMaker:productForm:confirmRemoveDialog.removeWithRecipes')}
              </ButtonPrimary>
            </>
          )}
        </Box>
        <Box mb={3} mt={3}>
          <ButtonOutlined fullWidth onClick={onCancel}>
            {t('common:buttons.cancel')}
          </ButtonOutlined>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default DialogConfirmDelete;
