// @ts-nocheck
import { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import Accordion from '@experimental-components/Accordion';
import RowCheckbox from '@experimental-components/RowCheckbox';

import { PropsStoresOptions } from 'utils/menus/propsMenuCopy';
import { subscriptionStatusTypes } from 'utils/subscription/statusTypes';
import { copyModes } from 'utils/menus/menuCopy';

import SkeletonHelper from './SkeletonHelper';
import useStyles from './styles';

interface Props extends PropsStoresOptions {
  isLoading: boolean;
  elementsSelected: string[];
  onSelectElements: (value: string[]) => void;
  configType: string;
}

function SelectDestinationElements({ isLoading, stores, elementsSelected, onSelectElements, configType }: Props) {
  const classes = useStyles();
  const [storeOpenAccordion, setStoresOpenAccordion] = useState<string[]>([]);
  const [brandsOpenState, setBrandsOpenState] = useState({});

  useEffect(() => {
    if (stores?.length) {
      const tmpOpenAccordion = stores?.map((store) => store?.uuid);
      setStoresOpenAccordion(tmpOpenAccordion);

      let tmpBrandsOpenState = {};
      stores?.forEach((store) => {
        store?.brands?.forEach((brand) => {
          tmpBrandsOpenState = { ...tmpBrandsOpenState, [brand.uuid]: true };
        });
      });
      setBrandsOpenState(tmpBrandsOpenState);
    }
  }, []);

  const handleOpenStoreAccordion = (value: string) => {
    const tmpOpenAccordion = [...storeOpenAccordion];
    if (storeOpenAccordion.includes(value)) {
      setStoresOpenAccordion([...tmpOpenAccordion].filter((item) => item !== value));
    } else {
      tmpOpenAccordion.push(value);
      setStoresOpenAccordion(tmpOpenAccordion);
    }
  };

  const handleSelectElements = (elementUuid: string) => {
    const elementsTmp = [...elementsSelected];

    if (elementsTmp.includes(elementUuid)) {
      onSelectElements([...elementsTmp].filter((item) => item !== elementUuid));
    } else {
      elementsTmp.push(elementUuid);
      onSelectElements(elementsTmp);
    }
  };

  const handleOpenBrands = (brandUuid: string) => {
    setBrandsOpenState((currentState) => ({ ...currentState, [brandUuid]: !currentState[brandUuid] }));
  };

  if (isLoading) {
    return <SkeletonHelper type="between" />;
  }

  return stores?.map((store) => (
    <Accordion
      key={store?.uuid}
      disabledTitle={store?.status !== subscriptionStatusTypes.AVAILABLE}
      expanded={storeOpenAccordion?.includes(store?.uuid)}
      iconSize={20}
      onClick={() => handleOpenStoreAccordion(store?.uuid)}
      title={store?.name}
    >
      {configType === copyModes.COPY_MENU && (
        <List>
          {store?.brands?.map((brand) => (
            <ListItem
              key={brand?.uuid}
              onClick={() => {
                if (store?.status === subscriptionStatusTypes.AVAILABLE) handleSelectElements(brand?.uuid);
              }}
            >
              <RowCheckbox
                checked={elementsSelected?.includes(brand?.uuid)}
                description={brand?.name}
                disabled={store?.status !== subscriptionStatusTypes.AVAILABLE}
                label=""
                value={brand?.uuid}
              />
            </ListItem>
          ))}
        </List>
      )}

      {configType === copyModes.SYNC_COPY &&
        store?.brands?.map((brand) => (
          <Accordion
            key={brand?.uuid}
            disabledTitle={store?.status !== subscriptionStatusTypes.AVAILABLE}
            expanded={brandsOpenState[brand.uuid]}
            headerStyle={classes.accordionHeader}
            iconSize={14}
            onClick={() => handleOpenBrands(brand.uuid)}
            title={brand.name}
          >
            <List>
              {brand?.menus?.map((menu) => (
                <ListItem
                  key={menu?.uuid}
                  onClick={() => {
                    if (store?.status === subscriptionStatusTypes.AVAILABLE) handleSelectElements(menu.uuid);
                  }}
                  style={{ paddingBlock: 0 }}
                >
                  <RowCheckbox
                    checked={elementsSelected.includes(menu.uuid)}
                    description={menu?.name}
                    disabled={store?.status !== subscriptionStatusTypes.AVAILABLE}
                    label=""
                    value={menu?.uuid}
                  />
                </ListItem>
              ))}
            </List>
          </Accordion>
        ))}
    </Accordion>
  ));
}

export default SelectDestinationElements;
