import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';

import DateFnsUtils from '@date-io/dayjs';

import ViewPanelProvider from 'providers/root/ViewPanelProvider';
import ContentLayoutProvider from 'providers/root/ContentLayoutProvider';
import SidebarLayoutProvider from 'providers/root/SidebarLayoutProvider';
import BrandsProvider from 'providers/root/BrandsProvider';
import BrandsProviderV2 from 'providers/root/BrandsProviderV2';
import AddressProvider from 'providers/root/AddressProvider';
import SubscriptionProvider from 'providers/root/SubscriptionProvider';
import ListsOptionsProvider from 'providers/root/ListsOptionsProvider';
import ParrotGoogleMapProvider from '@experimental-components/GoogleMapsLocation/GoogleMapProvider';

import useFiltersReports from 'hooks/useFiltersReports';

import ComposeProviders from 'components/ComposeProviders';

import ReportsRootProvider from './AppProviders/ReportsRootProvider';
import MenuMakerRootProvider from './AppProviders/MenuMakerRootProvider';
import WorkforceRootProvider from './AppProviders/WorkforceRootProvider';
import InvoicingRootProvider from './AppProviders/InvoicingRootProvider';
import SettingsRootProvider from './AppProviders/SettingsRootProvider';
import OnlineOrderingRootProvider from './AppProviders/OnlineOrderingRootProvider';

function AppProviders({ children }) {
  const location = useLocation();
  const { removeFilters } = useFiltersReports();

  useEffect(() => {
    if (!location.pathname.includes('/reports')) {
      removeFilters();
    }
  }, []);

  return (
    <LocalizationProvider utils={DateFnsUtils}>
      <ComposeProviders
        providers={[
          <ContentLayoutProvider />,
          <ViewPanelProvider />,
          <SidebarLayoutProvider />,
          <ParrotGoogleMapProvider
            config={{
              googleMapURLApi: 'https://maps.googleapis.com/maps/api/js',
              apiKey: 'AIzaSyBmuLEB-EAmaQ2LeHXQCV7IKChAYWF77Z8',
              libraries: ['geometry', 'drawing', 'places'],
            }}
          />,
          <BrandsProvider />,
          <BrandsProviderV2 />,
          <AddressProvider />,
          <SubscriptionProvider />,
          <ListsOptionsProvider />,
          <ReportsRootProvider />,
          <MenuMakerRootProvider />,
          <WorkforceRootProvider />,
          <InvoicingRootProvider />,
          <SettingsRootProvider />,
          <OnlineOrderingRootProvider />,
        ]}
      >
        {React.Children.only(children)}
      </ComposeProviders>
    </LocalizationProvider>
  );
}

AppProviders.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppProviders;
