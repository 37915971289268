import { withTranslation, TFunction } from 'react-i18next';
import type { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import IconDeleteOutline from '@experimental-components/IconsComponents/DeleteOutline';

import Menu from '@experimental-components/Menu';
import IconButton from '@design-system/IconButton';

import { MenuProps } from '@mui/material/Menu';

import useIconParrot from 'hooks/useIconParrot';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    boxShadow: `0px 8px 40px ${theme.palette.darkGrey[5]}`,
    minWidth: 168,
  },
}));

interface Props extends MenuProps {
  open: boolean;
  onDelete?: React.MouseEventHandler<HTMLLIElement>;
  onDuplicate?: React.MouseEventHandler<HTMLLIElement>;
  onEdit?: React.MouseEventHandler<HTMLLIElement>;
  t: TFunction;
}

function MenuActions({ open, onDelete, onDuplicate, onEdit, onClose, t, ...others }: Props) {
  const classes = useStyles();

  const { IconDuplicateOutline } = useIconParrot({
    icons: ['duplicateOutline'],
  });

  return (
    <Menu
      classes={{ paper: classes.paper }}
      data-testid="actionsTabsMenuMaker"
      keepMounted
      onClose={onClose}
      open={open}
      {...others}
    >
      {onEdit && (
        <Menu.Item data-testid="editItemButton" onClick={onEdit}>
          <IconButton size="large" startIcon={<EditOutlinedIcon />}>
            {t('common:actions.edit')}
          </IconButton>
        </Menu.Item>
      )}

      {onDuplicate && (
        <Menu.Item data-testid="duplicateItemButton" onClick={onDuplicate}>
          <IconButton size="large" startIcon={<IconDuplicateOutline />}>
            {t('common:actions.duplicate')}
          </IconButton>
        </Menu.Item>
      )}

      {onDelete && (
        <Menu.Item data-testid="deleteItemButton" onClick={onDelete}>
          <IconButton size="large" startIcon={<IconDeleteOutline />}>
            <Box color="error.main" component="span">
              {t('common:actions.delete')}
            </Box>
          </IconButton>
        </Menu.Item>
      )}
    </Menu>
  );
}

export default withTranslation()(MenuActions);
