import { memo } from 'react';
import { compose } from 'redux';
import { Route } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import { withRequiredLicense } from 'providers/root/AuthProvider';
import Layout, { ViewPanelLayout } from 'Layout';

import Filters from 'components/ReportModifiers/Filters';
import ModifiersByProduct from 'components/ReportModifiers/ModifiersByProduct';
import ModifierGroupsByOption from 'components/ReportModifiers/ModifierGroupsByOption';

import Navigation from './Navigation';
import { FiltersProvider } from './context';

const ReportModifiers = () => {
  const { t } = useTranslation('reports');
  const theme = useTheme();

  return (
    <Layout>
      <FiltersProvider>
        <ViewPanelLayout
          goBackPath="/reports"
          pb={4}
          title={t('navigation.modifiersReport')}
          titleProps={{ style: { paddingLeft: theme.spacing(4) } }}
        >
          <Filters />

          <Navigation />

          <Route exact path="/reports/modifiers/modifiersByProduct">
            <ModifiersByProduct t={t} />
          </Route>

          <Route exact path="/reports/modifiers/modifiersByOption">
            <ModifierGroupsByOption />
          </Route>
        </ViewPanelLayout>
      </FiltersProvider>
    </Layout>
  );
};

export default compose(memo, withRequiredLicense())(ReportModifiers);
