// @ts-nocheck
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';

import Table from '@experimental-components/Table';

const useStyles = makeStyles((theme: Theme) => ({
  hiddenColumn: {
    padding: '0px',
    width: theme.spacing(6),
  },

  head: {
    fontWeight: 500,
  },
}));

interface BaseInfo {
  uuid: string;
  name: string;
}

interface Props {
  isSync: boolean;
  t: (value: string) => void;
  selectionList: { uuid: string; name: string; brands: { uuid: string; name: string; menus?: BaseInfo[] }[] }[];
}

function SelectionSummary({ isSync, selectionList, t }: Props) {
  const classes = useStyles();

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.HeadCell className={classes.hiddenColumn} />
          <Table.HeadCell align="left">{t('menuMaker:copyMenu.store')}</Table.HeadCell>
          <Table.HeadCell align="left">{t('menuMaker:copyMenu.brand')}</Table.HeadCell>
          {isSync && <Table.HeadCell align="left">{t('menuMaker:copyMenu.menu')}</Table.HeadCell>}
        </Table.Row>
      </Table.Head>

      <Table.Body>
        {isSync
          ? selectionList.map((store, indexStore) =>
              store.brands.map((brand, indexBrand) => (
                <Table.Row key={`${store.uuid}-${brand.uuid}`}>
                  <Table.Cell className={classes.hiddenColumn} isBorder={indexStore + indexBrand !== 0} />

                  <Table.Cell align="left" component="th" isBorder={indexStore + indexBrand !== 0}>
                    <Typography className={classes.head}>{store.name}</Typography>
                  </Table.Cell>

                  <Table.Cell align="left" isBorder={indexStore + indexBrand !== 0}>
                    <Typography>{brand.name}</Typography>
                  </Table.Cell>

                  <Table.BigCell align="left" isBorder={indexStore + indexBrand !== 0}>
                    <Table.BigCell.ExpandableList
                      list={brand.menus.map((menu) => (
                        <Table.BigCell.Option key={menu.uuid}>
                          <Typography>{menu.name}</Typography>
                        </Table.BigCell.Option>
                      ))}
                    />
                  </Table.BigCell>
                </Table.Row>
              )),
            )
          : selectionList.map((store, index) => (
              <Table.Row key={store.uuid}>
                <Table.Cell className={classes.hiddenColumn} isBorder={index !== 0} />

                <Table.Cell align="left" component="th" isBorder={index !== 0}>
                  <Typography className={classes.head}>{store.name}</Typography>
                </Table.Cell>

                <Table.BigCell align="left" isBorder={index !== 0}>
                  <Table.BigCell.ExpandableList
                    list={store.brands.map((brand) => (
                      <Table.BigCell.Option key={brand.uuid}>
                        <Typography>{brand.name}</Typography>
                      </Table.BigCell.Option>
                    ))}
                  />
                </Table.BigCell>
              </Table.Row>
            ))}
      </Table.Body>
    </Table>
  );
}

export default SelectionSummary;
