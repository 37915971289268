import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapperTooltip: {
    position: 'relative',
    width: '100%',
    minWidth: 0,
  },

  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },

  tooltip: {
    position: 'absolute',
    right: 0,
    zIndex: 1,
  },
}));

export default useStyles;
