import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCellMui from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHeadMui from '@mui/material/TableHead';
import TableRowMui from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

import TableHeadCell from './TableHeadCell';
import PlaceholdersTable from './PlaceholdersTable';

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 8px 20px rgba(164, 170, 186, 0.15)',
  },
}))(TableHeadMui);

const TableCell = withStyles((theme) => ({
  root: {
    borderLeft: `1px solid ${theme.palette.darkGrey[15]}`,
    borderBottom: `1px solid ${theme.palette.darkGrey[15]}`,
    padding: '1rem .75rem',
    lineHeight: 'normal',
    letterSpacing: 0,
  },
}))(TableCellMui);

const TableRow = withStyles(() => ({
  root: {
    '& > th:first-child, td:first-child ': {
      borderLeft: 0,
      paddingLeft: '32px',
    },
    '& > th:last-child, td:last-child': {
      paddingRight: '32px',
    },
  },
  hover: {
    cursor: (props) => (props.onClick ? 'pointer' : 'default'),
  },
}))(TableRowMui);

const useStyles = makeStyles(() => ({
  container: {
    overflowX: 'visible',
  },

  table: {
    '& > thead > tr > th': {
      backgroundColor: 'white',
      position: 'sticky',
      top: 0,
    },
  },

  extraPadding: {
    '& > thead > tr > th': {
      paddingTop: '53px',
      boxShadow: 'inset 0px -0.5px 0px 0px rgba(0, 0, 0, 0.4)',
    },
  },

  generalTablePadding: {
    '& > thead > tr > th': {
      paddingTop: '1rem',
      boxShadow: '18px 3px 11px 3px rgba(164, 170, 186, 0.15)',
      paddingBottom: '1rem',
    },
  },
}));

function ReportsTable({ children, stickyHeader = false, isGeneralTable = false }) {
  const classes = useStyles();
  const stickyRef = useRef(null);
  const [activateSticky, setActivateSticky] = useState(false);

  useEffect(() => {
    if (stickyHeader) {
      const observer = new IntersectionObserver((entries) => {
        setActivateSticky(!entries[0].isIntersecting);
      });

      if (stickyRef.current) observer.observe(stickyRef.current);
    }
  }, [stickyRef, stickyHeader]);

  return (
    <Box boxShadow="0px -6px 20px -6px rgba(164, 170, 186, 0.15)">
      <div ref={stickyRef} />

      <TableContainer className={clsx({ [classes.container]: activateSticky })}>
        <Table
          className={clsx(classes.table, {
            [classes.extraPadding]: activateSticky,
            [classes.generalTablePadding]: Boolean(isGeneralTable),
          })}
        >
          {children}
        </Table>
      </TableContainer>
    </Box>
  );
}

ReportsTable.HeadCell = TableHeadCell;
ReportsTable.Head = TableHead;
ReportsTable.Row = TableRow;
ReportsTable.Body = TableBody;
ReportsTable.Cell = TableCell;
ReportsTable.Placeholders = PlaceholdersTable;

ReportsTable.propTypes = {
  ...Table.propTypes,
};

export default ReportsTable;
