import { memo } from 'react';
import { compose } from 'redux';
import { Route } from 'react-router';
import { useTranslation } from 'react-i18next';

import { withRequiredLicense } from 'providers/root/AuthProvider';
import Layout, { ViewPanelLayout } from 'Layout';

import Summary from 'components/ReportAverageTicket/Summary';
import Detailed from 'components/ReportAverageTicket/Detailed';
import Filters from 'components/ReportAverageTicket/Filters';
import Navigation from 'components/ReportAverageTicket/Navigation';

import { FilterProvider } from './context';

const ReportAverageTicket = () => {
  const { t } = useTranslation('reports');

  return (
    <Layout>
      <FilterProvider>
        <ViewPanelLayout goBackPath="/reports" pb={4} title={t('navigation.averageTicketReport')}>
          <Filters />

          <Navigation />

          <Route exact path="/reports/average-ticket/summary">
            <Summary />
          </Route>

          <Route exact path="/reports/average-ticket/detailed">
            <Detailed />
          </Route>
        </ViewPanelLayout>
      </FilterProvider>
    </Layout>
  );
};

export default compose(memo, withRequiredLicense())(ReportAverageTicket);
