import { useState, createContext, ReactNode, Dispatch, SetStateAction, useEffect } from 'react';
import dayjs from 'dayjs';

import { formatTypesDates } from 'utils/times';
import { transformFilters, transformParamsToFilters } from 'utils/reports/modifiers/transformers';

import { useQueryParams } from 'hooks';

import { useLocation } from 'react-router';

import { Filters, FiltersQueryParams } from './types';

interface FilterContextType {
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
}
export const FiltersContext = createContext<FilterContextType | null>(null);

interface Props {
  children: ReactNode;
}

export function FiltersProvider({ children }: Props) {
  const location = useLocation();

  const starHours = '00:00:00';
  const endHours = '23:59:59';

  const initialFilters: Filters = {
    startDate: `${dayjs().format(formatTypesDates.SEND_FORM)} ${starHours}`,
    endDate: `${dayjs().format(formatTypesDates.SEND_FORM)} ${endHours}`,
    brands: [],
    providers: [],
    location: location.pathname,
  };

  const { queryParams } = useQueryParams<FiltersQueryParams>();
  const { setManyQueryParams } = useQueryParams<Filters>();

  const queryValues = {
    startDate: queryParams.startDate
      ? `${dayjs(queryParams.startDate).format(formatTypesDates.SEND_FORM)} ${starHours}`
      : initialFilters.startDate,
    endDate: queryParams.endDate
      ? `${dayjs(queryParams.endDate).format(formatTypesDates.SEND_FORM)} ${endHours}`
      : initialFilters.endDate,
    brands: queryParams.brands,
    providers: queryParams.providers,
  };
  const queryParamsTransformed: Filters = transformParamsToFilters(queryValues);
  const defaultFilters = Object.assign(initialFilters, queryParamsTransformed);
  const [filters, setFilters] = useState<Filters>(defaultFilters);

  useEffect(() => {
    setManyQueryParams(transformFilters(filters));
  }, [filters]);

  return <FiltersContext.Provider value={{ filters, setFilters }}>{children}</FiltersContext.Provider>;
}
