import { useState } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Feature, useFeature, useFeatures } from 'FeatureProvider';

import { useTheme } from '@mui/material/styles';

import LogOutOutline from '@experimental-components/IconsComponents/LogOutOutline';
import IconHelp from '@experimental-components/IconsComponents/HelpPrincipalMenu';
import IconAlert from '@experimental-components/IconsComponents/Alert';

import Reports from '@experimental-components/IconsComponents/PrincipalMenu/Reports';
import Menu from '@experimental-components/IconsComponents/PrincipalMenu/Menu';
import Users from '@experimental-components/IconsComponents/PrincipalMenu/Users';
import Invoice from '@experimental-components/IconsComponents/PrincipalMenu/Invoice';
import OnlineOrdering from '@experimental-components/IconsComponents/PrincipalMenu/OnlineOrdering';
import Settings from '@experimental-components/IconsComponents/PrincipalMenu/Settings';
import Inventories from '@experimental-components/IconsComponents/PrincipalMenu/Inventories';
import Subscription from '@experimental-components/IconsComponents/PrincipalMenu/Subscription';

import SidebarLayout from 'components/SidebarLayout';

import useUserStore from 'hooks/useUserStore';
import useViewPanel from 'hooks/useViewPanel';
import useContentLayout from 'hooks/useContentLayout';
import useEnvironment from 'hooks/useEnvironment';
import useLicenseState from 'hooks/useLicenseState';
import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';

import { lastPaymentStatus } from 'utils/subscription';
import { getValidateItemsAvailabilityFlags } from 'utils/featureFlags/menus';
import Sparkle from '@experimental-components/IconsComponents/Sparkle';
import SearchOutline from '@experimental-components/IconsComponents/SearchOutline';
import SparkleWithBackground from '@experimental-components/IconsComponents/SparkleWithBackground';
import { Hidden } from '@mui/material';
import { hideCommandBar } from 'utils/featureFlags/commandBar';

export function AppFrame({ children, t }) {
  const theme = useTheme();
  const location = useLocation();
  const { environmentsState } = useEnvironment();
  const { paymentStatus, paymentInvalid, licenseInvalid } = useLicenseState();
  const { openDrawer, openMdDrawer, isPublishMenuError } = useSidebarLayout();

  const { flags } = useFeatures();
  const { enableValidateItemsAvailability } = getValidateItemsAvailabilityFlags(flags);

  const {
    user,
    storeState: { selected: storeSelected },
  } = useUserStore();
  const { setOpen } = useViewPanel();
  const { setValue } = useContentLayout();
  const [changeColorIcon, setChangeColorIcon] = useState(null);

  const superUserAccess = useFeature('adminStore');
  const flagEnableInventory = environmentsState.REACT_APP_FEATURE_INVENTORY_ENABLED === 'true';
  const flagEnableOnlineOrdering =
    useFeature('@module.onlineOrdering') && environmentsState.REACT_APP_FEATURE_ONLINE_ORDERING_ENABLED === 'true';

  function handleResetViewPanel() {
    setOpen(false);
    setValue(0);
  }

  const handleMouseEnter = (item) => {
    setChangeColorIcon((state) => ({ ...state, [item]: true }));
  };
  const handleMouseLeave = (item) => {
    setChangeColorIcon((state) => ({ ...state, [item]: false }));
  };

  const authenticatedLink = Canny('authenticateCannyLink', 'https://parrotconnect.canny.io/mejoras');

  function renderMenuSuperUser() {
    return (
      <>
        {!storeSelected?.isDraft && (
          <SidebarLayout.Item
            exact={false}
            isActive={(match, locationRef) => {
              return locationRef.pathname.startsWith('/reports');
            }}
            label={t('common:nav.reports')}
            onClick={handleResetViewPanel}
            onMouseEnter={() => {
              if (!location.pathname.startsWith('/reports')) handleMouseEnter('reports');
            }}
            onMouseLeave={() => {
              if (!location.pathname.startsWith('/reports')) handleMouseLeave('reports');
            }}
            openMenu={openMdDrawer || openDrawer}
            startIcon={
              <Reports
                color={changeColorIcon?.reports ? theme.palette.secondary.main : theme.palette.common.white}
                size={16}
              />
            }
            to="/reports"
          />
        )}

        <SidebarLayout.Item
          complementIcon={enableValidateItemsAvailability && isPublishMenuError ? <IconAlert size={20} /> : null}
          exact={false}
          isActive={(match, locationRef) => {
            const pathMenus = locationRef?.pathname?.match('/menus');
            return !!pathMenus;
          }}
          label={t('common:nav.menu')}
          onClick={handleResetViewPanel}
          onMouseEnter={() => {
            if (!location?.pathname?.match('/menus')) handleMouseEnter('menu');
          }}
          onMouseLeave={() => {
            if (!location?.pathname?.match('/menus')) handleMouseLeave('menu');
          }}
          openMenu={openMdDrawer || openDrawer}
          startIcon={
            <Menu
              alert={enableValidateItemsAvailability && isPublishMenuError}
              color={changeColorIcon?.menu ? theme.palette.secondary.main : theme.palette.common.white}
              size={16}
            />
          }
          to="/menus/resume"
        />

        {!storeSelected?.isDraft && (
          <>
            <SidebarLayout.Item
              exact={false}
              label={t('common:nav.setting')}
              onClick={handleResetViewPanel}
              onMouseEnter={() => {
                if (!location.pathname.startsWith('/settings')) handleMouseEnter('settings');
              }}
              onMouseLeave={() => {
                if (!location.pathname.startsWith('/settings')) handleMouseLeave('settings');
              }}
              openMenu={openMdDrawer || openDrawer}
              startIcon={
                <Settings
                  color={changeColorIcon?.settings ? theme.palette.secondary.main : theme.palette.common.white}
                  size={16}
                />
              }
              to="/settings"
            />
            <SidebarLayout.Item
              exact={false}
              label={t('common:nav.personal')}
              onClick={handleResetViewPanel}
              onMouseEnter={() => {
                if (!location.pathname.startsWith('/workforce')) handleMouseEnter('users');
              }}
              onMouseLeave={() => {
                if (!location.pathname.startsWith('/workforce')) handleMouseLeave('users');
              }}
              openMenu={openMdDrawer || openDrawer}
              startIcon={
                <Users
                  color={changeColorIcon?.users ? theme.palette.secondary.main : theme.palette.common.white}
                  size={16}
                />
              }
              to="/workforce"
            />
            {/* @flag inventories */}
            {flagEnableInventory && (
              <SidebarLayout.Item
                exact={false}
                label={t('common:nav.inventories')}
                onClick={handleResetViewPanel}
                onMouseEnter={() => {
                  if (!location.pathname.startsWith('/inventories')) handleMouseEnter('inventories');
                }}
                onMouseLeave={() => {
                  if (!location.pathname.startsWith('/inventories')) handleMouseLeave('inventories');
                }}
                openMenu={openMdDrawer || openDrawer}
                startIcon={
                  <Inventories
                    color={changeColorIcon?.inventories ? theme.palette.secondary.main : theme.palette.common.white}
                    size={16}
                  />
                }
                to="/inventories"
              />
            )}
            {!storeSelected.isDraft && flagEnableOnlineOrdering && (
              <SidebarLayout.Item
                exact={false}
                label={t('common:nav.digitalMenu')}
                onClick={handleResetViewPanel}
                onMouseEnter={() => {
                  if (!location.pathname.startsWith('/online-ordering')) handleMouseEnter('online-ordering');
                }}
                onMouseLeave={() => {
                  if (!location.pathname.startsWith('/online-ordering')) handleMouseLeave('online-ordering');
                }}
                openMenu={openMdDrawer || openDrawer}
                startIcon={
                  <OnlineOrdering
                    color={
                      changeColorIcon?.['online-ordering'] ? theme.palette.secondary.main : theme.palette.common.white
                    }
                    size={16}
                  />
                }
                to="/online-ordering"
              />
            )}
            <SidebarLayout.Item
              exact={false}
              label={t('common:nav.invoicing')}
              onClick={handleResetViewPanel}
              onMouseEnter={() => {
                if (!location.pathname.startsWith('/invoicing')) handleMouseEnter('invoice');
              }}
              onMouseLeave={() => {
                if (!location.pathname.startsWith('/invoicing')) handleMouseLeave('invoice');
              }}
              openMenu={openMdDrawer || openDrawer}
              startIcon={
                <Invoice
                  color={changeColorIcon?.invoice ? theme.palette.secondary.main : theme.palette.common.white}
                  size={16}
                />
              }
              to="/invoicing"
            />
          </>
        )}

        <SidebarLayout.Item
          complementIcon={
            paymentStatus?.lastPaymentStatus === lastPaymentStatus.FAILED || paymentInvalid || licenseInvalid ? (
              <IconAlert />
            ) : null
          }
          exact={false}
          label={t('common:nav.subscription')}
          onClick={handleResetViewPanel}
          onMouseEnter={() => {
            if (!location.pathname.startsWith('/subscription')) handleMouseEnter('subscription');
          }}
          onMouseLeave={() => {
            if (!location.pathname.startsWith('/subscription')) handleMouseLeave('subscription');
          }}
          openMenu={openMdDrawer || openDrawer}
          startIcon={
            <Subscription
              alert={paymentStatus?.lastPaymentStatus === lastPaymentStatus.FAILED || paymentInvalid || licenseInvalid}
              color={changeColorIcon?.subscription ? theme.palette.secondary.main : theme.palette.common.white}
              size={16}
            />
          }
          to="/subscription"
        />
      </>
    );
  }

  function renderMenuWithPermissions() {
    return (
      <>
        <Feature name="backofficeModuleReports">
          <SidebarLayout.Item
            exact={false}
            label={t('common:nav.reports')}
            onClick={handleResetViewPanel}
            onMouseEnter={() => {
              if (!location.pathname.startsWith('/reports')) handleMouseEnter('reports');
            }}
            onMouseLeave={() => {
              if (!location.pathname.startsWith('/reports')) handleMouseLeave('reports');
            }}
            openMenu={openMdDrawer || openDrawer}
            startIcon={
              <Reports
                color={changeColorIcon?.reports ? theme.palette.secondary.main : theme.palette.common.white}
                size={16}
              />
            }
            to="/reports"
          />
        </Feature>
        <Feature name="backofficeModuleMenu">
          <SidebarLayout.Item
            complementIcon={enableValidateItemsAvailability && isPublishMenuError ? <IconAlert size={20} /> : null}
            exact={false}
            label={t('common:nav.menu')}
            onClick={handleResetViewPanel}
            onMouseEnter={() => {
              if (!location?.pathname?.match('/menus')) handleMouseEnter('menu');
            }}
            onMouseLeave={() => {
              if (!location?.pathname?.match('/menus')) handleMouseLeave('menu');
            }}
            openMenu={openMdDrawer || openDrawer}
            startIcon={
              <Menu
                alert={enableValidateItemsAvailability && isPublishMenuError}
                color={changeColorIcon?.menu ? theme.palette.secondary.main : theme.palette.common.white}
                size={16}
              />
            }
            to="/menus/resume"
          />
        </Feature>
        <Feature name="backofficeModuleSettings">
          <SidebarLayout.Item
            exact={false}
            label={t('common:nav.setting')}
            onClick={handleResetViewPanel}
            onMouseEnter={() => {
              if (!location.pathname.startsWith('/settings')) handleMouseEnter('settings');
            }}
            onMouseLeave={() => {
              if (!location.pathname.startsWith('/settings')) handleMouseLeave('settings');
            }}
            openMenu={openMdDrawer || openDrawer}
            startIcon={
              <Settings
                color={changeColorIcon?.settings ? theme.palette.secondary.main : theme.palette.common.white}
                size={16}
              />
            }
            to="/settings"
          />
        </Feature>
        <Feature name="backofficeModuleWorkforce">
          <SidebarLayout.Item
            exact={false}
            label={t('common:nav.personal')}
            onClick={handleResetViewPanel}
            onMouseEnter={() => {
              if (!location.pathname.startsWith('/workforce')) handleMouseEnter('users');
            }}
            onMouseLeave={() => {
              if (!location.pathname.startsWith('/workforce')) handleMouseLeave('users');
            }}
            openMenu={openMdDrawer || openDrawer}
            startIcon={
              <Users
                color={changeColorIcon?.users ? theme.palette.secondary.main : theme.palette.common.white}
                size={16}
              />
            }
            to="/workforce"
          />
        </Feature>
        <Feature name="backofficeModuleInvoicing">
          <SidebarLayout.Item
            exact={false}
            label={t('common:nav.invoicing')}
            onClick={handleResetViewPanel}
            onMouseEnter={() => {
              if (!location.pathname.startsWith('/invoicing')) handleMouseEnter('invoice');
            }}
            onMouseLeave={() => {
              if (!location.pathname.startsWith('/invoicing')) handleMouseLeave('invoice');
            }}
            openMenu={openMdDrawer || openDrawer}
            startIcon={
              <Invoice
                color={changeColorIcon?.invoice ? theme.palette.secondary.main : theme.palette.common.white}
                size={16}
              />
            }
            to="/invoicing"
          />
        </Feature>
        {/* @flag inventories */}
        {flagEnableInventory && (
          <SidebarLayout.Item
            exact={false}
            label={t('common:nav.inventories')}
            onClick={handleResetViewPanel}
            onMouseEnter={() => {
              if (!location.pathname.startsWith('/inventories')) handleMouseEnter('inventories');
            }}
            onMouseLeave={() => {
              if (!location.pathname.startsWith('/inventories')) handleMouseLeave('inventories');
            }}
            openMenu={openMdDrawer || openDrawer}
            startIcon={
              <Inventories
                color={changeColorIcon?.inventories ? theme.palette.secondary.main : theme.palette.common.white}
                size={16}
              />
            }
            to="/inventories"
          />
        )}

        <Feature name="backofficeModuleSubscriptions">
          <SidebarLayout.Item
            complementIcon={
              paymentStatus?.lastPaymentStatus === lastPaymentStatus.FAILED || paymentInvalid ? (
                <IconAlert size={20} />
              ) : null
            }
            exact={false}
            label={t('common:nav.subscription')}
            onClick={handleResetViewPanel}
            onMouseEnter={() => {
              if (!location.pathname.startsWith('/subscription')) handleMouseEnter('subscription');
            }}
            onMouseLeave={() => {
              if (!location.pathname.startsWith('/subscription')) handleMouseLeave('subscription');
            }}
            openMenu={openMdDrawer || openDrawer}
            startIcon={
              <Subscription
                alert={
                  paymentStatus?.lastPaymentStatus === lastPaymentStatus.FAILED || paymentInvalid || licenseInvalid
                }
                color={changeColorIcon?.subscription ? theme.palette.secondary.main : theme.palette.common.white}
                size={16}
              />
            }
            to="/subscription"
          />
        </Feature>
      </>
    );
  }

  return (
    <SidebarLayout>
      <SidebarLayout.Sidebar window={window}>
        {!hideCommandBar && (
          <Hidden implementation="css" smDown>
            <SidebarLayout.Section>
              <SidebarLayout.ButtonCard
                endIcon={<Sparkle />}
                label={t('common:nav.spotlight')}
                onClick={() => window.CommandBar.open()}
                onMouseEnter={() => handleMouseEnter('spotlight')}
                onMouseLeave={() => handleMouseLeave('spotlight')}
                openMenu={openMdDrawer || openDrawer}
                startIcon={
                  openMdDrawer || openDrawer ? (
                    <SearchOutline
                      color={changeColorIcon?.spotlight ? theme.palette.common.white : theme.palette.primary.main}
                      size={16}
                    />
                  ) : (
                    <SparkleWithBackground
                      backgroundColor={changeColorIcon?.spotlight ? '#3C3C3C' : theme.palette.common.white}
                      size={32}
                    />
                  )
                }
                to={location.pathname}
              />
            </SidebarLayout.Section>
          </Hidden>
        )}

        <SidebarLayout.Section>
          {superUserAccess ? renderMenuSuperUser() : renderMenuWithPermissions()}
        </SidebarLayout.Section>

        <SidebarLayout.Divider variant="middle" />

        <SidebarLayout.Section>
          <SidebarLayout.ItemMenu
            endIcon={!hideCommandBar ? <Sparkle /> : null}
            label={t('common:help.title')}
            onClick={!hideCommandBar ? () => window.CommandBar.openHelpHub() : null}
            onMouseEnter={() => handleMouseEnter('help')}
            onMouseLeave={() => handleMouseLeave('help')}
            openMenu={openMdDrawer || openDrawer}
            startIcon={
              <IconHelp
                color={changeColorIcon?.help ? theme.palette.secondary.main : theme.palette.common.white}
                size={16}
              />
            }
            t={t}
          />

          <SidebarLayout.Item
            label={
              !user?.firstName || (!user?.firstName && !user.lastName)
                ? t('common:auth.signOut')
                : `${user.firstName} ${user.lastName}`
            }
            onClick={handleResetViewPanel}
            onMouseEnter={() => handleMouseEnter('logout')}
            onMouseLeave={() => handleMouseLeave('logout')}
            openMenu={openMdDrawer || openDrawer}
            startIcon={
              <LogOutOutline
                color={changeColorIcon?.logout ? theme.palette.secondary.main : theme.palette.common.white}
                size={16}
              />
            }
            to="/signout"
          />
        </SidebarLayout.Section>
        <SidebarLayout.Section
          css={{
            justifyContent: 'end',
            height: '100%',
            '& >li > button > span': { fontSize: '14px', textDecoration: 'underline' },
          }}
        >
          <SidebarLayout.ItemMenu
            data-canny-link
            label={t('common:canny.link')}
            onClick={() => window.open(authenticatedLink, '_blank')}
            openMenu={openMdDrawer || openDrawer}
            t={t}
          />
        </SidebarLayout.Section>
      </SidebarLayout.Sidebar>
      <SidebarLayout.Content>{children}</SidebarLayout.Content>
    </SidebarLayout>
  );
}

AppFrame.propTypes = {
  children: PropTypes.node,
  t: PropTypes.func,
};

export default withTranslation()(AppFrame);
