import { Divider as DividerMui, DividerProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.darkGrey[5],
  },
}));

function Divider(props: DividerProps) {
  const classes = useStyles();

  return <DividerMui classes={classes} {...props} />;
}

export default Divider;
