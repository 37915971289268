import { useContext } from 'react';

import { useActiveLicense } from 'containers/Subscription/hooks';

import SidebarLayoutContext from './SidebarLayoutContext';

function useSidebarLayout() {
  const { data } = useActiveLicense();
  const { openDrawer, setOpenDrawer, openMdDrawer, setOpenMdDrawer, modeDraft, setModeDraft } = useContext(
    SidebarLayoutContext,
  );

  return {
    openDrawer,
    setOpenDrawer,
    openMdDrawer,
    setOpenMdDrawer,
    modeDraft,
    setModeDraft,
    isPublishMenuError: data?.menuPublicationState === 'ERROR',
  };
}

export default useSidebarLayout;
