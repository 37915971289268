import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import useStyles from './styles';

function HelperSkeleton() {
  const classes = useStyles();

  return (
    <Box className={classes.rootTable}>
      {[...Array(4).keys()].map((row) => (
        <Box key={`skeleton-table-resume-row-${row}`} className={classes.rootRow}>
          <Box className={classes.headerRow}>
            <Box>
              <Box mb={1}>
                <Skeleton height={40} variant="text" width={200} />
              </Box>
              <Box className={classes.wrapperTimesRow}>
                {[...Array(2).keys()].map((time) => (
                  <Skeleton key={`skeleton-table-resume-row-time-${time}`} height={20} variant="text" width={120} />
                ))}
              </Box>
            </Box>
            <Box className={classes.contentLeftRow}>
              <Box className={classes.providersRow}>
                {[...Array(4).keys()].map((provider) => (
                  <Skeleton
                    key={`skeleton-table-resume-row-provider-${provider}`}
                    height={30}
                    variant="circular"
                    width={30}
                  />
                ))}
              </Box>
              <Skeleton height={22} variant="rectangular" width={22} />
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default HelperSkeleton;
