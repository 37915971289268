import { Box, Collapse } from '@mui/material';

import Table from '@experimental-components/Table';

import Chevron from 'components/Chevron';

import { AccordionRowProps } from './types';

import StripedTable from '.';

function AccordionRow({ bold = false, openRow, evenStyle, children, accordionContent, ...props }: AccordionRowProps) {
  return (
    <>
      <StripedTable.Row bold={bold} evenStyle={evenStyle} sx={{ cursor: 'pointer' }} {...props}>
        <Box display="flex" justifyContent="space-between" width="100%">
          {children}
        </Box>

        <Box alignItems="center" display="flex" pl={5}>
          <Chevron lighter open={openRow} size={16} />
        </Box>
      </StripedTable.Row>

      <Table.Row>
        <Collapse in={openRow} timeout="auto" unmountOnExit>
          {accordionContent}
        </Collapse>
      </Table.Row>
    </>
  );
}

export default AccordionRow;
