import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import Chevron from '@experimental-components/Chevron';

import { alignType } from './types';

interface Props {
  children?: React.ReactNode;
  paddingCell?: number;
  align?: alignType;
  showChevron?: boolean;
  openChevron?: boolean;
  tab?: 0 | 1 | 2 | 3;
  className?: string;
  onClick?: () => void;
}

const CellRoot = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  fontSize: '14px',
  paddingBlock: '0.5rem',
  wordBreak: 'break-word',
}));

const Cell = ({
  children,
  paddingCell = 2,
  align = 'center',
  showChevron = false,
  openChevron = false,
  tab = 0,
  onClick,
  className,
}: Props) => {
  return (
    <CellRoot
      aria-label="Cell"
      className={className}
      justifySelf={align}
      onClick={onClick}
      padding={paddingCell}
      paddingLeft={`${tab * 30 + 16}px`}
    >
      {showChevron && <Chevron lighter open={openChevron} size={14} />}
      {children}
    </CellRoot>
  );
};

export default Cell;
