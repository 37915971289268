import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

import { useLocation } from 'react-router-dom';

import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';

import DrawerMui from '@mui/material/Drawer';

import MainView from './MainView';
import Step from './Step';
import useStyles from './styles';

function GeneralDrawer({ children, currentStep, open, fullDrawer = false, onClose }) {
  const location = useLocation();
  const { modeDraft } = useSidebarLayout();
  const isPathSubscription = location.pathname.startsWith('/subscription');
  const classes = useStyles({ isModeDraft: modeDraft, fullDrawer, isPathSubscription });

  return (
    <DrawerMui
      anchor="right"
      classes={{ root: classes.rootDrawer, paper: classes.drawerPaper }}
      onClose={onClose || undefined}
      open={open}
      variant={fullDrawer ? 'temporary' : 'persistent'}
    >
      {Children.toArray(children).map((child) => cloneElement(child, { currentStep, fullDrawer }))}
    </DrawerMui>
  );
}

GeneralDrawer.MainView = MainView;
GeneralDrawer.Step = Step;

GeneralDrawer.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
  currentStep: PropTypes.number,
  fullDrawer: PropTypes.bool,
  onClose: PropTypes.func,
};

export default GeneralDrawer;
