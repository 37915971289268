import { useMutation, useQuery } from 'react-query';
import {
  getCookieUser,
  getUserCurrentStore,
  removeAuthToken,
  removeCookieUser,
  removeOrganizationUser,
  removeStoreBackupKey,
  removeStoreDratfKey,
  removeUserCurrentStore,
  removeUserStoresStoreKey,
} from 'utils/auth';

import { removeBrandSelectedLocalStorage } from 'utils/menuMaker';
import history from 'utils/history';

import { getUser, postValidateEmail } from './services';

export function useValidateEmail() {
  return useMutation((email: string) => postValidateEmail({ email }));
}

export function useUser(options?: { storeUuid?: string; userUuid?: string }) {
  const storeUuid = options?.storeUuid || getUserCurrentStore()?.uuid;
  const userUuid = options?.userUuid || getCookieUser()?.uuid;

  return useQuery({
    queryKey: ['user', userUuid],
    queryFn: () => getUser({ userUuid, storeUuid }),
    retry: 1,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      if (error.response && error.response.status === 401) {
        removeAuthToken();
        removeUserStoresStoreKey();
        removeUserCurrentStore();
        removeStoreDratfKey();
        removeStoreBackupKey();
        removeBrandSelectedLocalStorage();
        removeOrganizationUser();
        removeCookieUser();
        history.push({
          pathname: '/logged-out',
        });
      }
    },
  });
}
