import { ChangeEvent } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import RadioColor from './RadioColor';
import useStyles from './styles';
import { Props, colors, descriptionMap } from './utils';

function SelectColor({ entity, onChange, value = colors[0] }: Props) {
  const classes = useStyles({});
  const { t } = useTranslation(['menuMaker']);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange(target.value);
  };

  return (
    <Box>
      <Typography className={classes.title}>{t('common:forms.color')}</Typography>

      <Box mb={4} mt={1}>
        <Typography color="textSecondary" variant="body2">
          {t(descriptionMap[entity])}
        </Typography>
      </Box>

      <Box className={classes.wrapperColors}>
        {colors.map((color) => (
          <RadioColor key={color} checked={value === color} colorIcon={color} onChange={handleChange} value={color} />
        ))}
      </Box>
    </Box>
  );
}

export default SelectColor;
