/* eslint-disable @typescript-eslint/no-explicit-any */
import { getUserCurrentStore } from 'utils/auth';

export const getValidateItemsAvailabilityFlags = (flags: { [key: string]: any }) => {
  const currentStore = getUserCurrentStore()?.uuid;
  let enableValidateItemsAvailability;

  if (typeof flags?.ffValidateItemsAvailabilityByStore === 'boolean') {
    enableValidateItemsAvailability = flags.ffValidateItemsAvailabilityByStore;
  } else {
    enableValidateItemsAvailability = flags.ffValidateItemsAvailabilityByStore?.storeUuidList?.includes(currentStore);
  }

  return { enableValidateItemsAvailability };
};
