import { Organization, Profile, Session, Store } from 'containers/SignIn/types';

import { getCookie, removeCookie, setCookie } from './cookies';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from './localStorage';

const COOKIE_PARROT_AUTH = 'parr-tok';
const USER_CURRENT_STORE_STORAGE_KEY = 'parr-current-store';
const PARROT_STORE_DRAFT = 'parr-store-draft';
const PARROT_STORE_BACKUP = 'parr-store-backup';
const USER_STORES_STORAGE_KEY = 'parr-stores';
const COOKIE_PARROT_USER = 'parr-user';
const USER_ORGANIZATION_KEY = 'parr-organization';

export const setAuthToken = (session: Session) => setCookie(COOKIE_PARROT_AUTH, session);
export const getAuthToken = () => getCookie<Session>(COOKIE_PARROT_AUTH);
export const removeAuthToken = () => removeCookie(COOKIE_PARROT_AUTH);

export const setCookieUser = (profile: Profile) => setCookie(COOKIE_PARROT_USER, profile);
export const getCookieUser = () => getCookie<Profile>(COOKIE_PARROT_USER);
export const removeCookieUser = () => removeCookie(COOKIE_PARROT_USER);

export const setUserCurrentStore = (store: Store) => setLocalStorage(USER_CURRENT_STORE_STORAGE_KEY, store);
export const getUserCurrentStore = () => getLocalStorage<Store>(USER_CURRENT_STORE_STORAGE_KEY);
export const removeUserCurrentStore = () => removeLocalStorage(USER_CURRENT_STORE_STORAGE_KEY);

export const setStoreDraftUser = (store: Store) => setLocalStorage(PARROT_STORE_DRAFT, store);
export const getStoreDraftUser = () => getLocalStorage<Store>(PARROT_STORE_DRAFT);
export const removeStoreDratfKey = () => removeLocalStorage(PARROT_STORE_DRAFT);

export const setStoreBackupUser = (store: Store) => setLocalStorage(PARROT_STORE_BACKUP, store);
export const getStoreBackupUser = () => getLocalStorage<Store>(PARROT_STORE_BACKUP);
export const removeStoreBackupKey = () => removeLocalStorage(PARROT_STORE_BACKUP);

export const setUserStoresStoreKey = (store: Store[]) => setLocalStorage(USER_STORES_STORAGE_KEY, store);
export const getUserStoresStoreKey = () => getLocalStorage<Store[]>(USER_STORES_STORAGE_KEY) || [];
export const removeUserStoresStoreKey = () => removeLocalStorage(USER_STORES_STORAGE_KEY);

export const setOrganizationUser = (store: Organization) => setLocalStorage(USER_ORGANIZATION_KEY, store);
export const getOrganizationUser = () => getLocalStorage<Organization>(USER_ORGANIZATION_KEY);
export const removeOrganizationUser = () => removeLocalStorage(USER_ORGANIZATION_KEY);
