import { Tab as TabMui, TabProps } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';

const TabBase = withStyles((theme: Theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 300,
    minWidth: 0,
    minHeight: 0,
    padding: 0,
    paddingBottom: '14px',
  },

  textColorPrimary: {
    color: theme.palette.common.black,
    '&$selected': {
      color: theme.palette.common.black,
    },
  },

  selected: {
    fontWeight: 500,
  },
}))(TabMui);

function Tab(props: TabProps) {
  return <TabBase disableFocusRipple disableRipple {...props} />;
}

export default Tab;
