import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import MobileStepper from '@experimental-components/MobileStepper';

function StepperStart({ children, activeStep, steps }) {
  return (
    <Box margin="0px auto 0px 0px" p="0rem 4.5rem 0rem 4.5rem">
      <Box alignItems="center" display="flex" maxWidth="16rem" mb="2rem">
        <Box width="50%">
          <MobileStepper
            activeStep={activeStep}
            LinearProgressProps={{
              value: Math.ceil((activeStep / steps) * 100),
            }}
            position="static"
            steps={steps}
            variant="progress"
          />
        </Box>

        <Box
          color="#656565 !important"
          fontSize="0.625rem"
          fontWeight={700}
          letterSpacing="0.04em"
        >{`${activeStep} DE ${steps}`}</Box>
      </Box>

      {children}
    </Box>
  );
}

StepperStart.propTypes = {
  children: PropTypes.node,
  steps: PropTypes.number,
  activeStep: PropTypes.number,
};

export default StepperStart;
