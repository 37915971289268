import React from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Skeleton from '@mui/material/Skeleton';

import PlaceholdersTable from 'components/PlaceholdersTable';

function MenuMakerPublishPlaceholder() {
  return (
    <Box>
      <Box mb="3rem">
        <Box alignItems="center" component="header" display="flex" justifyContent="space-between" px={4}>
          <Skeleton height={70} width={100} />
          <Skeleton height={70} width={200} />
        </Box>
        <Table>
          <TableBody>
            <PlaceholdersTable placeholderCols={3} />
          </TableBody>
        </Table>
      </Box>

      <Box mb="3rem">
        <Box alignItems="center" component="header" display="flex" justifyContent="space-between" px={4}>
          <Skeleton height={80} width={100} />
          <Skeleton height={80} width={200} />
        </Box>
        <Table>
          <TableBody>
            <PlaceholdersTable placeholderCols={3} />
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
}

export default MenuMakerPublishPlaceholder;
