import { useContext } from 'react';
import { useQuery, useInfiniteQuery, useMutation } from 'react-query';
import dayjs from 'dayjs';

import useAmplitude from 'providers/root/AmplitudeProvider/useAmplitude';

import { getReportsFilters } from 'utils/api/baas/reportsFiltersV2';
import {
  getModifiersByProduct,
  getModifiersOptions,
  getModifiersByProductDetail,
  getModifiersOptionsDetail,
  downloadReportModifiers,
} from 'utils/api/baas/reports/modifiers';
import { getPageFromUrl } from 'utils/getPageFromUrl';
import { useGetEnableGeneralFlag } from 'utils/featureFlags/reports';

import { FiltersContext } from './context';

export function useFilters() {
  const context = useContext(FiltersContext);
  if (!context) {
    throw new Error('useFilters must be used within an FiltersProvider');
  }

  return context;
}

export function useReportFilters() {
  return useQuery('reportFilters', getReportsFilters, {
    enabled: false,
  });
}

export function useGetModifiersByProduct() {
  const { filters } = useFilters();
  const { logEvent } = useAmplitude();

  const {
    data,
    isFetched,
    isFetching,
    isError,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    remove,
  } = useInfiniteQuery(
    'modifiersByProduct',
    ({ pageParam = 1 }) =>
      getModifiersByProduct({
        startDate: filters.startDate,
        endDate: filters.endDate,
        brands: filters.brands,
        providers: filters.providers,
        page: pageParam,
      }),
    {
      getNextPageParam: (lastPage, pages) => (lastPage.pagination.next ? pages.length + 1 : undefined),
      enabled: false,
      retry: 1,
    },
  );

  const handleLoadModifiers = () => {
    const startDateTmp = dayjs(filters.startDate);
    const endDateTmp = dayjs(filters.endDate);

    logEvent('@event.$reports.reportViewed', '@@section.modifiers', {
      type: 'items',
      days_viewed: endDateTmp.diff(startDateTmp, 'day') + 1,
    });

    refetch();
  };

  return {
    data,
    loadModifiers: handleLoadModifiers,
    paginate: fetchNextPage,
    dataStatus: {
      fetched: isFetched,
      fetching: isFetching,
      error: isError,
      isFetchingNextPage,
      hasNextPage,
    },
    removeModifiers: remove,
  };
}

export function useGetModifiersByProductDetail(skuItem: string) {
  const { filters } = useFilters();

  const { data, isFetching, isFetched, isError, refetch, remove } = useQuery(
    ['modifiersByProductDetail', skuItem],
    () =>
      getModifiersByProductDetail({
        skuItem,
        startDate: filters.startDate,
        endDate: filters.endDate,
        brands: filters.brands,
        providers: filters.providers,
      }),
    { enabled: false, retry: 1 },
  );

  return {
    data,
    loadModifiersDetail: refetch,
    dataStatus: {
      fetched: isFetched,
      fetching: isFetching,
      error: isError,
    },
    removeModifiersDetail: remove,
  };
}

export function useGetModifiersOptionInfinity() {
  const { filters } = useFilters();
  const { logEvent } = useAmplitude();

  return useInfiniteQuery(
    'modifiersOptionsInfinity',
    ({ pageParam = 1 }) => {
      if (pageParam === 1) {
        const startDateTmp = dayjs(filters.startDate);
        const endDateTmp = dayjs(filters.endDate);

        logEvent('@event.$reports.reportViewed', '@@section.modifiers', {
          type: 'options',
          days_viewed: endDateTmp.diff(startDateTmp, 'day') + 1,
        });
      }

      return getModifiersOptions({
        startDate: filters.startDate,
        endDate: filters.endDate,
        brands: filters.brands,
        providers: filters.providers,
        page: pageParam,
        pageSize: 10,
      });
    },
    {
      getNextPageParam: (lastPage) => getPageFromUrl(lastPage.pagination.next),
      enabled: false,
      retry: 1,
    },
  );
}

export function useGetModifiersOptionDetail(skuItem: string) {
  const { filters } = useFilters();

  const { data, isFetching, isFetched, isError, refetch, remove } = useQuery(
    ['modifiersOptionsDetails', skuItem],
    () =>
      getModifiersOptionsDetail({
        skuItem,
        startDate: filters.startDate,
        endDate: filters.endDate,
        brands: filters.brands,
        providers: filters.providers,
      }),
    { enabled: false, retry: 1 },
  );

  return {
    data: data?.data,
    loadModifiersOptionDetail: refetch,
    dataStatus: {
      fetched: isFetched,
      fetching: isFetching,
      error: isError,
    },
    removeModifiersOptionDetail: remove,
  };
}

export function useDownloadReportModifiers() {
  const { filters } = useFilters();
  const { logEvent } = useAmplitude();
  const enableWsOptimized = useGetEnableGeneralFlag('ffEnableWsOptExportModifierReport');

  return useMutation(
    (reportType?: string) =>
      downloadReportModifiers({
        startDate: filters.startDate,
        endDate: filters.endDate,
        report: reportType ?? 'PRODUCT_MODIFIER_SALES',
        brands: filters.brands,
        providers: filters.providers,
        enableWsOptimized,
      }),
    {
      onSuccess: (_, reportType) => {
        const startDateTmp = dayjs(filters.startDate);
        const endDateTmp = dayjs(filters.endDate);

        const reportTypeSegment = (() => {
          switch (reportType) {
            case 'PRODUCT_MODIFIER_SALES':
              return 'items';
            case 'MODIFIER_SALES':
              return 'options';
            default:
              return 'items';
          }
        })();

        logEvent('@event.$reports.reportExported', '@@section.modifiers', {
          type: reportTypeSegment,
          days_viewed: endDateTmp.diff(startDateTmp, 'day') + 1,
        });
      },
    },
  );
}
