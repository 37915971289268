import clsx, { ClassDictionary } from 'clsx';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

import IconButton from '@design-system/IconButton';
import ChevronDown from '@experimental-components/IconsComponents/ChevronDown';

import useStyles from './styles';

interface Props {
  children: React.ReactNode;
  expanded?: boolean;
  disabledTitle?: boolean;
  title: string;
  onClick: () => void;
  iconSize?: number;
  headerStyle?: ClassDictionary;
  actionComponent: React.ReactNode;
}

function Accordion({
  actionComponent: actionComponentProp,
  children,
  expanded = false,
  disabledTitle = false,
  title,
  onClick,
  iconSize = 15,
  headerStyle = {},
}: Props) {
  const classes = useStyles();
  return (
    <Box width="100%">
      <Box className={clsx(classes.accordionHeader, headerStyle)}>
        {actionComponentProp && <>{actionComponentProp}</>}

        <Box className={classes.accordionAreaAction} onClick={onClick}>
          <Box className={clsx(classes.labelSection, { [classes.labeDisabled]: disabledTitle })} component="span">
            {title}
          </Box>

          <IconButton size="large">
            <ChevronDown className={clsx({ [classes.endIconRotate]: expanded })} color="#000" lighter size={iconSize} />
          </IconButton>
        </Box>
      </Box>

      <Collapse in={expanded}>{children}</Collapse>
    </Box>
  );
}

export default Accordion;
