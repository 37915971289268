import Dialog, { DialogProps } from '@mui/material/Dialog';
import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

import PaperComponent from './PaperComponent';
import DialogLayoutContent from './Content';
import DialogLayoutActions from './Actions';
import DialogLayoutTitle from './Title';

const useStyles = makeStyles((theme: Theme) => ({
  paperWidthMd: {
    borderRadius: theme.shape.borderRadius,
    maxWidth: ({ maxWidth }: { maxWidth: number }) => maxWidth,
  },
}));

interface Props extends DialogProps {
  open: boolean;
  children: React.ReactNode;
  maxModalWidth?: number;
}

function DialogLayout({ open, children, maxModalWidth = 802, ...others }: Props) {
  const classes = useStyles({ maxWidth: maxModalWidth });

  return (
    <Dialog
      aria-labelledby="dialogConfirm"
      classes={{ paperWidthMd: classes.paperWidthMd }}
      fullWidth
      maxWidth="md"
      open={open}
      PaperComponent={PaperComponent}
      {...others}
    >
      {children}
    </Dialog>
  );
}

DialogLayout.Title = DialogLayoutTitle;
DialogLayout.Content = DialogLayoutContent;
DialogLayout.Actions = DialogLayoutActions;

export default DialogLayout;
