import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';

interface PropsTitle {
  children: JSX.Element;
}

interface Props {
  children: React.ReactNode;
}

function Title({ children }: PropsTitle) {
  return children;
}

function ProductOverrideSkeleton({ children }: Props) {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>{children}</Grid>

      <Grid item>
        <Skeleton height={100} variant="rectangular" width="100%" />
      </Grid>

      <Grid item>
        <Box columnGap={3} display="flex">
          <Skeleton height={80} variant="rectangular" width={80} />

          <Box width="80%">
            <Skeleton height={15} variant="rectangular" width={150} />
            <Skeleton height={75} variant="text" width="100%" />
          </Box>
        </Box>
      </Grid>

      <Grid item>
        <Skeleton height={25} variant="text" width={100} />
        <Skeleton height={25} variant="text" width={100} />
      </Grid>

      <Grid item>
        <Skeleton height={25} variant="text" width={150} />
        <Skeleton height={42} variant="rectangular" width="100%" />
      </Grid>

      <Grid item>
        <Skeleton height={25} variant="text" width={150} />

        <Box display="grid" gap={4.5} gridTemplateColumns="1.5fr 1fr" mt={1}>
          <Box display="flex" flexDirection="column" gap={2.5}>
            <Skeleton height={80} variant="rectangular" width="100%" />
            <Skeleton height={40} variant="rectangular" width="100%" />
          </Box>

          <Skeleton height={150} variant="rectangular" width="100%" />
        </Box>
      </Grid>

      <Box my={4} px={2}>
        <Divider />
      </Box>

      <Grid item>
        <Skeleton height={25} variant="text" width={150} />
        <Skeleton height={15} variant="text" width={350} />
        <Skeleton height={70} variant="text" width="100%" />
      </Grid>

      <Grid item>
        <Box my={3}>
          <Skeleton height={42} variant="rectangular" width="100%" />
        </Box>
      </Grid>
    </Grid>
  );
}

ProductOverrideSkeleton.Title = Title;

export default ProductOverrideSkeleton;
