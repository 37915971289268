/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import makeStyles from '@mui/styles/makeStyles';

import ReportsTable from 'components/ReportsTable';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > :first-child': {
      paddingLeft: theme.spacing(4),
    },
    '& > :last-child': {
      paddingRight: theme.spacing(4),
    },
  },
}));

function PlaceholdersTable({ placeholderRows, placeholderCols, width, heightCol }) {
  const classes = useStyles();

  return (
    <>
      {[...Array(placeholderRows)].map((row, i) => (
        <ReportsTable.Row key={`tr-${i}`} classes={{ root: classes.root }}>
          {[...Array(placeholderCols)].map((col, j) => (
            <ReportsTable.Cell key={`tr-${i}-td-${j}`}>
              <Skeleton height={heightCol} width={width} />
            </ReportsTable.Cell>
          ))}
        </ReportsTable.Row>
      ))}
    </>
  );
}

PlaceholdersTable.defaultProps = {
  placeholderRows: 3,
  placeholderCols: 3,
  width: '100%',
  heightCol: 20,
};

PlaceholdersTable.propTypes = {
  placeholderRows: PropTypes.number,
  placeholderCols: PropTypes.number,
  width: PropTypes.string,
  heightCol: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PlaceholdersTable;
