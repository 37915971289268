import React from 'react';
import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Button, { ButtonProps } from '@mui/material/Button';
import type { Theme } from '@mui/material/styles';

const ButtonPlusBase = withStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(16),
    padding: `${theme.typography.pxToRem(12)}`,
    border: 'none',
    lineHeight: theme.typography.pxToRem(18),
    height: (props: { height?: number }) => (props.height ? props.height : theme.typography.pxToRem(42)),
    minWidth: theme.typography.pxToRem(129),
    backgroundColor: theme.palette.darkGrey[2],
    color: theme.palette.common.black,

    '&:hover': {
      backgroundColor: theme.palette.darkGrey[5],
      boxShadow: 'none',
    },

    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.darkGrey[2],
    },

    '&:focus': {
      border: 'none',
    },

    '&$disabled': {
      color: theme.palette.darkGrey[50],
    },
  },

  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.darkGrey[50],
  },

  sizeSmall: {
    padding: 16,
  },
}))(Button);

interface Props extends ButtonProps {
  disabled?: boolean;
  icon?: React.ReactNode;
  height?: number;
}

function ButtonScaleGray({ disabled = false, height, icon = <></>, ...others }: Props) {
  const theme = useTheme();
  const IconButtonComponent = React.cloneElement(<>{icon}</>, {
    color: disabled ? theme.palette.darkGrey[50] : theme.palette.common.black,
  });

  return (
    <ButtonPlusBase
      disabled={disabled}
      disableElevation
      disableRipple
      height={height}
      startIcon={IconButtonComponent}
      {...others}
    />
  );
}

export default ButtonScaleGray;
