import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  rowCell: {
    background: theme.palette.darkGrey[2],
    borderTop: `1px solid ${theme.palette.darkGrey[15]}`,
    borderBottom: `1px solid ${theme.palette.darkGrey[15]}`,
    '&:first-child': {
      width: theme.typography.pxToRem(32),
      paddingRight: 0,
    },
    '&:nth-child(2)': {
      width: theme.typography.pxToRem(62),
      paddingLeft: 0,
      paddingRight: theme.typography.pxToRem(14),
    },
    '&:nth-child(3)': {
      paddingLeft: 0,
    },
  },
  iconExpand: {
    width: theme.typography.pxToRem(32),
  },
  rowSecondaryCell: {
    borderTop: `1px solid ${theme.palette.darkGrey[15]}`,
    borderBottom: `1px solid ${theme.palette.darkGrey[15]}`,
    '&:nth-child(3)': {
      paddingLeft: 0,
    },
  },
  photoContainer: {
    width: theme.typography.pxToRem(62),
    height: theme.typography.pxToRem(62),
    '& img': {
      borderRadius: theme.typography.pxToRem(4),
      maxWidth: '100%',
      height: '100%',
    },
  },
  placeholderIcon: {
    width: '100%',
    height: '100%',
  },
}));

export default useStyles;
