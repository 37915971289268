import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  subtitles: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
  },
}));

export default useStyles;
