import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import TableMui from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainerMui from '@mui/material/TableContainer';
import TableHeadMui from '@mui/material/TableHead';
import TableRowMui from '@mui/material/TableRow';

import withStyles from '@mui/styles/withStyles';

import TableHeadCell from './TableHeadCell';
import TableCell from './TableCell';
import TableBigCell from './TableBigCell';

const TableHead = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
}))(TableHeadMui);

const SortableBody = SortableContainer(({ children }) => {
  return <TableBody>{children}</TableBody>;
});

const TableContainer = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
}))(TableContainerMui);

const TableRow = withStyles((theme) => ({
  root: {
    '& > :first-child': {
      paddingLeft: theme.spacing(4),
    },
    '& > :last-child': {
      paddingRight: theme.spacing(4),
    },
  },
  hover: {
    cursor: (props) => (props.onClick ? 'pointer' : 'default'),
  },
}))(TableRowMui);

const TableRowStreaky = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.darkGrey[2],
    },
  },
}))(TableRow);

const SortableRow = SortableElement((props) => <TableRow {...props} />);
function Table({ children, className = {}, ...others }) {
  return (
    <TableContainer className={className}>
      <TableMui {...others}>{children}</TableMui>
    </TableContainer>
  );
}

Table.HeadCell = TableHeadCell;
Table.Head = TableHead;
Table.SortableRow = SortableRow;
Table.Row = TableRow;
Table.Body = TableBody;
Table.BodyDraggable = SortableBody;
Table.Cell = TableCell;
Table.BigCell = TableBigCell;
Table.RowStreaky = TableRowStreaky;

Table.propTypes = {
  ...Table.propTypes,
};

export default Table;
