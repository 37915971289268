import type { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

interface propsStyles {
  isOpenMenu: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  navLinkLabel: {
    width: '100%',
    margin: '0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  buttonCard: {
    backgroundColor: (props: propsStyles) =>
      props.isOpenMenu ? theme.palette.background.default : theme.palette.primary.main,
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 14,
    padding: '8px 12px',
    lineHeight: 1.5,
    height: theme.typography.pxToRem(42),
    width: '100%',
    maxWidth: '14rem',
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightRegular,
    textDecoration: 'none',
    margin: '0',

    '&:hover': {
      border: (props: propsStyles) => `1px solid ${props.isOpenMenu ? '#616161' : theme.palette.primary.main}`,
      backgroundColor: (props: propsStyles) => (props.isOpenMenu ? '#3C3C3C' : theme.palette.primary.main),
      boxShadow: 'none',
      color: theme.palette.background.default,
    },

    '&:active': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },

  buttonCardCollapseMenu: {
    padding: 0,
    minWidth: '50px',
    border: 'none',
  },

  startIcon: {
    marginRight: theme.spacing(1),
  },
  startIconV2: {
    marginRight: 0,
  },
}));

export default useStyles;
