/* eslint-disable @typescript-eslint/no-explicit-any */
import MaskedInput from 'react-text-mask';

import createAutoCorrectedDatePipe from './dataPipe';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextTimeMask(props: TextMaskCustomProps) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        if (inputRef) inputRef(ref ? ref.inputElement : null);
      }}
      keepCharPositions
      mask={[/[0-2]/, /[0-9]/, ':', /[0-6]/, /[0-9]/]}
      pipe={createAutoCorrectedDatePipe('HH:MM')}
      showMask
    />
  );
}

export default TextTimeMask;
