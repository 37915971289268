/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

function SkeletonHelper({ type }) {
  if (type === 'start') {
    return (
      <Box margin="0px auto 0px 0px" maxWidth="25rem" p="1rem 1rem 1rem 4.5rem">
        <Box alignItems="center" display="flex" mb="2rem">
          <Box width="50%">
            <Skeleton height={80} variant="rectangular" width={200} />
          </Box>

          <Skeleton height={80} variant="text" width={200} />
          <Skeleton height={80} variant="text" width={200} />
        </Box>
      </Box>
    );
  }

  if (type === 'between') {
    return (
      <Box>
        {[1, 2].map((item) => (
          <Box key={item} mb={1}>
            <Skeleton height={60} variant="rectangular" />
            <Box height={150} width="100%">
              {[1, 2, 3, 4].map((subItem) => (
                <Box key={`${item}_${subItem}`} pt={2} px={4}>
                  <Skeleton height={20} variant="rectangular" />
                </Box>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    );
  }

  return null;
}

SkeletonHelper.propTypes = {
  type: PropTypes.oneOf(['start', 'between', 'end']),
};

export default SkeletonHelper;
