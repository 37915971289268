import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';
import ButtonAlert from '@design-system/ButtonAlert';
import ButtonOutlined from '@design-system/ButtonOutlined';
import Popover, { PopoverProps } from '@mui/material/Popover';
import Box from '@mui/material/Box';
import { CSSProperties } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.typography.pxToRem(30),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.typography.pxToRem(352),
    width: '100%',
    boxShadow: `0px 8px 40px ${theme.palette.darkGrey[5]}`,
  },
  section: {
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(3),
    },
  },
}));

interface Props {
  isDisabled?: boolean;
  anchorEl?: PopoverProps['anchorEl'];
  id: string;
  labelAccept: string;
  labelCancel: string;
  loading?: boolean;
  message?: string;
  onAccept: React.MouseEventHandler<HTMLButtonElement>;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  open: boolean;
  title: string;
}

const oStyle: CSSProperties = {
  textAlign: 'center',
};

function ConfirmDialog({
  isDisabled = false,
  anchorEl,
  id,
  labelAccept,
  labelCancel,
  loading,
  message = '',
  onAccept,
  onCancel,
  open,
  title,
}: Props) {
  const classes = useStyles();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      classes={{ paper: classes.paper }}
      id={id}
      open={open}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <Box>
        <Box component="h6" fontSize={18} fontWeight="fontWeightMedium" m={0} style={oStyle}>
          {title}
        </Box>
      </Box>

      <Box mt={2}>
        {message && (
          <Box color="text.secondary" component="p" fontSize={14} fontWeight="fontWeightRegular" m={0} style={oStyle}>
            {message}
          </Box>
        )}

        <Box alignItems="center" className={classes.section} display="flex" mt={4}>
          <ButtonOutlined disabled={isDisabled} onClick={onCancel}>
            {labelCancel}
          </ButtonOutlined>

          <ButtonAlert disabled={isDisabled} loading={loading} onClick={onAccept}>
            {labelAccept}
          </ButtonAlert>
        </Box>
      </Box>
    </Popover>
  );
}

export default ConfirmDialog;
