import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

import ButtonPrimary from '@design-system/ButtonPrimary';
import ButtonOutlined from '@design-system/ButtonOutlined';

import ModalConfirmContent from './ModalConfirmContent';
import useStyles from './styles';

function ModalConfirm({
  open,
  title,
  loading,
  message,
  onAccept,
  onCancel,
  labelAccept,
  labelCancel,
  buttonActionType = 'button',
  formNameForAction,
  ...others
}) {
  const classes = useStyles();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      data-testid="modalConfirm"
      fullWidth
      maxWidth="md"
      open={open}
      {...others}
    >
      <DialogTitle align="center" className={classes.dialogTitle} id="alert-dialog-title">
        {title}
      </DialogTitle>

      <DialogContent>
        <Box display="flex" justifyContent="center">
          <DialogContentText align="center" className={classes.dialogContentText} id="alert-dialog-description">
            {message}
          </DialogContentText>
        </Box>
      </DialogContent>

      <DialogActions align="center" className={classes.actions}>
        <ButtonOutlined
          className={classes.actionButton}
          data-testid="btnModalConfirmCancel"
          disabled={loading}
          onClick={onCancel}
        >
          {labelCancel}
        </ButtonOutlined>

        <ButtonPrimary
          className={classes.actionButton}
          data-testid="btnModalConfirmAccept"
          disabled={loading}
          form={formNameForAction}
          loading={loading}
          onClick={onAccept}
          type={buttonActionType}
        >
          {labelAccept}
        </ButtonPrimary>
      </DialogActions>
    </Dialog>
  );
}

ModalConfirm.Content = ModalConfirmContent;

ModalConfirm.propTypes = {
  loading: PropTypes.bool,
  open: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  labelAccept: PropTypes.string,
  labelCancel: PropTypes.string,
};

export default ModalConfirm;
