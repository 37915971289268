/* eslint-disable react/no-array-index-key */
import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import ReportsTable from '.';

interface Props {
  placeholderRows?: number;
  placeholderCols?: number;
  width?: string | number;
  heightCol?: string | number;
  withHeaders?: boolean;
}

function PlaceholdersTable({
  placeholderRows = 3,
  placeholderCols = 3,
  width = '100%',
  heightCol = 20,
  withHeaders,
}: Props) {
  const bodySkeleton = () => (
    <>
      {[...Array(placeholderRows)].map((row, i) => (
        <ReportsTable.Row key={`tr-${i}`}>
          {[...Array(placeholderCols)].map((col, j) => (
            <ReportsTable.Cell key={`tr-${i}-td-${j}`}>
              <Skeleton height={heightCol} width={width} />
            </ReportsTable.Cell>
          ))}
        </ReportsTable.Row>
      ))}
    </>
  );

  if (withHeaders) {
    return (
      <Box boxShadow="0px -6px 20px -6px rgba(164, 170, 186, 0.15)">
        <ReportsTable>
          <ReportsTable.Head>
            <ReportsTable.Row>
              {[...Array(placeholderCols).keys()].map((item) => (
                <ReportsTable.HeadCell key={item} align="left">
                  <Skeleton height={heightCol} variant="text" width="30%" />
                </ReportsTable.HeadCell>
              ))}
            </ReportsTable.Row>
          </ReportsTable.Head>

          <ReportsTable.Body>{bodySkeleton()}</ReportsTable.Body>
        </ReportsTable>
      </Box>
    );
  }

  return bodySkeleton();
}

export default PlaceholdersTable;
