import React from 'react';

import Box, { BoxProps } from '@mui/material/Box';

import useStyles from './styles';

interface Props extends BoxProps {
  children: React.ReactNode;
}

export const OptionsTabs = (props: BoxProps) => <Box alignItems="center" display="flex" {...props} />;
export const Button = (props: BoxProps) => (
  <Box alignItems="center" display="flex" gap={2} justifyContent="space-between" mt={-1.5} {...props} />
);

function ContentTabsReports({ children, ...others }: Props) {
  const classes = useStyles();
  return (
    <Box className={classes.root} {...others}>
      {children}
    </Box>
  );
}

ContentTabsReports.Options = OptionsTabs;
ContentTabsReports.ButtonExport = Button;

export default ContentTabsReports;
