import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(20),
  },

  subtitles: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
  },

  subtitlesThree: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(1),
  },

  messageEditionProduct: {
    padding: '1rem',
    background: theme.palette.darkGrey[2],
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.darkGrey.main,
    marginBottom: theme.spacing(3),
  },

  tabs: {
    boxShadow: `0px 1px 0px 0px ${theme.palette.darkGrey[5]}`,
    paddingInline: '24px',
    paddingTop: '18px',
  },

  twoCols: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(2.5),
    rowGap: theme.spacing(4),
  },
  boxesColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    rowGap: '4px',
  },
  baseTextArea: {
    padding: 0,
  },
  textArea: {
    width: '100%',
    height: `${theme.typography.pxToRem(208)} !important`,
    padding: '1rem',
    resize: 'none',
  },
  descriptionPhoto: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.darkGrey.main,
  },
  toggleButtonsGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    columnGap: theme.spacing(4),
    '&>button': {
      height: `${theme.typography.pxToRem(56)} !important`,
      border: `1px solid ${theme.palette.darkGrey[15]} !important`,
      borderRadius: `${theme.shape.borderRadius}px !important`,
      fontSize: theme.typography.pxToRem(14),
      color: theme.palette.text.secondary,
      lineHeight: 1.3,
    },
  },
  divider: {
    border: `1px solid ${theme.palette.darkGrey[5]}`,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(5),
  },
  placeholderSelected: {
    color: theme.palette.darkGrey[50],
    fontSize: theme.typography.pxToRem(14),
  },
  skeletonCols: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(3),
  },
  skeletonToggle: {
    display: 'flex',
    columnGap: theme.spacing(3),
  },
  switchButton: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginLeft: '-8px',
  },
  descriptionTaxes: {
    maxWidth: theme.typography.pxToRem(290),
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
  },
  groupRadio: {
    textAlign: 'center',
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(4),
    },
  },
  labelRadio: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
  },
  labelKitchen: {
    color: theme.palette.darkGrey.main,
    fontSize: 14,
    fontWeight: theme.typography.fontWeightRegular,
    lineHeight: theme.typography.pxToRem(22),
  },
  contentSelected: {
    color: theme.palette.darkGrey[50],
    fontSize: theme.typography.pxToRem(14),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  wrapperMultiSelect: {
    overflow: 'hidden',
  },

  hideElements: {
    display: 'none',
  },
  descriptionModifiers: {
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  fontMedium: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  contentSelectedTypeProductSat: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  headOverridePrices: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: theme.spacing(3),
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },
  contentItemMenuOverride: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  disabledOverridePrices: {
    pointerEvents: 'none',
    opacity: 0.3,
  },
  itemTextOverride: {
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: '1rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 2,
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  errorDuplicateSku: {
    color: theme.palette.error.main,
    fontSize: theme.typography.pxToRem(12),
    marginTop: theme.spacing(2),
  },

  disableSection: {
    display: 'none',
  },

  customInput: {
    backgroundColor: `${theme.palette.darkGrey[5]} !important`,

    '& input': {
      background: 'white',
      height: '42px',
    },
    '& label': {
      background: theme.palette.darkGrey[5],
    },
  },

  satOptional: {
    color: theme.palette.darkGrey[50],
    fontSize: theme.typography.pxToRem(16),
    paddingBottom: theme.spacing(3),

    '& > strong': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

export default useStyles;
