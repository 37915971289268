import { forwardRef, Ref } from 'react';
import { InputBase, InputBaseProps, InputLabel, FormControl, FormHelperText, PropertyProps, Box } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';

const InputRoot = withStyles((theme: Theme) => {
  const placeholder = {
    color: (props: PropertyProps) =>
      props.disabled || props.readOnly ? theme.palette.darkGrey[15] : theme.palette.darkGrey[50],
    opacity: 1,
  };

  const getBorder = (props: PropertyProps) => {
    if (props.ghost) {
      return '0';
    }

    if (props?.disabled || props?.readOnly) {
      return `1px solid ${theme.palette.darkGrey[15]}`;
    }

    if (props?.error) {
      return `1px solid ${theme.palette.error.main}`;
    }

    return `1px solid ${theme.palette.common.black}`;
  };

  const getBorderColor = (props: PropertyProps) => {
    if (props.ghost) {
      return '0';
    }

    if (props?.disabled || props?.readOnly) {
      return `1px solid ${theme.palette.darkGrey[15]}`;
    }

    if (props?.error) {
      return `1px solid ${theme.palette.error.main}`;
    }

    return `1px solid ${theme.palette.darkGrey[50]}`;
  };

  const getBackgroundColor = (props: PropertyProps) => {
    if (props?.disabled || props?.readOnly) {
      return theme.palette.darkGrey[2];
    }

    if (props?.error) {
      return theme.palette.error.light;
    }

    return theme.palette.common.white;
  };

  const getColor = (props: PropertyProps) => {
    if (props?.disabled) {
      return theme.palette.darkGrey[15];
    }
    if (props?.readOnly) {
      return theme.palette.darkGrey[50];
    }
    if (props?.error) {
      return theme.palette.error.main;
    }
    return theme.palette.primary.main;
  };

  const getHeight = (props: PropertyProps) => {
    if (props.multiline) {
      return '80px !important';
    }
    if (props.ghost) {
      return '54px !important';
    }
    return '42px !important';
  };

  return {
    root: {
      'label + &': {
        marginTop: theme.spacing(1),
      },
    },
    input: {
      borderRadius: theme.shape.borderRadius,
      position: 'relative',
      backgroundColor: (props) => getBackgroundColor(props),
      border: (props) => getBorderColor(props),
      color: (props) => getColor(props),
      fontSize: '14px',
      fontWeight: 400,
      height: (props: PropertyProps) => getHeight(props),
      boxSizing: 'border-box',
      padding: '14px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&::-webkit-input-placeholder': placeholder,

      '&:hover': {
        border: (props: PropertyProps) => getBorder(props),
      },

      '&:focus': {
        border: (props: PropertyProps) => getBorder(props),
        borderRadius: theme.shape.borderRadius,
      },
    },
  };
})(InputBase);

const InputLabelRoot = withStyles((theme: Theme) => ({
  root: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.common.black,
    position: 'relative',
    lineHeight: 1,
  },

  shrink: {
    transform: 'translate(0, 1.5px) scale(1)',
    transformOrigin: 'top left',
  },

  disabled: {
    color: theme.palette.darkGrey[50],
  },
}))(InputLabel);

const FormHelperTextRoot = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1.2,
  },
}))(FormHelperText);

interface Props extends InputBaseProps {
  label?: string;
  subLabel?: string;
  helperText?: string;
  errorMsg?: string;
  hideLabel?: boolean;
  hideLabelHelperText?: boolean;
  readonly?: boolean;
  ghost?: boolean;
  maxLength?: number;
}

const TextField = forwardRef(function TextField(
  {
    id,
    error,
    fullWidth,
    label,
    subLabel,
    helperText,
    errorMsg,
    disabled,
    readOnly = false,
    required,
    hideLabel,
    hideLabelHelperText,
    ...props
  }: Props,
  ref: Ref<HTMLInputElement>,
) {
  return (
    <FormControl disabled={disabled} error={error} fullWidth={fullWidth} required={required} variant="standard">
      {!hideLabel && (
        <InputLabelRoot htmlFor={id} shrink>
          {label}
        </InputLabelRoot>
      )}
      {subLabel && (
        <Box color={error ? '#DF130E' : '#47465F'} fontSize={14} fontWeight="400" mb={2} mt={1}>
          {subLabel}
        </Box>
      )}
      <InputRoot ref={ref} disabled={disabled} error={error} readOnly={readOnly} {...props} />
      {!hideLabelHelperText && <FormHelperTextRoot id={`${id}HelperText`}>{errorMsg || helperText}</FormHelperTextRoot>}
    </FormControl>
  );
});

export default TextField;
