import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: ({ openDrawer }) => (openDrawer ? 'repeat(3, 1fr)' : 'repeat(5, 1fr)'),
    alignItems: 'end',
    gap: theme.spacing(3),
    marginBottom: theme.spacing(2),
    paddingInline: theme.spacing(4),

    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: () => 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: () => 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: () => '1fr',
    },
  },

  actions: {
    gridColumn: ({ openDrawer }) => (openDrawer ? '3' : '4 / 2 span'),
    display: 'flex',
    gap: theme.spacing(3),
    justifyContent: 'end',

    [theme.breakpoints.down('lg')]: {
      gridColumn: () => '3',
    },
    [theme.breakpoints.down('md')]: {
      gridColumn: () => '2',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: () => '1',
    },
  },

  contentSelected: {
    color: theme.palette.darkGrey.main,
    fontSize: theme.typography.pxToRem(14),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  middlePrimaryButton: {
    alignSelf: 'baseline',
  },

  optionAll: {
    paddingBlock: theme.spacing(1.5),
  },
}));

export default useStyles;
