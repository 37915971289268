import Grid from '@mui/material/Grid';

function Item({ ...others }) {
  return <Grid item {...others} />;
}

Item.propTypes = {
  ...Grid.propTypes,
};

export default Item;
