import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  radioGroups: {
    flexDirection: 'row',
    '& > label': { marginRight: theme.spacing(5) },
  },
  helperText: {
    marginTop: theme.spacing(2),
    fontSize: 14,
    maxWidth: theme.spacing(39),
  },
}));

export default useStyles;
