/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import camelCase from 'lodash/camelCase';
import { useHistory, useLocation } from 'react-router-dom';
import { stringify } from 'query-string';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';

import IconButton from '@design-system/IconButton';
import useNotification from '@design-system/Notification/useNotification';
import IconNotImage from '@experimental-components/IconsComponents/NotImage';
import EyeOnOutline from '@experimental-components/IconsComponents/EyeOnOutline';
import EyeOffOutline from '@experimental-components/IconsComponents/EyeOffOutline';

import { useOverrideMenuProduct } from 'providers/menuMaker/MenuProducts/hooks';
import { useMenus } from 'providers/menuMaker/Menus';

import InputOverridePrice from 'components/InputOverridePrice';
import PriceFormatField from 'components/PriceFormatField';

import { drawerActions, drawerEntities, getQueryParamsMenuMaker } from 'utils/drawerMenuMaker';
import { statusType } from 'utils/products';
import { getUnitWeight, isSaleByWeight } from 'utils/products/soldByGrammage';

import useStyles from './styles';

function RowProduct({ product, menu, hasProductOverrides, openDrawer, t }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { syncProductOverride } = useMenus();

  const overrideProductMutation = useOverrideMenuProduct();
  const { setNotification } = useNotification();
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const isDisabledRow = selectedAvailability === statusType.STATUS_UNAVAILABLE;

  const { entity } = getQueryParamsMenuMaker(location);

  const hideOverrideColumn = (() => {
    const entityCheck = entity === drawerEntities.PRODUCT_OVERRIDE;
    return (openDrawer && entityCheck) ?? false;
  })();

  const unitWeight = useMemo(() => {
    return getUnitWeight({ serving: product?.serving, servingUnit: product?.servingUnit });
  }, [product?.serving, product?.servingUnit]);

  useEffect(() => {
    setSelectedAvailability(initAvailabilityState);
  }, [product?.override?.availability]);

  useEffect(() => {
    if (overrideProductMutation.isSuccess) {
      const newAvailability =
        selectedAvailability === statusType.STATUS_AVAILABLE
          ? statusType.STATUS_UNAVAILABLE
          : statusType.STATUS_AVAILABLE;

      handleSyncProductOverride({ availability: newAvailability });
      setSelectedAvailability(newAvailability);
      setNotification(t('menuMaker:messages.changeAvailabilityProductSuccess'), { variant: 'success' });
    }

    if (overrideProductMutation.isError) {
      setNotification(t('menuMaker:messages.changeAvailabilityProductError'), { variant: 'error' });
    }
  }, [overrideProductMutation.isSuccess, overrideProductMutation.isError]);

  const handleEditProduct = (productUuid, saleByWeight = false) => {
    const query = {
      action: drawerActions.EDIT,
      entity: hasProductOverrides ? drawerEntities.PRODUCT_OVERRIDE : drawerEntities.PRODUCT,
      uuid: productUuid,
      menuOfProduct: menu?.uuid,
      isSoldByWeight: saleByWeight,
    };

    history.replace({ search: stringify(query) });
  };

  function initAvailabilityState() {
    let initValue;

    if (product?.override?.availability === statusType.STATUS_AVAILABLE) {
      initValue = statusType.STATUS_AVAILABLE;
    } else if (product?.override?.availability === statusType.STATUS_UNAVAILABLE) {
      initValue = statusType.STATUS_UNAVAILABLE;
    } else {
      initValue = statusType.STATUS_AVAILABLE;
    }

    return initValue;
  }

  const handleOverrideAvailability = () => {
    if (selectedAvailability === statusType.STATUS_AVAILABLE) {
      overrideProductMutation.mutate({
        menuUuid: menu?.uuid,
        productUuid: product?.uuid,
        availability: statusType.STATUS_UNAVAILABLE,
        hasOverridePrice: false,
      });
    } else {
      overrideProductMutation.mutate({
        menuUuid: menu?.uuid,
        productUuid: product?.uuid,
        availability: statusType.STATUS_AVAILABLE,
        hasOverridePrice: false,
      });
    }
  };

  const renderAvailability = () => {
    if (overrideProductMutation.isLoading) {
      return <CircularProgress color="secondary" size={24} />;
    }

    return selectedAvailability === statusType.STATUS_AVAILABLE ? <EyeOnOutline /> : <EyeOffOutline />;
  };

  const handleEditModifierGroup = (modifierGroupUuid) => {
    const query = {
      action: drawerActions.EDIT,
      entity: drawerEntities.MODIFIER_GROUP,
      uuid: modifierGroupUuid,
      menuOfModifier: menu?.uuid,
    };

    history.replace({ search: stringify(query) });
  };

  const handleSyncProductOverride = ({ availability = selectedAvailability, price = product?.override?.price }) => {
    syncProductOverride({ menuUuid: menu?.uuid, productUuid: product?.uuid, availability, price });
  };

  return (
    <Box className={clsx(classes.headerRow, classes.rowBorderBottom)}>
      <Box className={clsx(classes.contentLeftRowProduct, { [classes.disabledRow]: isDisabledRow })}>
        <Box className={classes.imageContainer}>
          {product?.image ? (
            <img alt="Preview" src={product?.image} />
          ) : (
            <IconNotImage idRow={camelCase([menu?.uuid, product?.uuid])} size={32} />
          )}
        </Box>

        <Box>
          <Link
            className={classes.labelProductRow}
            component="button"
            data-testid={product?.uuid}
            onClick={() =>
              handleEditProduct(
                product?.uuid,
                isSaleByWeight({ serving: product?.serving, servingUnit: product?.servingUnit }),
              )
            }
            underline="none"
            variant="body2"
          >
            {product?.name}
          </Link>

          <Box className={classes.boxModifiers}>
            {product?.modifierGroups?.length
              ? product?.modifierGroups
                  ?.map((modifier) => (
                    <Link
                      key={`${product?.uuid}-${modifier?.uuid}`}
                      className={classes.labelModifiers}
                      component="button"
                      data-testid={modifier?.uuid}
                      onClick={() => handleEditModifierGroup(modifier?.uuid)}
                      underline="none"
                      variant="body2"
                    >
                      {modifier?.name}
                    </Link>
                  ))
                  ?.reduce((prev, curr) => [prev, ', ', curr])
              : null}
          </Box>
        </Box>
      </Box>

      <Box className={classes.contentRightRowProduct}>
        <Box className={clsx(classes.columnPrice, { [classes.disabledRow]: isDisabledRow })}>
          <PriceFormatField displayType="text" thousandSeparator value={product?.price ?? 0} />
          {unitWeight && (
            <Box>
              /{Number(product?.serving)} {unitWeight}
            </Box>
          )}
        </Box>

        {hasProductOverrides && !hideOverrideColumn && (
          <Box className={clsx(classes.columnPriceInMenu, { [classes.disabledRow]: isDisabledRow })}>
            <InputOverridePrice
              disabled={isDisabledRow}
              menuName={menu?.name}
              menuUuid={menu?.uuid}
              onSyncProductOverride={handleSyncProductOverride}
              placeholder={t('menuMaker:contentResume.labels.priceInMenuPlaceholder')}
              productUuid={product?.uuid}
              value={product?.override?.price}
              valueBase={product?.price ?? 0}
            />
          </Box>
        )}

        {hasProductOverrides && !openDrawer && (
          <Box className={classes.columnProduct}>
            <IconButton
              data-testid={`btnAvailability-${product?.uuid}`}
              disabled={overrideProductMutation.isLoading}
              onClick={handleOverrideAvailability}
              size="large"
            >
              {renderAvailability()}
            </IconButton>
          </Box>
        )}

        {/* <IconButton><IconMoreVertical size={16} /></IconButton> */}
        {/* Box with with is to simulate the space while IconButton is commented */}
        <Box width={16} />
      </Box>
    </Box>
  );
}

RowProduct.propTypes = {
  product: PropTypes.object,
  menu: PropTypes.object,
  hasProductOverrides: PropTypes.bool,
  openDrawer: PropTypes.bool,
  t: PropTypes.func,
};

export default RowProduct;
