import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Box from '@mui/material/Box';
import TableCellMui, { TableCellProps } from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import CircularProgress from '@mui/material/CircularProgress';
import type { Theme } from '@mui/material';

import ChevronBase from '@experimental-components/IconsComponents/ChevronBase';

const TableCell = withStyles((theme: Theme) => ({
  root: {
    padding: '1rem .75rem',
    fontWeight: 500,
    color: theme.palette.darkGrey.main,
    borderBottom: 0,
    lineHeight: 'normal',
    letterSpacing: 0,
  },
  stickyHeader: {
    top: theme.typography.pxToRem(53),
    'box-shadow': '18px 3px 11px 3px rgba(164, 170, 186, 0.15)',
  },
}))(TableCellMui);

interface StylesProps {
  active?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  sortLabelRoot: {
    lineHeight: 'normal !important',
    gap: theme.spacing(0.5),
  },
  sortLabelActive: {
    color: (props: StylesProps) => (props.active ? '#000 !important' : `${theme.palette.text.secondary} !important`),
  },
  sortLabelIcon: {
    opacity: (props: StylesProps) => (props.active ? '1 !important' : '0.5 !important'),
    margin: 0,
  },
}));

interface Props extends TableCellProps {
  disabled?: boolean;
  isSorting?: boolean;
  direction?: 'desc' | 'asc' | undefined;
  children: React.ReactNode;
  isFetching?: boolean;
  activeColumn?: boolean;
}

function TableHeadCell({
  disabled,
  activeColumn: activeHelperProp,
  isSorting,
  direction,
  children,
  isFetching,
  ...others
}: Props) {
  const classes = useStyles({ active: activeHelperProp });
  const IconSorting = ({ ...props }) => (
    <Box component="span" height={14} width={14}>
      <ChevronBase color="#F04E4A" {...props} />
    </Box>
  );
  const LoadingIcon = () => (
    <Box mb="-4px" ml={1}>
      <CircularProgress size={12} />
    </Box>
  );

  return (
    <TableCell {...others}>
      {isSorting && (
        <TableSortLabel
          active={!isFetching}
          classes={{ root: classes.sortLabelRoot, active: classes.sortLabelActive, icon: classes.sortLabelIcon }}
          direction={direction}
          disabled={disabled || isFetching}
          IconComponent={isFetching ? LoadingIcon : IconSorting}
        >
          {children}
        </TableSortLabel>
      )}
      {!isSorting && children}
    </TableCell>
  );
}

export default TableHeadCell;
