import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';

function Title({ children }) {
  return children;
}

function ModifierGroupSkeleton({ isEdition, children }) {
  return (
    <Grid container data-testid="ModifierGroupSkeleton" direction="column" spacing={3}>
      <Grid item>{children}</Grid>

      {isEdition && (
        <Grid item>
          <Skeleton height={80} variant="rectangular" width="100%" />
        </Grid>
      )}

      <Grid item>
        <Skeleton height={25} varaint="text" width={150} />
        <Skeleton height={92} variant="text" width="100%" />
      </Grid>

      {isEdition && (
        <>
          <Grid item>
            <Skeleton height={100} variant="rectangular" width="100%" />
          </Grid>

          <Grid item>
            <Skeleton height={25} varaint="text" width={150} />
            <Skeleton height={92} variant="text" width="100%" />
          </Grid>

          <Grid item>
            <Skeleton height={25} varaint="text" width={150} />
            <Skeleton height={92} variant="text" width="100%" />
          </Grid>

          <Grid item>
            <Skeleton height={25} varaint="text" width={150} />
            <Skeleton height={15} variant="text" width={150} />
            <Skeleton height={92} variant="text" width="100%" />

            <Box display="flex" justifyContent="space-between">
              <Skeleton height={100} varaint="text" width={180} />
              <Skeleton height={100} variant="text" width={180} />
            </Box>

            <Box mt={1}>
              <Divider />
            </Box>

            <Skeleton height={92} varaint="text" width={130} />
          </Grid>

          <Grid item>
            <Box mb={3}>
              <Skeleton height={25} varaint="text" width={150} />
              <Skeleton height={15} variant="text" width={350} />
            </Box>

            <Box alignItems="center" display="flex" mb={2}>
              <Box pr={1}>
                <Skeleton height={40} varaint="text" width={30} />
              </Box>
              <Skeleton height={50} variant="text" width={200} />
            </Box>

            <Skeleton height={25} varaint="text" width={80} />

            {[...Array(3).keys()].map((item) => (
              <Box key={item} alignItems="center" display="flex" justifyContent="space-between">
                <Skeleton height={50} varaint="text" width={280} />
                <Skeleton height={90} variant="text" width={82} />
              </Box>
            ))}

            <Box mt={1}>
              <Divider />
            </Box>

            <Box my={3}>
              <Skeleton height={25} varaint="text" width={150} />
              <Skeleton height={15} variant="text" width={350} />
            </Box>

            <Skeleton height={50} variant="rectangular" width={180} />

            <Box mt={3}>
              <Divider />
            </Box>
          </Grid>
          <Grid item>
            <Skeleton height={25} varaint="text" width={150} />
            <Skeleton height={15} variant="text" width="100%" />
            <Box columnGap={1} display="flex" justifyContent="space-between">
              <Skeleton height={92} variant="text" width="100%" />
              <Skeleton height={92} variant="text" width={170} />
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
}

ModifierGroupSkeleton.Title = Title;

ModifierGroupSkeleton.propTypes = {
  children: PropTypes.node,
};

export default ModifierGroupSkeleton;
