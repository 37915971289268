import Box, { BoxProps } from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import { colorType, bgColorType } from './types';

export interface Props extends BoxProps {
  children: React.ReactNode;
  height?: number | string;
  columnStructure?: string;
  bgColor?: bgColorType | string;
  color?: colorType;
  cursor?: 'pointer' | 'default';
  bold?: 'normal' | '500' | '700';
  onClick?: () => void;
  divider?: boolean;
}

const useStyles = makeStyles(() => ({
  hover: {
    '&:hover': {
      cursor: (props: { onClick?: () => void }) => (props.onClick ? 'pointer' : 'default'),
    },
  },
}));

const RowRoot = styled(Box)(() => ({
  display: 'grid',
  gridAutoFlow: 'column',
  alignItems: 'center',
  padding: '.5rem 1rem',
}));

const Row = ({
  children,
  height = 'auto',
  columnStructure = '2fr repeat(4, 1fr)',
  bgColor = 'gray',
  color = 'black',
  bold = 'normal',
  onClick,
  divider = false,
  ...others
}: Props) => {
  const classes = useStyles({ onClick });

  return (
    <RowRoot
      aria-label="Row"
      bgcolor={bgColor === 'gray' ? '#FBFBFC' : bgColor}
      borderBottom={divider ? '1px solid #CECDD1' : 'none'}
      className={classes.hover}
      color={color === 'gray' ? '#47465F' : 'black'}
      fontWeight={bold}
      gridTemplateColumns={columnStructure}
      height={height}
      onClick={onClick}
      {...others}
    >
      {children}
    </RowRoot>
  );
};

export default Row;
