import { useMemo } from 'react';
import { ResponsivePie } from '@nivo/pie';

import Box from '@mui/material/Box';

import { useGetReportSalesPerTimeSummary } from 'containers/ReportSalesPerTime/hooks';
import { DataResponseChart } from 'containers/ReportSalesPerTime/types';

import TooltipChart from 'components/ReportsResume/TooltipChart';

import { getDataGraphPie } from 'utils/reports/resume';

import { TooltipPropsPie } from './types';

interface Props {
  dataChart?: DataResponseChart;
}

const ChartPiePerTime = ({ dataChart }: Props) => {
  const { data } = useGetReportSalesPerTimeSummary();

  const dataTransformed = useMemo(() => {
    return getDataGraphPie(dataChart?.layers);
  }, [data]);

  function renderTooltip(dataE: TooltipPropsPie) {
    const tooltip = dataE?.datum?.data?.tooltip;

    return (
      <TooltipChart>
        {(tooltip?.tickValue || dataE?.datum?.data?.label) && (
          <span>{tooltip?.tickValue ?? dataE?.datum?.data?.label}: </span>
        )}
        {`${tooltip?.symbol}${tooltip?.value}`}
      </TooltipChart>
    );
  }

  return (
    <Box alignItems="center" display="flex" height={380} justifyContent="center">
      <ResponsivePie
        arcLinkLabel={(e) => {
          const item = e?.data;
          return `${item?.value}${item?.symbol}`;
        }}
        arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
        arcLinkLabelsDiagonalLength={25}
        arcLinkLabelsSkipAngle={2}
        arcLinkLabelsTextColor="#000000"
        arcLinkLabelsThickness={2}
        borderColor={{ from: 'color', modifiers: [['darker', 0]] }}
        borderWidth={1}
        colors={dataTransformed.map((item) => item?.color)}
        data={dataTransformed}
        enableArcLabels={false}
        innerRadius={0.5}
        margin={{ top: 40, right: 0, bottom: 40, left: 0 }}
        padAngle={2}
        startAngle={-70}
        theme={{
          fontSize: 14,
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        }}
        tooltip={renderTooltip}
      />
    </Box>
  );
};
export default ChartPiePerTime;
