import { useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import { TableContainerProps } from '@mui/material/TableContainer';

import ReportsTable from 'components/ReportsTable';

interface Props extends TableContainerProps {
  children: React.ReactNode;
  mbIntersection?: number;
  onIntersecting?: () => void;
  stickyHeader?: boolean;
  hasNextPage?: boolean;
  isGeneralTable?: boolean;
  isFetchingNextPage?: boolean;
}

function TableCustomContainer({
  children,
  onIntersecting = () => {},
  mbIntersection = 1,
  stickyHeader = false,
  hasNextPage = false,
  isGeneralTable = false,
  isFetchingNextPage = false,
}: Props) {
  const scrollPaginationRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const { isIntersecting } = entries[0];
        if (isIntersecting && hasNextPage && !isFetchingNextPage) {
          onIntersecting();
        }
      },
      { threshold: 1 },
    );

    if (scrollPaginationRef.current) observer.observe(scrollPaginationRef.current);

    return () => {
      observer.disconnect();
    };
  }, [scrollPaginationRef, hasNextPage]);

  return (
    <ReportsTable isGeneralTable={isGeneralTable} stickyHeader={stickyHeader}>
      {children}
      <Box ref={scrollPaginationRef} id="tableIntersection" mb={mbIntersection} />
    </ReportsTable>
  );
}

export default TableCustomContainer;
