import withStyles from '@mui/styles/withStyles';
import RadioMui from '@mui/material/Radio';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import type { Theme } from '@mui/material/styles';

import { ReactComponent as RadioCheckedIcon } from './icons/RadioCheckedIcon.svg';
import { ReactComponent as RadioIcon } from './icons/RadioIcon.svg';

const RadioBase = withStyles(() => ({
  root: {
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))(RadioMui);

const FormControlLabelBase = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
  },

  label: {
    fontSize: theme.typography.pxToRem(14),
    marginLeft: theme.spacing(2),

    '&$disabled': {
      color: theme.palette.darkGrey[15],
    },
  },

  disabled: {
    color: theme.palette.darkGrey[15],
  },
}))(FormControlLabel);

function Radio({ ...others }: FormControlLabelProps) {
  return (
    <FormControlLabelBase
      {...others}
      control={<RadioBase checkedIcon={<RadioCheckedIcon />} disableRipple icon={<RadioIcon />} />}
    />
  );
}

export default Radio;
