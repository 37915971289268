/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import { compose } from 'redux';
import { useFormContext, Controller } from 'react-hook-form';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Divider from '@mui/material/Divider';

import ButtonPlus from '@experimental-components/ButtonPlus';
import InputAdornmentFiled from '@design-system/InputAdornmentFiled';
import Checkbox from '@design-system/Checkbox';

import ModifierOptions from 'components/ModifierOptions';
import ProductsSort from 'components/ProductsSort';
import SkuMultiStore from 'components/SkuMultiStore';

import { modifierTypes } from 'utils/modifierGroups';
import { regexTypes, formatTextAllowedValues } from 'utils/formatField';
import { validateUppercase } from 'utils/menuMaker';

import ModifierGroupSkeleton from './ModifierGroupSkeleton';
import OptionRule from './OptionRule';
import SelectModifierTypeField from './SelectModifierTypeField';
import useStyles from './styles';

function ModifierGroupForm({
  fieldArrayMethods,
  isEdition,
  isLoading,
  isLoaded,
  onChangeProductsOrder,
  onSetStep,
  productsSelected,
  titleRef,
  isFormDirtyOnchangeTypeModifier,
  onSetTypeModifierBakcup,
  onSetOpenChangeTypeModifier,
  t,
}) {
  const classes = useStyles();
  const { control, errors, register, setValue, watch, trigger } = useFormContext();

  const { modifierTypeField, ruleOptionMin, forceSelectionField } = watch();
  const isNotCustomizable = modifierTypeField !== modifierTypes.CUSTOMIZABLE;

  useEffect(() => {
    if (ruleOptionMin === '0') {
      setValue('forceSelectionField', false);
    }
  }, [ruleOptionMin]);

  const handleOpenSelectProducts = () => {
    onSetStep(2);
  };

  const handleOpenSelectSKU = () => {
    onSetStep(3);
  };

  const handleChangeProductsOrder = (products) => {
    onChangeProductsOrder(products);
  };

  const handleChangeForceSelection = ({ target: { checked } }) => {
    setValue('ruleOptionMin', checked ? 1 : 0, { shouldValidate: true });
  };

  const handleValidateAllowedValues = (target, field) => {
    target.value = formatTextAllowedValues(target.value, regexTypes.REGEX_PRODUCT_NAME_ALLOWED);
    setValue(field, target.value);
    return target.value;
  };

  function handleChangeTypeModifier(value) {
    if (isFormDirtyOnchangeTypeModifier) {
      onSetOpenChangeTypeModifier(true);
      onSetTypeModifierBakcup(value);
    } else {
      setValue('modifierTypeField', value, { shouldValidate: true });
    }
  }

  const renderTitle = () => (
    <Typography className={classes.title}>
      {t(`menuMaker:sections.${isEdition ? 'editModifierGroup' : 'addModifierGroup'}`)}
    </Typography>
  );

  if (isLoading) {
    return (
      <ModifierGroupSkeleton isEdition={isEdition}>
        <ModifierGroupSkeleton.Title>{renderTitle()}</ModifierGroupSkeleton.Title>
      </ModifierGroupSkeleton>
    );
  }

  if (isLoaded) {
    return (
      <Grid ref={titleRef} container direction="column" spacing={3}>
        <Grid item>{renderTitle()}</Grid>

        {isEdition && (
          <Grid item>
            <Typography className={classes.grayBox}>
              <Trans i18nKey="menuMaker:modifierGroupForm.editionWarning" />
            </Typography>
          </Grid>
        )}

        <Grid item>
          <Controller
            control={control}
            name="modifierTypeField"
            render={({ onChange, name, ref }) => (
              <SelectModifierTypeField
                inputRef={ref}
                isEdition={isEdition}
                modifierType={modifierTypeField}
                name={name}
                onChangeType={(value) => {
                  if (modifierTypeField) {
                    handleChangeTypeModifier(value);
                  } else {
                    onChange(value);
                  }
                }}
                t={t}
              />
            )}
          />
        </Grid>

        {modifierTypeField && (
          <>
            <Grid item>
              <Typography className={classes.grayBox}>
                <Trans i18nKey={`menuMaker:modifierGroupForm.typeDescription.${modifierTypeField?.toLowerCase()}`} />
              </Typography>
            </Grid>

            <Grid item>
              <InputAdornmentFiled
                autoComplete="off"
                data-testid="inputNameModifierGroup"
                endAdornment={
                  <InputAdornment position="end">
                    <Box color="#CECDD1" fontSize="14px">
                      {watch('nameModifierGroupField')?.length ?? 0}/40
                    </Box>
                  </InputAdornment>
                }
                error={Boolean(errors?.nameModifierGroupField)}
                errorMsg={errors?.nameModifierGroupField?.message}
                fullWidth
                id="inputNameModifierGroup"
                inputProps={{ maxLength: 40, minLength: 3 }}
                inputRef={register({
                  required: t('menuMaker:modifierGroupForm.name.requiredError'),
                  maxLength: { value: 40, message: t('common:errors.maxLengthField', { chars: 40 }) },
                  minLength: {
                    value: 3,
                    message: t('common:errors.minLength3Field'),
                  },
                  validate: {
                    controlUppercase: (value) => validateUppercase(value) || t('common:errors.controlUppercase'),
                  },
                })}
                label={t(
                  `menuMaker:modifierGroupForm.name.${modifierTypeField === 'QUESTION' ? 'question' : 'default'}`,
                )}
                name="nameModifierGroupField"
                onChange={({ target }) => handleValidateAllowedValues(target, 'nameModifierGroupField')}
              />
            </Grid>

            <Grid item>
              <InputAdornmentFiled
                autoComplete="off"
                data-testid="inputNoteModifierGroup"
                endAdornment={
                  <InputAdornment position="end">
                    <Box color="#CECDD1" fontSize="14px">
                      {watch('noteModifierGroupField')?.length ?? 0}/40
                    </Box>
                  </InputAdornment>
                }
                error={Boolean(errors?.noteModifierGroupField)}
                errorMsg={errors?.noteModifierGroupField?.message}
                fullWidth
                id="inputNoteModifierGroup"
                inputProps={{ maxLength: 40, minLength: 3 }}
                inputRef={register({
                  maxLength: {
                    value: 40,
                    message: t('common:errors.maxLengthField', { chars: 40 }),
                  },
                  minLength: {
                    value: 3,
                    message: t('common:errors.minLength3Field'),
                  },
                  validate: {
                    controlUppercase: (value) => validateUppercase(value) || t('common:errors.controlUppercase'),
                  },
                })}
                label={t('menuMaker:modifierGroupForm.noteModifierGroup.label')}
                name="noteModifierGroupField"
                onChange={({ target }) => handleValidateAllowedValues(target, 'noteModifierGroupField')}
                placeholder={t('menuMaker:modifierGroupForm.noteModifierGroup.placeholder')}
              />
            </Grid>

            <Grid item>
              <Typography className={classes.subtitles}>
                {t('menuMaker:modifierGroupForm.labels.optionsToInclude')}
              </Typography>

              <Box>
                <Typography variant="body2">{t('menuMaker:modifierGroupForm.labels.optionsToIncludeDesc')}</Typography>
              </Box>

              <Box mt={3}>
                <ModifierOptions
                  fieldArrayMethods={fieldArrayMethods}
                  modifierType={modifierTypeField}
                  options={fieldArrayMethods?.fields}
                />
              </Box>
            </Grid>

            <Grid item>
              <Typography className={classes.subtitles}>
                {t('menuMaker:modifierGroupForm.rulesSection.title')}
              </Typography>

              <Box>
                <Typography variant="body2">{t('menuMaker:modifierGroupForm.rulesSection.description')}</Typography>
              </Box>

              <Checkbox
                checked={!!forceSelectionField}
                className={classes.checkboxForceSelection}
                data-testid="checkboxForceSelection"
                disabled={isNotCustomizable}
                inputRef={register()}
                isCheckedDisabled={isNotCustomizable}
                label={t('menuMaker:modifierGroupForm.rulesSection.forceSelection')}
                name="forceSelectionField"
                onChange={handleChangeForceSelection}
              />

              <Box mt={3}>
                <Box mb={1}>
                  <Typography className={classes.subtitles}>
                    {t('menuMaker:modifierGroupForm.rulesSection.optionsLabel')}
                  </Typography>
                </Box>

                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <OptionRule
                      disabled={isNotCustomizable}
                      label={t('menuMaker:modifierGroupForm.rulesSection.rules.minOptions')}
                      name="ruleOptionMin"
                      t={t}
                    />
                  </Grid>

                  <Grid item>
                    <OptionRule
                      disabled={isNotCustomizable}
                      label={t('menuMaker:modifierGroupForm.rulesSection.rules.maxOptions')}
                      name="ruleOptionMax"
                      t={t}
                    />
                  </Grid>

                  <Grid item>
                    <OptionRule
                      disabled={isNotCustomizable}
                      label={t('menuMaker:modifierGroupForm.rulesSection.rules.quantityOptions')}
                      name="ruleOptionRepeated"
                      t={t}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item>
              <Box py={1}>
                <Divider />
              </Box>
            </Grid>

            <Grid item>
              <Typography className={classes.subtitles}>{t('menuMaker:modifierGroupForm.labels.products')}</Typography>

              <Box mb={3}>
                <Typography variant="body2">{t('menuMaker:modifierGroupForm.labels.productsDescription')}</Typography>
              </Box>

              {productsSelected?.length > 0 && (
                <Box mb={3}>
                  <ProductsSort hideSort onChange={handleChangeProductsOrder} products={productsSelected} />
                </Box>
              )}
              <ButtonPlus data-testid="btnAddArticles" onClick={handleOpenSelectProducts}>
                {t('menuMaker:buttons.assignItem')}
              </ButtonPlus>
            </Grid>

            <Grid item>
              <Divider />
            </Grid>

            <Grid item>
              <Typography className={classes.subtitles}>{t('menuMaker:skuLabels.key')}</Typography>
              <Box mb={4} mt={1}>
                <Typography color="textSecondary" variant="body2">
                  {t('menuMaker:skuLabels.modifierGroup.info')}
                </Typography>
              </Box>

              <SkuMultiStore
                errors={errors}
                onClickButton={handleOpenSelectSKU}
                prefix="GM-"
                register={register}
                trigger={isEdition ? trigger : null}
                watch={watch}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }
  return null;
}

ModifierGroupForm.propTypes = {
  fieldArrayMethods: PropTypes.object,
  isEdition: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoaded: PropTypes.bool,
  onChangeProductsOrder: PropTypes.func,
  onSetStep: PropTypes.func,
  productsSelected: PropTypes.array,
  isFormDirtyOnchangeTypeModifier: PropTypes.bool,
  onSetTypeModifierBakcup: PropTypes.func,
  onSetOpenChangeTypeModifier: PropTypes.func,
  t: PropTypes.func,
};

export default compose(withTranslation('menuMaker'), withTranslation('modifierGroups'), memo)(ModifierGroupForm);
