import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';
import type { Theme } from '@mui/material';

export const TooltipStyled = withStyles((theme: Theme) => ({
  tooltip: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2),
    boxShadow: theme.shadows[1],
    lineHeight: '1.7',
  },
}))(Tooltip);

const useStyles = makeStyles((theme: Theme) => ({
  wrapperTooltip: {
    position: 'relative',
    width: '100%',
    minWidth: 0,
  },

  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },

  tooltip: {
    position: 'absolute',
    right: 0,
    zIndex: 1,
  },

  strikethroughCell: {
    textDecoration: 'line-through',
  },

  textDisabled: {
    color: theme.palette.darkGrey[50],
    textAlign: 'end',
  },
}));

export default useStyles;
