// @ts-nocheck
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { Trans } from 'react-i18next';

import ButtonPrimary from '@design-system/ButtonPrimary';
import ButtonOutlined from '@design-system/ButtonOutlined';
import Table from '@experimental-components/Table';
import ErrorCircle from '@experimental-components/IconsComponents/ErrorCircle';
import SuccessCircle from '@experimental-components/IconsComponents/SuccessCircle';

import useStyles from './styles';

interface PropsBrand {
  uuid: string;
  name: string;
}

interface Props {
  isSync: boolean;
  menuMutation: {
    isError: boolean;
    isSuccess: boolean;
    data: { data: { isSuccess: boolean; uuid: string; name: string; brands: PropsBrand[] }[] };
  };
  onClose: () => void;
  onRetry: (values: { destinationList: string[] }) => void;
  open: boolean;
  t: (value: string) => string;
}

function MenuCopyNotification({ isSync, menuMutation, open, onClose, onRetry, t }: Props) {
  const classes = useStyles({});
  const [retriesCount, setRetriesCount] = useState(0);
  const isFullCopySuccess = menuMutation.isSuccess && menuMutation?.data?.data.every((item) => item.isSuccess);
  const storesList = menuMutation?.data?.data;

  useEffect(() => {
    if (storesList?.some((item) => item.isSuccess)) {
      setRetriesCount(0);
    }
  }, [storesList]);

  const handleRetryFailed = () => {
    setRetriesCount((count) => count + 1);

    const brandsFailed = storesList.reduce<string[]>((brandsFailedList, currentStore) => {
      if (!currentStore.isSuccess) {
        return [...brandsFailedList, ...currentStore.brands.map((item) => item.uuid)];
      }

      return brandsFailedList;
    }, []);

    onRetry({ destinationList: brandsFailed });
  };

  return (
    <Dialog classes={{ paperFullScreen: classes.paperFullScreen }} fullScreen open={open} style={{ zIndex: '1400' }}>
      <DialogTitle>
        <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
          <Box component="h4" fontSize={40} fontWeight="fontWeightMedium" m={0} textAlign="center">
            {t(
              `menuMaker:copyMenu.status.${isFullCopySuccess ? 'success' : 'error'}.${isSync ? 'titleSync' : 'title'}`,
            )}
          </Box>

          <Box className={classes.descriptionNotification} component="p">
            {retriesCount === 2 ? (
              t(`menuMaker:copyMenu.status.error.${isSync ? 'retryDescriptionSync' : 'retryDescription'}`)
            ) : (
              <Trans i18nKey={`menuMaker:copyMenu.status.${isFullCopySuccess ? 'success' : 'error'}.description`} />
            )}
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent classes={{ root: classes.rootContent }}>
        <Table className={classes.tableNotification}>
          <Table.Head>
            <Table.Row>
              <Table.HeadCell align="left">{t('menuMaker:copyMenu.copyStatus')}</Table.HeadCell>
              <Table.HeadCell align="left">{t('menuMaker:copyMenu.store')}</Table.HeadCell>
              <Table.HeadCell align="left">{t('menuMaker:copyMenu.brand')}</Table.HeadCell>
            </Table.Row>
          </Table.Head>

          <Table.Body>
            {storesList?.map((store, index) => (
              <Table.Row key={store.uuid}>
                <Table.Cell align="left" isBorder={index !== 0} style={{ width: '56px' }}>
                  {store.isSuccess ? <SuccessCircle size={40} /> : <ErrorCircle size={40} />}
                </Table.Cell>

                <Table.Cell align="left" isBorder={index !== 0}>
                  <Box fontWeight="fontWeightMedium">{store.name}</Box>
                </Table.Cell>

                <Table.BigCell align="left" isBorder={index !== 0}>
                  <Table.BigCell.ExpandableList
                    list={store.brands.map((brand) => (
                      <Table.BigCell.Option key={brand.uuid}>
                        <Typography>{brand.name}</Typography>
                      </Table.BigCell.Option>
                    ))}
                  />
                </Table.BigCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </DialogContent>

      <DialogActions classes={{ root: classes.rootActions }}>
        <Box display="grid" gap={2} gridAutoFlow="column" mt={2}>
          <ButtonOutlined className={classes.buttonCloseNot} onClick={onClose}>
            {t('common:buttons.close')}
          </ButtonOutlined>

          {!isFullCopySuccess && retriesCount < 2 && (
            <ButtonPrimary onClick={handleRetryFailed}>{t('menuMaker:buttons.retryFailed')}</ButtonPrimary>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default MenuCopyNotification;
