import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';

import InputAdornment from '@mui/material/InputAdornment';
import InputAdornmentFiled from '@design-system/InputAdornmentFiled';
import ButtonPlus from '@experimental-components/ButtonPlus';

import useStyles from './styles';

function SkuMultiStore({ register, errors, watch, onClickButton, labelButon = 'addExistingKey', prefix, trigger }) {
  const classes = useStyles();
  const { t } = useTranslation('menuMaker');
  const limitLength = prefix ? 17 : 20;

  useEffect(() => {
    if (trigger) trigger('skuFieldMultiStore');
  }, []);

  const handleValidateChars = (e) => {
    const regex = /^[a-zA-Z0-9-]+$/;
    const key = String.fromCharCode(e.keyCode || e.which);
    if (!regex.test(key)) {
      e.preventDefault();
      return false;
    }
  };

  return (
    <Box className={classes.twoCols}>
      <Box columnGap={prefix ? '5px' : 0} display="flex">
        {prefix && (
          <Box fontSize={14} py="11.5px">
            {prefix}
          </Box>
        )}

        <InputAdornmentFiled
          data-testid="inputSkuMultiStore"
          endAdornment={
            <InputAdornment position="end">
              <Box color="#CECDD1" fontSize="14px">
                {watch('skuFieldMultiStore')?.length ?? 0}/{limitLength}
              </Box>
            </InputAdornment>
          }
          error={Boolean(errors?.skuFieldMultiStore)}
          errorMsg={errors?.skuFieldMultiStore?.message}
          hideLabel
          inputProps={{ maxLength: limitLength, 'data-testid': 'skuMultiStore' }}
          inputRef={register({
            required: t('common:messages.requireField'),
            maxLength: {
              message: t('menuMaker:skuLabels.tooManyDigitsErrorLabel', { limit: limitLength }),
              value: limitLength,
            },
            pattern: {
              message: t('menuMaker:skuLabels.errorPatternCharsLabel'),
              value: /^[a-zA-Z0-9-]+$/,
            },
          })}
          name="skuFieldMultiStore"
          onKeyPress={handleValidateChars}
        />
      </Box>

      <ButtonPlus classes={{ root: classes.buttonRoot }} onClick={onClickButton}>
        {t(`menuMaker:buttons.${labelButon}`)}
      </ButtonPlus>
    </Box>
  );
}

SkuMultiStore.propTypes = {
  errors: PropTypes.object,
  labelButon: PropTypes.string,
  onClickButton: PropTypes.func,
  prefix: PropTypes.string,
  register: PropTypes.func,
  watch: PropTypes.func,
};

export default SkuMultiStore;
