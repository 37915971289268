import { forwardRef, Ref, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}

const OnlineOrdering = forwardRef(function OnlineOrdering(props: Props, ref: Ref<SVGSVGElement>) {
  const { color = '#FFF', size = 16, ...others } = props;

  return (
    <svg
      ref={ref}
      fill="none"
      height={size}
      viewBox="0 0 16 16"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...others}
    >
      <path
        clipRule="evenodd"
        d="M5.17603 6.79993H6.54271C6.72941 6.79993 7.16053 6.84259 7.52119 7.087C7.7136 7.2174 7.89679 7.41346 7.99273 7.69405C8.08596 7.96671 8.08002 8.27007 7.9885 8.59254L7.85552 9.23773L7.80804 9.51064L6.82863 9.30877L6.86464 9.0915L7.01723 8.35117L7.02312 8.33118C7.07714 8.14785 7.05934 8.05511 7.04651 8.01758C7.03434 7.98199 7.01082 7.94913 6.96019 7.91481C6.90466 7.87719 6.82733 7.84621 6.73882 7.82549C6.65118 7.80498 6.57577 7.79993 6.54271 7.79993H5.22214C5.21202 7.80078 5.2012 7.80166 5.18971 7.80259C4.93255 7.8234 4.34031 7.87133 3.66252 8.34169C2.99955 8.80176 2.45004 9.51215 2.25941 10.64H11.224L11.2479 10.6423C11.5813 10.6744 12.1428 10.6145 12.6431 10.3252C13.0707 10.0779 13.4737 9.6533 13.6526 8.912C13.3263 8.66787 12.9324 8.47877 12.5139 8.48264C12.0347 8.48708 11.5824 8.44004 10.8295 9.55844L9.99997 9C10.8665 7.71283 11.5873 7.49118 12.5046 7.48269C13.3862 7.47452 14.099 7.95359 14.5334 8.34245L14.7349 8.52275L14.6943 8.79003C14.5088 10.012 13.8754 10.7677 13.1437 11.1909C12.4466 11.594 11.6839 11.6835 11.1773 11.64H1.16772L1.20094 11.1088C1.31013 9.36245 2.07874 8.22357 3.09239 7.52013C3.99357 6.89474 4.81258 6.82951 5.09656 6.80689C5.11832 6.80516 5.13694 6.80368 5.15221 6.80221L5.17603 6.79993Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M5.8661 4.14196V7.04853V7.29075H5.57544H3.15329C2.06817 7.29075 1.7646 6.61255 1.74845 6.27345C1.7323 5.98279 1.70969 5.14957 1.74845 4.14196C1.78357 3.22883 2.37832 3.0159 2.75394 3.02005C2.79281 3.02048 2.82933 3.02323 2.86264 3.02777H4.80035C5.6917 3.02777 5.88224 3.77056 5.8661 4.14196Z"
        stroke={color}
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M2.9751 11.1462C2.9751 11.9402 3.61879 12.5839 4.41281 12.5839C5.20684 12.5839 5.85053 11.9402 5.85053 11.1462H6.85053C6.85053 12.4925 5.75913 13.5839 4.41281 13.5839C3.0665 13.5839 1.9751 12.4925 1.9751 11.1462H2.9751Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.5754 11.1462C13.5754 10.8533 13.4884 10.5823 13.3389 10.3558L14.1736 9.80508C14.4276 10.19 14.5754 10.6516 14.5754 11.1462C14.5754 12.4925 13.484 13.5839 12.1377 13.5839C10.7914 13.5839 9.69995 12.4925 9.69995 11.1462H10.7C10.7 11.9402 11.3436 12.5839 12.1377 12.5839C12.9317 12.5839 13.5754 11.9402 13.5754 11.1462Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.76 4.48587C10.5201 3.62293 9.90438 3.49234 9.71381 3.50028L9.7034 3.50072H9.06323V2.50072H9.68389C10.2708 2.48172 11.3467 2.85231 11.7252 4.22415L11.7276 4.23305L12.7448 8.20539L11.7761 8.45348L10.76 4.48587Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M13.0057 4.47029C12.8908 4.40026 12.7301 4.37435 12.6224 4.37925L12.6111 4.37977H11.0496V3.37977H12.5896C12.837 3.37064 13.2023 3.41914 13.5259 3.61624C13.8866 3.83593 14.1655 4.22644 14.1655 4.80018V5.33305C14.1655 5.58203 14.0792 5.92093 13.834 6.2107C13.5753 6.51651 13.1732 6.72792 12.6224 6.75295L12.6111 6.75347H11.6793V5.75347H12.5875C12.8819 5.73786 13.0104 5.63595 13.0706 5.56475C13.1452 5.47666 13.1655 5.37473 13.1655 5.33305V4.80018C13.1655 4.68565 13.1401 4.61868 13.1169 4.57877C13.0928 4.53748 13.0572 4.50163 13.0057 4.47029Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
});

export default OnlineOrdering;
