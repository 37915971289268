import { stringify } from 'query-string';

import { getUserCurrentStore } from 'utils/auth';
import { reportsApi } from 'utils/api/baas/reports/axiosBase';

import type {
  FilterProps as SummaryRequest,
  FilterProps as ExportRequest,
  SummaryProps as SummaryResponse,
  DetailsRequest,
  DetailsProps as DetailResponse,
} from 'components/ReportAverageTicket/types';

export const getAverageTicketSummaryRequest = async ({
  startDate,
  endDate,
  brands,
  providers,
  orderTypes,
}: SummaryRequest) => {
  const storeUuidLocal = getUserCurrentStore()?.uuid;

  const query = stringify({ store_uuid: storeUuidLocal, start_date: startDate, end_date: endDate });

  const body = { brands, providers, order_types: orderTypes };

  const response = await reportsApi.post<SummaryResponse>(`/v1/reports/average-ticket/summary/?${query}`, { ...body });
  return response.data;
};

export const getAverageTicketDetailRequest = async ({
  startDate,
  endDate,
  brands,
  providers,
  orderTypes,
  page = 1,
  pageSize = 20,
}: DetailsRequest) => {
  const storeUuidLocal = getUserCurrentStore()?.uuid;

  const query = stringify({
    store_uuid: storeUuidLocal,
    start_date: startDate,
    end_date: endDate,
    page,
    page_size: pageSize,
  });

  const body = { brands, providers, order_types: orderTypes };

  const response = await reportsApi.post<DetailResponse>(`/v1/reports/average-ticket/detail/?${query}`, { ...body });
  return response.data;
};

export const downloadAverageTicketReportRequest = async ({
  startDate,
  endDate,
  brands,
  providers,
  orderTypes,
}: ExportRequest): Promise<Blob> => {
  const storeUuidLocal = getUserCurrentStore()?.uuid;

  const query = stringify({
    store_uuid: storeUuidLocal,
    start_date: startDate,
    end_date: endDate,
  });

  const body = { brands, providers, order_types: orderTypes };

  const response = await reportsApi.post<Promise<Blob>>(
    `/v1/reports/average-ticket/export/?${query}`,
    { ...body },
    { responseType: 'blob' },
  );

  return response.data;
};
