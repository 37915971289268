/* eslint-disable react/no-array-index-key */
import { Box } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import useStyles from './styles';

interface Props {
  includeHeaders?: boolean;
}

const SkeletonReport = ({ includeHeaders = true }: Props) => {
  const classes = useStyles({});

  return (
    <Box>
      {includeHeaders && (
        <>
          <Box>
            <Skeleton height={20} width="100%" />
            <Skeleton height={20} width="40%" />
          </Box>

          <Box className={classes.filters}>
            <Box width="100%">
              <Skeleton height={20} width="40%" />
              <Skeleton height={42} variant="rectangular" width="70%" />
            </Box>
          </Box>
        </>
      )}

      <Box className={classes.containerReport}>
        {Array.from(Array(4).keys()).map((item) => (
          <Box key={item} bgcolor="#0000001C" height={400} width="100%" />
        ))}
      </Box>
    </Box>
  );
};

export default SkeletonReport;
