import withStyles from '@mui/styles/withStyles';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';

const InputRoot = withStyles((theme) => {
  const placeholder = {
    color: (props) => (props.disabled ? theme.palette.darkGrey[15] : theme.palette.darkGrey[50]),
    opacity: 1,
  };

  return {
    root: {
      'label + &': {
        marginTop: theme.spacing(4),
      },
    },
    input: {
      borderRadius: theme.shape.borderRadius,
      position: 'relative',
      backgroundColor: '#F7F7F9',
      border: 'none',
      fontSize: 16,
      fontWeight: 400,
      height: '0.875rem',
      padding: '1.25rem 1rem',
      width: '100%',
      minWidth: 70,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&::-webkit-input-placeholder': placeholder,

      '&:hover': {
        border: 'none',
      },

      '&:focus': {
        border: 'none',
      },
    },

    disabled: {
      color: theme.palette.darkGrey[15],
    },

    error: {
      color: theme.palette.error.main,
    },
  };
})(InputBase);

function InputBaseSelect({ id, error, fullWidth, disabled, required, ...others }) {
  return (
    <FormControl disabled={disabled} error={error} fullWidth={fullWidth} required={required} variant="standard">
      <InputRoot disabled={disabled} error={error} id={id} required={required} {...others} />
    </FormControl>
  );
}

InputBaseSelect.propTypes = {
  ...InputBase.propTypes,
};

export default InputBaseSelect;
