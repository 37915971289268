import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { ViewPanelLayout } from 'Layout';

import { Box, Grid } from '@mui/material';
import TextField from '@design-system/TextField';
import ButtonPrimary from '@design-system/ButtonPrimary';

import { regexTypes } from 'utils/formatField';

import { ProductTypeFormProps } from '../types';

interface Props {
  isLoading: boolean;
  productTypeDetail?: ProductTypeFormProps;
  onGoBack: () => void;
  onSubmit: (values: { name: string; uuid?: string }) => void;
}

function CreateEditTypeOfProduct({ isLoading, productTypeDetail, onGoBack, onSubmit }: Props) {
  const { t } = useTranslation('settingsTypeOfProduct');

  const formMethods = useForm({
    mode: 'onChange',
    defaultValues: {
      nameField: productTypeDetail?.name,
    },
  });

  const { isValid } = formMethods.formState;

  function handleSubmit(values: { nameField: string }) {
    const params = {
      name: values.nameField,
      uuid: productTypeDetail?.uuid || '',
    };
    onSubmit(params);
  }

  return (
    <ViewPanelLayout
      actionComponent={
        <ButtonPrimary
          disabled={isLoading || !isValid}
          loading={isLoading}
          onClick={formMethods.handleSubmit(handleSubmit)}
        >
          {t('common:buttons.save')}
        </ButtonPrimary>
      }
      labelGoBack={t('common:buttons.toReturn')}
      onGoBack={onGoBack}
      pb={4}
      pl={0}
      pr={0}
    >
      <FormProvider {...formMethods}>
        <Box mb={8} pl={4} pr={4}>
          <Box>
            <Box fontSize={24} fontWeight="fontWeightMedium">
              {t('settingsTypeOfProduct:form.title')}
            </Box>
          </Box>

          <Box mt={6}>
            <Grid container>
              <Grid item md={4}>
                <TextField
                  autoFocus
                  disabled={isLoading}
                  error={Boolean(formMethods.errors?.nameField)}
                  errorMsg={formMethods.errors?.nameField?.message}
                  fullWidth
                  inputRef={formMethods.register({
                    pattern: {
                      message: t('settingsTypeOfProduct:form.errors.typeOfProductNamePattern'),
                      value: regexTypes.REGEX_ALPHANUMERIC,
                    },
                    required: t('common:messages.requireField'),
                    maxLength: {
                      value: 60,
                      message: t('settingsTypeOfProduct:form.errors.typeOfProductNameLength'),
                    },
                    minLength: 1,
                  })}
                  label={t('settingsTypeOfProduct:form.label')}
                  name="nameField"
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </FormProvider>
    </ViewPanelLayout>
  );
}

export default CreateEditTypeOfProduct;
