import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

function SidebarLayoutSection({ children, css, ...others }) {
  return (
    <Box
      component="ul"
      m={0}
      p={0}
      sx={{
        listStyle: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
        ...css,
      }}
      {...others}
    >
      {children}
    </Box>
  );
}

SidebarLayoutSection.propTypes = {
  children: PropTypes.node,
  css: PropTypes.object,
};

export default SidebarLayoutSection;
