export const getHeaderTooltip = (index: number) => {
  const messages = [
    'detailDate',
    'detailTotal',
    'detailOrders',
    'detailAverageTicketOrder',
    'detailCountCustomer',
    'detailAverageTicketCustomer',
  ];

  return messages[index];
};
