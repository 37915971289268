import { getCookieUser } from 'utils/auth';

const showForAll = process.env.REACT_APP_SHOW_COMMAND_BAR === 'true';
const showForParrot = process.env.REACT_APP_SHOW_COMMAND_BAR_ONLY_INTERNAL === 'true';
const userEmail = getCookieUser()?.email;
const hasParrotEmail =
  userEmail?.split('@')[1].includes('parrot.rest') || userEmail?.split('@')[1].includes('parrotsoftware.io');

export const hideCommandBar =
  (!showForAll && !showForParrot) ||
  (!showForAll && showForParrot && !hasParrotEmail) ||
  (showForAll && !showForParrot && hasParrotEmail);
