import { Route, RouteProps } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';

import AppProviders from './Providers';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
}

export function PublicRoute({ component: Component, ...routeProps }: Omit<RouteProps, 'component'> & Props) {
  const { isAuthenticated } = useAuth();

  function renderComponent(routerProps: RouteProps) {
    if (isAuthenticated) {
      window.location.replace('/reports');
      return <></>;
    }

    return <Component {...routerProps} />;
  }

  return (
    <AppProviders>
      <Route {...routeProps} render={renderComponent} />
    </AppProviders>
  );
}

export default PublicRoute;
