import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';

import useStyles from './styles';

function CheckboxLabel({ value, defaultChecked, name, ...rest }) {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.checkboxPaper, { [classes.checkboxActive]: defaultChecked })} variant="outlined">
      <FormControlLabel
        classes={{ root: classes.checkboxLabel }}
        control={<Checkbox defaultChecked={defaultChecked} icon={<div />} name={name} />}
        value={value}
        {...rest}
      />
    </Paper>
  );
}

CheckboxLabel.propTypes = {
  defaultChecked: PropTypes.bool,
  value: PropTypes.string,
  name: PropTypes.string,
};

export default CheckboxLabel;
