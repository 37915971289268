/* eslint-disable no-nested-ternary */
import amplitude from 'amplitude-js';

import { config as configInitial } from './events';
import isUndefined from './isUndefined';
import isString from './isString';
import isObject from './isObject';
import get from './get';

const instance = amplitude.getInstance();

const getValues = (fieldNames) => {
  const values = configInitial;

  return isUndefined(fieldNames)
    ? values
    : isString(fieldNames)
    ? get(values, fieldNames)
    : fieldNames.map((name) => get(values, name));
};

const getData = (fieldNames) => {
  const values = configInitial;
  const rexKey = /[,[\].]+?/;

  if (isString(fieldNames)) {
    const key = fieldNames?.split(rexKey)[0]?.replace('@@', '');
    return {
      [key]: get(values, fieldNames),
    };
  }

  if (isObject(fieldNames)) {
    const dataObject = fieldNames.reduce((a, b) => {
      const key = b?.split(rexKey)[0]?.replace('@@', '');
      return { ...a, [key]: get(values, b) };
    }, {});

    return dataObject;
  }

  return {};
};

export function setUserPropertyRequest(properties) {
  instance.setUserProperties(properties);
}

export function logEventRequest(event, data, extras) {
  instance.logEvent(getValues(event), { ...getData(data), ...extras });
}
