import { useEffect } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';

import Box from '@mui/material/Box';

import IconClose from '@experimental-components/IconsComponents/Close';
import IconButton from '@design-system/IconButton';
import ButtonPrimary from '@design-system/ButtonPrimary';

import useStyles from './styles';

let closeDrawer;

export function getCloseDrawerFunction() {
  return closeDrawer;
}

function MainView({
  children,
  currentStep,
  fullDrawer,
  loading,
  disabled = true,
  labelAction,
  onAction,
  onClose,
  step,
  buttonActionFixedBottom = false,
}) {
  const { modeDraft } = useSidebarLayout();
  const isActive = currentStep === step;
  const classes = useStyles({ isActive, isModeDraft: modeDraft, fullDrawer });
  closeDrawer = onClose;

  useEffect(() => {
    return () => {
      closeDrawer = undefined;
    };
  }, []);

  if (currentStep < step) return null;

  return (
    <Box className={classes.drawerContainer} component="section" flexDirection="column">
      <Box className={classes.drawerHeader} component="header">
        <IconButton data-testid="btnCloseDrawer" onClick={closeDrawer} size="large">
          <IconClose lighter size={20} />
        </IconButton>

        <Box className={clsx({ [classes.drawerHeaderButton]: buttonActionFixedBottom })}>
          <ButtonPrimary data-testid="btnActionDrawer" disabled={disabled} loading={loading} onClick={onAction}>
            {labelAction}
          </ButtonPrimary>
        </Box>
      </Box>

      <Box className={classes.drawerContent}>{children}</Box>
    </Box>
  );
}

MainView.propTypes = {
  children: PropTypes.node,
  currentStep: PropTypes.number,
  fullDrawer: PropTypes.bool,
  disabled: PropTypes.bool,
  labelAction: PropTypes.string,
  loading: PropTypes.bool,
  onAction: PropTypes.func,
  onClose: PropTypes.func,
  step: PropTypes.number,
  buttonActionFixedBottom: PropTypes.bool,
};

export default MainView;
