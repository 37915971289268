import { useState, useEffect } from 'react';
import { fetchAndActivate, getAll } from 'firebase/remote-config';

import { getRemoteConfigFlag } from 'firebaseConfig';

import { getFlagsFormatted } from 'utils/featureFlags/utils';

type Parameter = Record<string, string | number | boolean> | undefined;

const remoteConfig = getRemoteConfigFlag();

const useRemoteConfigFlags = () => {
  const [stateFlags, setStateFlags] = useState({ loading: false, loaded: false });
  const [flags, setFlags] = useState<Parameter>(remoteConfig.defaultConfig);

  useEffect(() => {
    (async () => {
      try {
        setStateFlags({ loading: true, loaded: false });

        await fetchAndActivate(remoteConfig);

        const allFlags = getAll(remoteConfig);

        const newFlags = getFlagsFormatted(allFlags);
        setFlags(newFlags);

        setStateFlags({ loading: false, loaded: true });
      } catch (error) {
        setStateFlags({ loading: false, loaded: true });
      }
    })();
  }, []);

  return { stateFlags, flags };
};

export default useRemoteConfigFlags;
