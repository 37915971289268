/* eslint-disable react/no-array-index-key */
import Skeleton from '@mui/lab/Skeleton';
import makeStyles from '@mui/styles/makeStyles';

import NestedTable from '@experimental-components/NestedTable';

const useStyles = makeStyles(() => ({
  fullWidthCell: {
    width: '100%',
  },
}));

interface Props {
  columnStructure: string;
  placeholderRows: number;
  placeholderCols: number;
}

const Placeholder = ({ columnStructure, placeholderCols, placeholderRows }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      {Array.from(Array(placeholderRows).keys()).map((row, i) => (
        <NestedTable.Row key={i} bgColor="white" columnStructure={columnStructure} divider>
          {Array.from(Array(placeholderCols).keys()).map((col, j) => (
            <NestedTable.Cell key={`key-${i}-${j}`} align="left" className={classes.fullWidthCell}>
              <Skeleton height={30} width="100%" />
            </NestedTable.Cell>
          ))}
        </NestedTable.Row>
      ))}
    </>
  );
};

export default Placeholder;
