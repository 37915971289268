import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  headerLayout: {
    marginBottom: '0px',
  },

  root: {
    paddingTop: theme.spacing(4),
    display: 'flex',
    width: ({ openDrawer }) => `calc(100% - ${openDrawer ? '496px' : '0px'})`,
    height: ({ isModeDraft }) => `calc(100vh - ${theme.typography.pxToRem(isModeDraft ? 193 : 137)})`,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: ({ openDrawer }) => `${openDrawer ? '0px' : '100%'}`,
    },
  },

  contentMenus: {
    width: '100%',
  },

  containerMessageError: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    maxHeight: 500,
  },
}));

export default useStyles;
