import React from 'react';

import withStyles from '@mui/styles/withStyles';
import TabsMui from '@mui/material/Tabs';

import Tab from './Tab';

const StyledTabs = withStyles((theme) => ({
  root: {
    minHeight: 0,
  },
  flexContainer: {
    gap: 32,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    '& > span': {
      width: '100%',
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))((props) => <TabsMui {...props} TabIndicatorProps={{ children: <span /> }} />);

function Tabs({ ...others }) {
  return (
    <StyledTabs
      aria-label="navigation tabs"
      indicatorColor="secondary"
      scrollButtons="auto"
      textColor="primary"
      variant="scrollable"
      {...others}
    />
  );
}

Tabs.Tab = Tab;

Tabs.propTypes = {
  ...TabsMui.propTypes,
};

export default Tabs;
