import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'lodash';

import StripedTable from '@experimental-components/StripedTable';

import { useFilters, useGetAverageTicketSummary } from 'containers/ReportAverageTicket/hooks';

import PageError from 'components/PageError';

import useGetCatalogReportFilters from 'hooks/reports/useGetCatalogReportFilters';

const Summary = () => {
  const { t } = useTranslation();
  const { filters } = useFilters();
  const reportFilters = useGetCatalogReportFilters({ enabled: false });
  const { data, dataStatus, loadAverageTicketSummary, removeAverageTicketSummary } = useGetAverageTicketSummary();

  useEffect(() => {
    if (reportFilters.data && !isEmpty(filters)) {
      loadAverageTicketSummary();
    }
  }, [filters]);

  useEffect(() => {
    return () => {
      removeAverageTicketSummary();
    };
  }, []);

  if (dataStatus.error) {
    return (
      <Box mt={6}>
        <PageError
          id="averageTicketSummaryError"
          labelAction={t('common:buttons.retry')}
          message={t('reports:messages.reportError')}
          onAction={loadAverageTicketSummary}
        />
      </Box>
    );
  }

  return (
    <Box mx={-4} pt={3}>
      <StripedTable
        loading={dataStatus.fetching || reportFilters.isFetching || !dataStatus.fetched}
        loadingRows={5}
        title={t('reports:resume.labels.title')}
      >
        {data?.summaries.map((summary) => (
          <StripedTable.Row key={summary.name}>
            <Typography>{summary.name || ''}</Typography>
            <Typography>{`${summary.symbol ?? ''}${summary.value}`}</Typography>
          </StripedTable.Row>
        ))}
      </StripedTable>
    </Box>
  );
};

export default Summary;
