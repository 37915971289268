import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { datadogLogs } from '@datadog/browser-logs';
import useUserStore from 'hooks/useUserStore';
import { useFeatures } from 'FeatureProvider';

import packageJson from '../../../../package.json';

function DatadogLogsProvider({ children }) {
  const { userState } = useUserStore();
  const { permissions } = useFeatures();

  useEffect(() => {
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DATADOG_CLIENTE_TOKEN,
      site: 'datadoghq.com',
      service: 'parrot-connect-web',
      version: packageJson.version,
      sampleRate: 100,
      forwardErrorsToLogs: false,
      silentMultipleInit: false,
    });
  }, []);

  useEffect(() => {
    if (userState) {
      const typeUser = permissions?.includes('adminStore') ? 'admin_store' : 'backoffice_user';
      datadogLogs.setUser({ id: userState?.uuid, email: userState?.email });
      datadogLogs.setUserProperty('type', typeUser);
    }
  }, [userState]);

  return React.Children.only(children);
}

DatadogLogsProvider.propTypes = {
  children: PropTypes.element,
};

export default DatadogLogsProvider;
