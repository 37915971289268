import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  row: {
    [theme.breakpoints.down('xl')]: {
      width: '50%',
    },

    [theme.breakpoints.down('xl')]: {
      width: '65%',
    },

    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },

  containerTimers: {
    display: 'inline-grid',
    '& > :not(:first-child)': {
      marginTop: theme.spacing(2),
    },
  },

  paper: {
    display: 'flex',
    backgroundColor: 'transparent',
    padding: theme.spacing(3),
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(4),
    },
  },

  paperDays: {
    display: 'flex',
    flexDirection: 'column',
  },

  containerProviders: {
    display: 'flex',
    marginTop: theme.spacing(1),
    '& > :not(:first-child)': {
      marginLeft: theme.spacing(2),
    },
  },

  title: {
    wordBreak: 'break-word',
  },

  subtitles: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(1),
  },

  wrapperExcel: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(1),
      justifyContent: 'flex-start',
    },
  },
}));

export default useStyles;
