/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import camelCase from 'lodash/camelCase';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';

import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';

import ChevronDown from '@experimental-components/IconsComponents/ChevronDown';

import { typeErrorsBeforeCopyMenus, orderBeforeCopyMenusErrors } from 'utils/menuMaker';

import useStyles from './styles';

function DetailsErrosOnBeforeCopyMenu({ data }) {
  const classes = useStyles();
  const { t } = useTranslation('menuMaker');

  const [openItem, setOpenItem] = useState([]);
  const validations = data?.validations ? orderBeforeCopyMenusErrors(data?.validations, 'PARROT') : {};

  const handleOpenDetails = (type) => {
    const openItemTmp = [...openItem];
    if (openItemTmp.includes(type)) {
      setOpenItem(openItemTmp?.filter((item) => item !== type));
    } else {
      openItemTmp.push(type);
      setOpenItem(openItemTmp);
    }
  };

  if (!data?.validations) {
    return null;
  }

  return (
    <List className={classes.root}>
      {data?.validations && (
        <>
          {validations?.CATEGORY?.length > 0 && (
            <>
              <ListItem button onClick={() => handleOpenDetails(typeErrorsBeforeCopyMenus.CATEGORY)}>
                <ListItemText
                  classes={{ primary: classes.textPrimaryHeaher }}
                  primary={t(`menuMaker:validateCopyMenu.labelsError.${camelCase(typeErrorsBeforeCopyMenus.CATEGORY)}`)}
                />
                <ChevronDown
                  className={clsx(classes.iconInitialState, {
                    [classes.iconRotate]: openItem.includes(typeErrorsBeforeCopyMenus.CATEGORY),
                  })}
                  color="#000"
                  lighter
                  size={16}
                />
              </ListItem>
              <Collapse in={openItem.includes(typeErrorsBeforeCopyMenus.CATEGORY)} timeout="auto" unmountOnExit>
                <List className={classes.subList} subheader={<li />}>
                  {validations?.CATEGORY?.map((error, index) => (
                    <li key={camelCase(`${index}-${error?.name}`)} className={classes.listSection}>
                      <ul className={classes.ul}>
                        <ListSubheader disableSticky>
                          <Box component="span" fontWeight="fontWeightMedium">
                            {error?.items.join(', ')}
                          </Box>
                        </ListSubheader>
                        <ListItem>
                          <ListItemText classes={{ primary: classes.detailsError }} primary={error?.message} />
                        </ListItem>
                      </ul>
                    </li>
                  ))}
                </List>
              </Collapse>
            </>
          )}
          {validations?.PRODUCT?.length > 0 && (
            <>
              <ListItem button onClick={() => handleOpenDetails(typeErrorsBeforeCopyMenus.PRODUCT)}>
                <ListItemText
                  classes={{ primary: classes.textPrimaryHeaher }}
                  primary={t(`menuMaker:validateCopyMenu.labelsError.${camelCase(typeErrorsBeforeCopyMenus.PRODUCT)}`)}
                />
                <ChevronDown
                  className={clsx(classes.iconInitialState, {
                    [classes.iconRotate]: openItem.includes(typeErrorsBeforeCopyMenus.PRODUCT),
                  })}
                  color="#000"
                  lighter
                  size={16}
                />
              </ListItem>
              <Collapse in={openItem.includes(typeErrorsBeforeCopyMenus.PRODUCT)} timeout="auto" unmountOnExit>
                <List className={classes.subList} subheader={<li />}>
                  {validations?.PRODUCT?.map((error, index) => (
                    <li key={camelCase(`${index}-${error?.name}`)} className={classes.listSection}>
                      <ul className={classes.ul}>
                        <ListSubheader disableSticky>
                          <Box component="span" fontWeight="fontWeightMedium">
                            {error?.items.join(', ')}
                          </Box>
                        </ListSubheader>
                        <ListItem>
                          <ListItemText classes={{ primary: classes.detailsError }} primary={error?.message} />
                        </ListItem>
                      </ul>
                    </li>
                  ))}
                </List>
              </Collapse>
            </>
          )}
          {validations?.MODIFIER_GROUP?.length > 0 && (
            <>
              <ListItem button onClick={() => handleOpenDetails(typeErrorsBeforeCopyMenus.MODIFIER_GROUP)}>
                <ListItemText
                  classes={{ primary: classes.textPrimaryHeaher }}
                  primary={t(
                    `menuMaker:validateCopyMenu.labelsError.${camelCase(typeErrorsBeforeCopyMenus.MODIFIER_GROUP)}`,
                  )}
                />
                <ChevronDown
                  className={clsx(classes.iconInitialState, {
                    [classes.iconRotate]: openItem.includes(typeErrorsBeforeCopyMenus.MODIFIER_GROUP),
                  })}
                  color="#000"
                  lighter
                  size={16}
                />
              </ListItem>
              <Collapse in={openItem.includes(typeErrorsBeforeCopyMenus.MODIFIER_GROUP)} timeout="auto" unmountOnExit>
                <List className={classes.subList} subheader={<li />}>
                  {validations?.MODIFIER_GROUP?.map((error, index) => (
                    <li key={camelCase(`${index}-${error?.name}`)} className={classes.listSection}>
                      <ul className={classes.ul}>
                        <ListSubheader disableSticky>
                          <Box component="span" fontWeight="fontWeightMedium">
                            {error?.items.join(', ')}
                          </Box>
                        </ListSubheader>
                        <ListItem>
                          <ListItemText classes={{ primary: classes.detailsError }} primary={error?.message} />
                        </ListItem>
                      </ul>
                    </li>
                  ))}
                </List>
              </Collapse>
            </>
          )}
        </>
      )}
    </List>
  );
}

DetailsErrosOnBeforeCopyMenu.propTypes = {
  data: PropTypes.object,
};

export default DetailsErrosOnBeforeCopyMenu;
