import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import MenuMui, { MenuProps } from '@mui/material/Menu';
import MenuItemMui from '@mui/material/MenuItem';
import ListItemTextMui from '@mui/material/ListItemText';
import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  menuPaper: {
    background: theme.palette.background.default,
    boxShadow: `0px 8px 40px  ${theme.palette.darkGrey[5]}`,
    borderRadius: 4,
    padding: '6px 0',
    width: '100%',
    maxWidth: 200,
    minWidth: 200,
  },
}));

const MenuItem = withStyles(() => ({
  root: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
  },
}))(MenuItemMui);

const MenuItemText = withStyles((theme: Theme) => ({
  primary: {
    fontSize: theme.typography.pxToRem(14),
  },
  secondary: {
    fontSize: theme.typography.pxToRem(14),
  },
}))(ListItemTextMui);

interface Props extends MenuProps {
  children: React.ReactNode;
}

function Menu({ children, classes: classesProp, ...others }: Props) {
  const classes = useStyles();

  return (
    <MenuMui
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      classes={{ paper: classes.menuPaper, ...classesProp }}
      elevation={0}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      {...others}
    >
      {children}
    </MenuMui>
  );
}

Menu.Item = MenuItem;
Menu.ItemText = MenuItemText;

export default Menu;
