const DANGER = 'DANGER';
const SUCCESS = 'SUCCESS';

export const statesTooltipsPercentage = {
  DANGER,
  SUCCESS,
};

function clearFormat(value) {
  let newValue = value;

  if (value && typeof value === 'string') {
    newValue = value?.replace(/,/g, '');
  }

  return newValue;
}

export function getDataGraphPie(currentData) {
  const dataGraph = [];

  currentData?.forEach((element) => {
    dataGraph.push({
      id: element?.index,
      label: element?.name,
      value: parseFloat(clearFormat(element?.data[0]?.value)),
      symbol: element?.data[0]?.symbol,
      color: element?.data[0]?.color,
      tooltip: element?.data[0]?.tooltip,
    });
  });

  return dataGraph;
}

export const colors = ['#F04E4A', '#44AEC8', '#47465F', '#F6BD2C'];

export function kFormatter(num) {
  if (Math.abs(num) > 999999) {
    return `${Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1)}M`;
  }

  if (Math.abs(num) > 999) {
    return `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}K`;
  }

  return Math.sign(num) * Math.abs(num);
}

export const themeChartBars = {
  textColor: '#47465f',
  fontSize: 10,
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  labels: {
    text: {
      fontWeight: 'bold',
      fill: 'black',
      fontSize: 12,
    },
  },
  axis: {
    domain: {
      line: {
        stroke: '#CECDD1',
        strokeWidth: 1.5,
      },
    },
    ticks: {
      line: {
        stroke: '#CECDD1',
        strokeWidth: 2,
      },
    },
    legend: {
      text: {
        fill: '#000',
        fontSize: 18,
      },
    },
  },
  grid: {
    line: {
      stroke: '#CECDD1',
      strokeWidth: 0.8,
      strokeDasharray: '2,2',
    },
  },
};

function convertDataY(data) {
  return data?.map((item) => {
    item.y = item?.y ? parseFloat(clearFormat(item?.y)) : null;

    return item;
  });
}

export function getDataGraph(currentData) {
  const currentDataTmp = JSON.parse(JSON.stringify(currentData));
  let dataGraph = [];

  dataGraph = currentDataTmp?.map((layer) => {
    layer.id = layer?.name;
    layer.label = layer?.name;
    layer.data = convertDataY(layer?.data);

    return layer;
  });

  return dataGraph || [];
}

export function parseMessageTooltipProducts(composeMessage, value) {
  let message = composeMessage[0];

  if (value?.length <= 5) {
    message += value?.map((item) => item).join(', ');
  }

  if (value?.length > 5) {
    const subArray = value?.slice(0, 5);
    message += subArray?.map((item) => item).join(', ');
    message += `, +${value?.length - 5} ${composeMessage[1]}`;
  }

  return message;
}
