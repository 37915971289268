import withStyles from '@mui/styles/withStyles';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import type { Theme } from '@mui/material/styles';

const ButtonPrimaryBase = withStyles((theme: Theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: theme.typography.pxToRem(16),
    border: 'none',
    lineHeight: '16px',
    height: theme.typography.pxToRem(42),
    minWidth: theme.typography.pxToRem(133),
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,

    '&:hover': {
      backgroundColor: theme.palette.error.dark,
      boxShadow: 'none',
    },

    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.error.dark,
    },

    '&:focus': {
      border: `1px solid ${theme.palette.error.dark}`,
    },

    '&$disabled': {
      color: theme.palette.common.white,
    },
  },

  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.common.white,
  },
}))(Button);

interface Props extends ButtonProps {
  loading?: boolean;
}

function ButtonAlert({ loading, ...others }: Props) {
  return (
    <ButtonPrimaryBase
      disableElevation
      disableRipple
      endIcon={loading && <CircularProgress color="inherit" size={18} />}
      {...others}
    />
  );
}

export default ButtonAlert;
