import withStyles from '@mui/styles/withStyles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import type { Theme } from '@mui/material/styles';

interface Props extends OutlinedInputProps {
  disabled?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  hideLabel?: boolean;
  label?: string | null;
  hideLabelHelperText?: boolean;
  helperText?: string;
  errorMsg?: string;
}

type InputRootProps = {
  disabled?: boolean;
  error?: boolean;
  label?: string;
};

const InputRoot = withStyles((theme: Theme) => {
  const placeholder = {
    color: (props: InputRootProps) => (props.disabled ? theme.palette.darkGrey[15] : '#757575'),
    opacity: 1,
  };

  const getBorder = (props: InputRootProps) => {
    if (props?.disabled) {
      return `1px solid ${theme.palette.darkGrey[15]}`;
    }

    if (props?.error) {
      return `1px solid ${theme.palette.error.main}`;
    }

    return `1px solid ${theme.palette.common.black}`;
  };

  const borderColor = (props: InputRootProps) => getBorder(props);

  const getBorderColor = (props: InputRootProps) => {
    if (props?.disabled) {
      return theme.palette.darkGrey[15];
    }

    if (props?.error) {
      return theme.palette.error.main;
    }

    return theme.palette.darkGrey[50];
  };

  const getBackgroundColor = (props: InputRootProps) => {
    if (props?.disabled) {
      return theme.palette.darkGrey[2];
    }

    if (props?.error) {
      return theme.palette.error.light;
    }

    return theme.palette.common.white;
  };

  return {
    root: {
      backgroundColor: (props) => getBackgroundColor(props),
      borderRadius: theme.shape.borderRadius,
      minHeight: 42,
      '&:hover $notchedOutline': {
        borderColor: (props: InputRootProps) => getBorder(props),
        borderWidth: 1,
      },

      '@media (hover: none)': {
        '&:hover $notchedOutline': {
          borderColor: (props: InputRootProps) => getBorderColor(props),
          borderWidth: 1,
        },
      },
      '&$focused $notchedOutline': {
        borderColor,
        borderWidth: 1,
      },
      '&$error $notchedOutline': {
        borderColor,
        borderWidth: 1,
      },
      '&$disabled $notchedOutline': {
        borderColor: (props: InputRootProps) => getBorderColor(props),
        borderWidth: 1,
      },

      'label + &': {
        marginTop: (props: InputRootProps) => (props?.label ? theme.spacing(1) : 0),
      },
    },

    notchedOutline: {
      borderColor: (props) => getBorderColor(props),
      borderWidth: 1,
    },

    focused: {
      borderWidth: 1,
    },

    disabled: {
      color: theme.palette.darkGrey[15],
    },

    error: {
      color: theme.palette.error.main,
    },

    input: {
      padding: '10px 12px',
      paddingRight: 0,
      position: 'relative',
      fontSize: '14px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&::-webkit-input-placeholder': placeholder,
      cursor: (props: Props) => (props.onClick && props.endAdornment ? 'pointer' : 'normal'),
    },

    inputAdornedStart: {
      paddingLeft: 0,
      paddingRight: 12,
    },
    adornedEnd: {
      paddingRight: 12,
    },
  };
})(OutlinedInput);

const InputLabelRoot = withStyles((theme: Theme) => ({
  root: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.common.black,
    position: 'relative',
  },

  shrink: {
    transform: 'translate(0, 1.5px) scale(1)',
    transformOrigin: 'top left',
  },

  disabled: {
    color: theme.palette.darkGrey[50],
  },
}))(InputLabel);

const FormHelperTextRoot = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.darkGrey.main,
    fontSize: 12,
    lineHeight: 1.2,
  },
}))(FormHelperText);

function InputAdornmentField({
  id,
  error,
  fullWidth,
  label = null,
  helperText,
  errorMsg,
  disabled,
  required,
  hideLabel,
  hideLabelHelperText,
  ...others
}: Props) {
  return (
    <FormControl disabled={disabled} error={error} fullWidth={fullWidth} required={required} variant="standard">
      {!hideLabel && (
        <InputLabelRoot htmlFor={id} shrink>
          {label}
        </InputLabelRoot>
      )}

      <InputRoot
        disabled={disabled}
        error={error}
        id={id}
        label={label}
        notched={false}
        required={required}
        {...others}
      />
      {!hideLabelHelperText && (errorMsg || helperText) && (
        <FormHelperTextRoot id={`${id}HelperText`}>{errorMsg || helperText}</FormHelperTextRoot>
      )}
    </FormControl>
  );
}

export default InputAdornmentField;
