// @ts-nocheck
import styled from '@emotion/styled';

interface AmountProps {
  lineThrough?: boolean;
}
const Amount = styled.span`
  text-decoration: ${(props: AmountProps) => (props.lineThrough ? 'line-through' : 'none')}; ;
`;

export type CurrencyCode = keyof typeof currency;

const currency = {
  MXN: {
    symbol: '$',
  },
  USD: {
    symbol: '$',
  },
  PEN: {
    symbol: 's/',
  },
};

export function getCurrencySymbol(code: CurrencyCode) {
  return currency[code].symbol || '$';
}

export function formatCurrencyByCode(amount: number | null | undefined, code: CurrencyCode, lineThrough?: boolean) {
  if (!amount) return '-';

  const symbol = getCurrencySymbol(code);
  const isNegative = amount < 0;
  const absAmount = Math.abs(amount).toFixed(2);

  return (
    <Amount lineThrough={lineThrough}>
      {isNegative ? '-' : ''}
      {symbol}
      {absAmount}
    </Amount>
  );
}

export function formatCurrency(
  amount: number | string | undefined | null,
  options?: {
    currencyCode?: CurrencyCode;
    defaultValue?: string;
    forceUseDefaultValue?: boolean;
    withLineThrough?: boolean;
    isNegative?: boolean;
    isPositive?: boolean;
  },
) {
  const symbol = getCurrencySymbol(options?.currencyCode || 'MXN');
  if (!amount || options?.forceUseDefaultValue) {
    if (options?.isNegative && options?.defaultValue) {
      return `- ${options?.defaultValue}`;
    }
    if (options?.isPositive && options?.defaultValue) {
      return `+ ${options?.defaultValue}`;
    }
    return options?.defaultValue || '-';
  }

  const isNegative = options?.isNegative || Number(amount) < 0;
  const isPositive = options?.isPositive;
  const amountParsed = Math.abs(Number(amount)).toLocaleString('es-MX', {
    minimumFractionDigits: 2,
  });

  return (
    <Amount lineThrough={options?.withLineThrough}>
      {isNegative ? '-' : ''}
      {isPositive ? '+' : ''}
      {symbol}
      {amountParsed}
    </Amount>
  );
}

export function removePriceSymbol(string: string | number) {
  if (string && typeof string === 'string') {
    return string.replace(/[$]/g, '');
  }

  return string;
}

export function maxValueAllowed(maxValue: number) {
  return ({ value }: { value: number }) => value <= maxValue;
}
