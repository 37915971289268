import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';

interface Props {
  colorIcon?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
  },

  wrapperColors: {
    display: 'grid',
    gridAutoFlow: 'column',
  },

  radioRoot: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  radioIcon: {
    borderRadius: '50%',
    width: 40,
    height: 40,
    border: `1px solid ${theme.palette.darkGrey[15]}`,
    backgroundColor: (props: Props) => `#${props.colorIcon}`,
  },

  radioChecked: {
    outline: '1px solid #000',
    outlineOffset: 8,
  },
}));

export default useStyles;
