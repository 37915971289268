import request from 'utils/request';

export const API_BASE_URL = `${process.env.REACT_APP_BASE_URL_API}/api`;

export function getEmployeesRequest({ storeUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/users/`, {
    method: 'GET',
    ...options,
  });
}

// employee
export function getEmployeeRequest({ storeUuid, employeeUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/users/${employeeUuid}/`, {
    method: 'GET',
    ...options,
  });
}

export function updateEmployeeRequest(
  {
    storeUuid,
    employeeUuid,
    password,
    email,
    firstName,
    lastName,
    phoneNumber,
    posPin,
    permissions,
    jobUuids,
    profile,
  },
  options = {},
) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/users/${employeeUuid}/`, {
    method: 'PUT',
    body: JSON.stringify({
      password,
      email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      pos_pin: posPin,
      permissions,
      job_uuids: jobUuids,
      profile,
    }),
    ...options,
  });
}

export function createEmployeeRequest(
  { storeUuid, password, email, firstName, lastName, phoneNumber, posPin, permissions, jobUuids, profile },
  options = {},
) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/users/`, {
    method: 'POST',
    body: JSON.stringify({
      password,
      email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phoneNumber,
      pos_pin: posPin,
      permissions,
      job_uuids: jobUuids,
      profile,
    }),
    ...options,
  });
}

export function updatePassportRequest({ storeUuid, employeeUuid, password }, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/users/${employeeUuid}/update-password/`, {
    method: 'PUT',
    body: JSON.stringify({
      password,
    }),
    ...options,
  });
}
export function deleteEmployeeRequest({ storeUuid, employeeUuid }, options = {}) {
  return request(`${API_BASE_URL}/v1/stores/${storeUuid}/users/${employeeUuid}/`, {
    method: 'DELETE',
    ...options,
  });
}
