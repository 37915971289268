import { memo, useEffect } from 'react';
import { compose } from 'redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import FeatureProvider from 'FeatureProvider';
import { useQueryParams } from 'hooks';
import useRemoteConfigFlags from 'hooks/useRemoteConfigFlags';

import { useActiveLicense } from 'containers/Subscription/hooks';
import { useUser } from 'containers/Users';

import {
  getListPermissions,
  getActivesModules,
  setAuthToken,
  setUserCurrentStore,
  setCookieUser,
  setOrganizationUser,
  getAuthToken,
  setStoreDraftUser,
  setUserStoresStoreKey,
  getUserStoresStoreKey,
  getUserCurrentStore,
} from 'utils/auth';

import { useProfile } from 'hooks/useProfile';

import { useHistory } from 'react-router';

interface Params {
  token: string;
  storeUuid: string;
  userUuid: string;
  organizationUuid: string;
  userEmail: string;
}

interface Props {
  children: React.ReactNode;
}

export function AuthProvider({ children }: Props) {
  const { queryParams } = useQueryParams<Params>();
  const { data: license, isLoading: isLoadingLicense } = useActiveLicense();
  const { profileData, getProfileData, profileDataState } = useProfile({ isEnabled: false });
  const { data: user, isLoading: isLoadingUser, refetch: refetchUser } = useUser();
  const { stateFlags, flags } = useRemoteConfigFlags();
  const { token, storeUuid, userUuid, organizationUuid, userEmail } = queryParams;
  const tokens = getAuthToken();
  const storesData = getUserStoresStoreKey();
  const currentStore = getUserCurrentStore();

  const history = useHistory();

  useEffect(() => {
    if (token && storeUuid && userUuid && organizationUuid && userEmail) {
      setAuthToken({
        accessToken: token,
        refreshToken: '',
      });
      setUserCurrentStore({
        isDraft: false,
        uuid: storeUuid,
        name: '',
        secret: '',
        legacyId: '',
        jobs: [],
        organizationUuid,
      });
      setCookieUser({
        uuid: userUuid || '',
        email: userEmail || '',
        firstName: '',
        lastName: '',
      });

      window.location.replace('/');
    }
  }, [token, storeUuid, userUuid, organizationUuid]);

  useEffect(() => {
    if (
      tokens?.accessToken &&
      (profileData === undefined || storesData.length === 0) &&
      (currentStore === null || currentStore?.name === '')
    ) {
      getProfileData();
      refetchUser();
    }
  }, [tokens]);

  useEffect(() => {
    if (profileData !== undefined) {
      const { profile, stores, storeDraft, organization } = profileData;
      if (!stores?.length) {
        return history.push({
          pathname: `/without-store`,
        });
      }

      const storesTmp = stores?.map((store) => ({ ...store, isDraft: false }));
      setUserCurrentStore(storesTmp[0]);

      if (storeDraft) {
        setStoreDraftUser({ ...storeDraft, isDraft: true });
      }

      setUserStoresStoreKey(storesTmp);
      setCookieUser(profile);
      setOrganizationUser(organization);
    }
  }, [profileData]);

  if (isLoadingLicense || isLoadingUser || profileDataState.isFetching || stateFlags.loading || !stateFlags.loaded) {
    return (
      <Backdrop open>
        <CircularProgress color="secondary" />
      </Backdrop>
    );
  }

  const features =
    user && license ? [...getListPermissions(user.permissions), ...getActivesModules(license.modules)] : [];

  return <FeatureProvider features={{ permissions: features, flags }}>{children}</FeatureProvider>;
}

export { default as useAuth } from './useAuth';
export { default as withRequiredLicense } from './withRequiredLicense';
export default compose(memo)(AuthProvider);
