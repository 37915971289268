import { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Box, Divider, Grid, InputAdornment, Typography } from '@mui/material';
import { capitalize, sortBy, isEqual } from 'lodash';

import IconClose from '@experimental-components/IconsComponents/Close';
import IconButton from '@design-system/IconButton';
import InputAdornmentField from '@design-system/InputAdornmentFiled';
import TimeCustomPicker from '@design-system/Time';
import ButtonPlus from '@experimental-components/ButtonPlus';

import GeneralDrawer from 'components/GeneralDrawer';
import ModalConfirm from 'components/ModalConfirm';

import {
  formDefinition,
  getInitSchedules,
  validateSchedulesForm,
  cleanErrorsOfName,
  setErrorsOfName,
  isHourValid,
  cleanErrorsOfHours,
  setErrorsOfHours,
  scheduleColors,
  defaultSchedule,
} from 'utils/reports/salesPerTime';
import { InputBaseOnChange } from 'utils/eventsTypes';

import { SchedulesType, SchedulesFormInputs } from './types';
import useStyles from './styles';

interface Props {
  openDrawer: boolean;
  onToggleDrawer: () => void;
  onSubmit: (values: SchedulesType[]) => void;
  schedulesData?: SchedulesType[];
  isSaveSchedulesFetching: boolean;
  t: (value: string, options?: { chars: number }) => string;
}

const ScheduleDrawer = ({ onSubmit, openDrawer, onToggleDrawer, schedulesData, t, isSaveSchedulesFetching }: Props) => {
  const [initSchedule, setInitSchedule] = useState(defaultSchedule);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const classes = useStyles({});
  const { register, control, errors, setError, reset, watch, clearErrors, handleSubmit } = useForm<SchedulesFormInputs>(
    {
      mode: 'onChange',
    },
  );
  const { fields, append, remove } = useFieldArray({ control, name: 'scheduleFields' });

  const watchFields = watch();
  const isFormValid = validateSchedulesForm({ schedulesValues: watchFields, errors });
  const isFormDirty = !isEqual(watchFields.scheduleFields, initSchedule);

  useEffect(() => {
    if (openDrawer) {
      const scheduleValues = getInitSchedules(schedulesData);
      setInitSchedule(scheduleValues);
      reset({ scheduleFields: scheduleValues });
    }
  }, [openDrawer]);

  const handleRemoveSchedule = (index: number) => {
    remove(index);
    cleanErrorsOfName({ watchFields, errors, clearErrors });
    cleanErrorsOfHours({ watchFields, clearErrors });

    const updatedWatchFields = watchFields.scheduleFields.filter((_, i) => i !== index);
    const scheduleNameList = updatedWatchFields.map((item) => item.scheduleNameField);

    setErrorsOfName({ scheduleNameList, errors, setError, t });
    setErrorsOfHours({ scheduleHourList: updatedWatchFields, setError });
  };

  const validateRepeatedNames = (currentValue: string, currentIndex: number) => {
    if (watchFields.scheduleFields.length > 1) {
      cleanErrorsOfName({ watchFields, errors, clearErrors });

      const scheduleNameList = watchFields.scheduleFields.map((item) => item.scheduleNameField);
      scheduleNameList[currentIndex] = currentValue;

      setErrorsOfName({ scheduleNameList, errors, setError, t });

      if (
        errors?.scheduleFields?.length &&
        errors?.scheduleFields[currentIndex]?.scheduleNameField?.type === 'required'
      ) {
        if (currentValue.length !== 0) {
          clearErrors(`scheduleFields[${currentIndex}].scheduleNameField`);
          return true;
        }

        return t(formDefinition.scheduleNameField.register.required);
      }

      return (
        !(errors?.scheduleFields?.length && errors?.scheduleFields[currentIndex]?.scheduleNameField) ||
        t(formDefinition.scheduleNameField.register.repeatedNames)
      );
    }

    return true;
  };

  const validateAllowedHours = (currentHour: string, currentIndex: number, fieldName: string) => {
    if (isHourValid(currentHour)) {
      cleanErrorsOfHours({ watchFields, clearErrors });

      const scheduleHourList = [...watchFields.scheduleFields];
      scheduleHourList[currentIndex][fieldName] = currentHour;

      setErrorsOfHours({ scheduleHourList, setError });

      if (
        isHourValid(scheduleHourList[currentIndex].startHoursField) &&
        isHourValid(scheduleHourList[currentIndex].endHoursField) &&
        scheduleHourList[currentIndex].startHoursField === scheduleHourList[currentIndex].endHoursField
      ) {
        return false;
      }
    }

    return true;
  };

  const isErrorHours = (index: number) => {
    return Boolean(
      errors?.scheduleFields?.length &&
        (errors?.scheduleFields[index]?.startHoursField || errors?.scheduleFields[index]?.endHoursField),
    );
  };

  const handleAppendSchedule = () => {
    append({ scheduleNameField: '', startHoursField: '', endHoursField: '' });
  };

  const handleTextTransform = (event: InputBaseOnChange, onChange: (value: string) => void) => {
    event.target.value = capitalize(event.target.value);
    onChange(event.target.value);
    return event.target.value;
  };

  const handleOnTogleDrawer = () => {
    if (isFormDirty) {
      return setOpenConfirmationModal(true);
    }

    reset({});
    onToggleDrawer();
  };

  const handleOnAcceptExitWithoutSaveChanges = () => {
    setOpenConfirmationModal(false);
    onToggleDrawer();
  };

  const handleSaveSchedules = (data: SchedulesFormInputs) => {
    const dataSorted = sortBy(data.scheduleFields, ['startHoursField']);
    const schedulesToSave = dataSorted.map((item, index) => {
      return {
        uuid: item.uuidField,
        name: item.scheduleNameField,
        startHour: item.startHoursField,
        endHour: item.endHoursField,
        color: scheduleColors[index],
        status: 'ACTIVE',
      };
    });

    initSchedule.forEach((originItem) => {
      const itemEliminated = !schedulesToSave.some((item) => item.uuid === originItem.uuidField);
      if (itemEliminated) {
        schedulesToSave.push({
          uuid: originItem.uuidField,
          name: originItem.scheduleNameField,
          startHour: originItem.startHoursField,
          endHour: originItem.endHoursField,
          color: 'FFF',
          status: 'DELETED',
        });
      }
    });

    onSubmit(schedulesToSave);
  };

  const renderTitle = () => {
    return (
      <Box>
        <Typography className={classes.titleDrawer}>{t('salesPerTimeReport.form.title')}</Typography>
        <Typography className={classes.descriptionDrawer}>{t('salesPerTimeReport.form.description')}</Typography>
      </Box>
    );
  };

  return (
    <>
      <GeneralDrawer fullDrawer onClose={handleOnTogleDrawer} open={openDrawer}>
        <GeneralDrawer.MainView
          disabled={!(isFormValid && isFormDirty) || isSaveSchedulesFetching}
          labelAction={t('common:buttons.save')}
          loading={isSaveSchedulesFetching}
          onAction={handleSubmit(handleSaveSchedules)}
          onClose={handleOnTogleDrawer}
        >
          <Grid container direction="column" spacing={3}>
            <Grid item>{renderTitle()}</Grid>

            <Grid item>
              {fields.map((field, index) => (
                <Grid key={field.id} container direction="column" spacing={3}>
                  {index !== 0 && (
                    <Grid item>
                      <Box className={classes.close}>
                        <IconButton onClick={() => handleRemoveSchedule(index)} size="large">
                          <IconClose lighter size={16} />
                        </IconButton>
                      </Box>
                    </Grid>
                  )}

                  <Grid item>
                    <input
                      ref={register()}
                      defaultValue={field.uuidField}
                      name={`scheduleFields[${index}].uuidField`}
                      type="hidden"
                    />

                    <Controller
                      control={control}
                      defaultValue={field.scheduleNameField}
                      name={`scheduleFields[${index}].${formDefinition.scheduleNameField.name}`}
                      render={({ value, onChange, name, ref }) => (
                        <InputAdornmentField
                          {...formDefinition.scheduleNameField}
                          autoComplete="off"
                          autoFocus
                          endAdornment={
                            <InputAdornment position="end">
                              <Box color="#CECDD1" fontSize="14px">
                                {watchFields?.scheduleFields[index]?.scheduleNameField?.length ?? 0}/15
                              </Box>
                            </InputAdornment>
                          }
                          error={Boolean(
                            errors?.scheduleFields?.length && errors?.scheduleFields[index]?.scheduleNameField,
                          )}
                          errorMsg={
                            errors?.scheduleFields?.length
                              ? errors?.scheduleFields[index]?.scheduleNameField?.message
                              : ''
                          }
                          fullWidth
                          inputProps={{ maxLength: formDefinition.scheduleNameField.maxLength }}
                          inputRef={ref}
                          label={t(formDefinition.scheduleNameField.label)}
                          name={name}
                          onChange={(event) => handleTextTransform(event, onChange)}
                          value={value}
                        />
                      )}
                      rules={{
                        required: t(formDefinition.scheduleNameField.register.required),
                        validate: (value: string) => validateRepeatedNames(value, index),
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          defaultValue={field?.startHoursField}
                          name={`scheduleFields[${index}].${formDefinition.startHoursField.name}`}
                          render={({ value, onChange }) => (
                            <TimeCustomPicker
                              error={isErrorHours(index)}
                              fullWidth
                              label={t(formDefinition.startHoursField.label)}
                              onChange={onChange}
                              value={value}
                            />
                          )}
                          rules={{
                            required: true,
                            validate: (value: string) => validateAllowedHours(value, index, 'startHoursField'),
                          }}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          defaultValue={field?.endHoursField}
                          name={`scheduleFields[${index}].${formDefinition.endHoursField.name}`}
                          render={({ value, onChange }) => (
                            <TimeCustomPicker
                              error={isErrorHours(index)}
                              fullWidth
                              label={t(formDefinition.endHoursField.label)}
                              onChange={onChange}
                              value={value}
                            />
                          )}
                          rules={{
                            required: true,
                            validate: (value: string) => validateAllowedHours(value, index, 'endHoursField'),
                          }}
                        />
                      </Grid>

                      {errors?.scheduleFields &&
                        (errors?.scheduleFields[index]?.startHoursField ||
                          errors?.scheduleFields[index]?.endHoursField) && (
                          <Box className={classes.scheduleError}>
                            <Typography>{t('salesPerTimeReport.form.errors.overlapHours')}</Typography>
                          </Box>
                        )}
                    </Grid>
                  </Grid>

                  {index !== fields.length - 1 && (
                    <Grid item>
                      <Divider />
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>

            {fields.length < 3 && (
              <Grid item>
                <ButtonPlus isDisabled={false} onClick={handleAppendSchedule}>
                  {t('salesPerTimeReport.buttons.addSchedule')}
                </ButtonPlus>
              </Grid>
            )}
          </Grid>
        </GeneralDrawer.MainView>
      </GeneralDrawer>

      {openConfirmationModal && (
        <ModalConfirm
          formNameForAction=""
          labelAccept={t('common:buttons.accept')}
          labelCancel={t('common:buttons.cancel')}
          loading={false}
          message={t('salesPerTimeReport.modalWithoutSaveChanges.description')}
          onAccept={handleOnAcceptExitWithoutSaveChanges}
          onCancel={() => setOpenConfirmationModal(false)}
          open={openConfirmationModal}
          title={t('salesPerTimeReport.modalWithoutSaveChanges.title')}
        />
      )}
    </>
  );
};

export default ScheduleDrawer;
