import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';

const CASH_MOVEMENTS = 'CASH_MOVEMENTS';
const TOTAL_SALES = 'TOTAL_SALES';
const SALES_BY_PROVIDER = 'SALES_BY_PROVIDER';
const SALES_BY_PAYMENT_TYPE = 'SALES_BY_PAYMENT_TYPE';
const ORDERS_SUMMARY = 'ORDERS_SUMMARY';
const DISCOUNTS_SUMMARY = 'DISCOUNTS_SUMMARY';
const SERVICE_CHARGES = 'SERVICE_CHARGES';
const CANCELLATIONS = 'CANCELLATIONS';
const SALES_BY_ORDER_TYPE = 'SALES_BY_ORDER_TYPE';
const SALES_BY_BRAND = 'SALES_BY_BRAND';
const SALES_SUMMARY = 'SALES_SUMMARY';
const TAXES_SUMMARY = 'TAX_SUMMARY';
const TIP_SUMMARY = 'TIP_TOTAL_SUMMARY';
const SALES_BY_USER = 'SALES_BY_USER';

const cashMovements = 'cashMovements';
const totalSales = 'totalSales';
const salesByProvider = 'salesByProvider';
const salesByPaymentType = 'salesByPaymentType';
const ordersSummary = 'ordersSummary';
const discountsSummary = 'discountsSummary';
const servicesCharges = 'servicesCharges';
const cancellations = 'cancellations';
const salesByOrderType = 'salesByOrderType';
const salesByBrand = 'salesByBrand';
const salesSummary = 'salesSummary';
const tipSummary = 'tipSummary';
const taxesSummary = 'taxesSummary';
const salesByUsers = 'salesByUsers';

export const sectionsCashRegisterSession = [
  CASH_MOVEMENTS,
  TOTAL_SALES,
  SALES_BY_USER,
  SALES_BY_PROVIDER,
  SALES_BY_PAYMENT_TYPE,
  ORDERS_SUMMARY,
  DISCOUNTS_SUMMARY,
  SERVICE_CHARGES,
  CANCELLATIONS,
  SALES_BY_ORDER_TYPE,
  SALES_BY_BRAND,
  TIP_SUMMARY,
  TAXES_SUMMARY,
  SALES_SUMMARY,
];

export const sectionsCashRegisterSessionTypes = {
  CASH_MOVEMENTS,
  TOTAL_SALES,
  SALES_BY_PROVIDER,
  SALES_BY_PAYMENT_TYPE,
  ORDERS_SUMMARY,
  DISCOUNTS_SUMMARY,
  SERVICE_CHARGES,
  CANCELLATIONS,
  SALES_BY_ORDER_TYPE,
  SALES_BY_BRAND,
  SALES_SUMMARY,
  TIP_SUMMARY,
  TAXES_SUMMARY,
  SALES_BY_USER,
};

export const sectionsCashRegisterSessionObject = {
  [CASH_MOVEMENTS]: cashMovements,
  [TOTAL_SALES]: totalSales,
  [SALES_BY_PROVIDER]: salesByProvider,
  [SALES_BY_PAYMENT_TYPE]: salesByPaymentType,
  [ORDERS_SUMMARY]: ordersSummary,
  [DISCOUNTS_SUMMARY]: discountsSummary,
  [SERVICE_CHARGES]: servicesCharges,
  [CANCELLATIONS]: cancellations,
  [SALES_BY_ORDER_TYPE]: salesByOrderType,
  [SALES_BY_BRAND]: salesByBrand,
  [SALES_SUMMARY]: salesSummary,
  [TIP_SUMMARY]: tipSummary,
  [TAXES_SUMMARY]: taxesSummary,
  [SALES_BY_USER]: salesByUsers,
};

export const sectionsCashRegisterSessionReducer = {
  cashMovements: { isLoaded: false },
  totalSales: { isLoaded: false },
  salesByProvider: { isLoaded: false },
  salesByPaymentType: { isLoaded: false },
  ordersSummary: { isLoaded: false },
  discountsSummary: { isLoaded: false },
  servicesCharges: { isLoaded: false },
  cancellations: { isLoaded: false },
  salesByOrderType: { isLoaded: false },
  salesByBrand: { isLoaded: false },
  salesSummary: { isLoaded: false },
  tipSummary: { isLoaded: false },
  taxesSummary: { isLoaded: false },
  salesByUsers: { isLoaded: false },
};

export function numberIsOdd(num) {
  return num % 2;
}

export function validateProvidersInOrders(providers) {
  let countKeysEmpty = 0;
  forEach(providers, (data) => {
    if (isEmpty(data)) countKeysEmpty += 1;
  });

  return countKeysEmpty > 0 ?? false;
}

export function removeSectionsCashRegisterSession(isSummaryByUserEnabled = false) {
  const newSections = [...sectionsCashRegisterSession];
  const excludes = [];
  if (!isSummaryByUserEnabled) {
    excludes.push(SALES_BY_USER);
  }
  return newSections.filter((section) => !excludes.includes(section));
}
