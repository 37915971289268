import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SignInLayout from 'components/SignInLayout';

import { ReactComponent as IconParrotPOS } from 'images/svg/brand/parrot_connect_large.svg';

import useStyles from './styles';

function WithoutStore({ t }) {
  const classes = useStyles();

  return (
    <SignInLayout>
      <div className={classes.header}>
        <IconParrotPOS className={classes.parrotS} />
      </div>

      <Box mb={2} mt={4}>
        <Typography gutterBottom variant="h6">
          {t('common:auth.withoutStore.title')}
        </Typography>
      </Box>

      <Box mb={2} width="80%">
        <Typography align="center" color="textSecondary" variant="subtitle1">
          {t('common:auth.withoutStore.message')}
        </Typography>
      </Box>
    </SignInLayout>
  );
}

WithoutStore.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(WithoutStore);
