import { call, put } from 'redux-saga/effects';

import { push } from 'connected-react-router';

import {
  getAuthToken,
  removeAuthToken,
  removeCookieUser,
  removeOrganizationUser,
  removeStoreBackupKey,
  removeStoreDratfKey,
  removeUserCurrentStore,
  removeUserStoresStoreKey,
} from 'utils/auth';

import { removeBrandSelectedLocalStorage } from './menuMaker';

export const createHeaders = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Accept-Language': 'es-MX',
  Authorization: `Bearer ${getAuthToken()?.accessToken}`,
});

export default function* baasRequest(request, ...params) {
  const headers = createHeaders();

  try {
    const response = yield call(request, ...params, { headers });

    return response;
  } catch (err) {
    if (err.response && err.response.status === 401) {
      removeAuthToken();
      removeCookieUser();
      removeUserStoresStoreKey();
      removeUserCurrentStore();
      removeStoreDratfKey();
      removeStoreBackupKey();
      removeBrandSelectedLocalStorage();
      removeOrganizationUser();
      yield put(push('/logged-out'));
    }

    throw err;
  }
}
