import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Box from '@mui/material/Box';

import { useSidebarLayout } from 'providers/root/SidebarLayoutProvider';
import { useFeatures } from 'FeatureProvider';

import TopNavigationBarStores from 'components/TopNavigationBarStores';

import { getValidateItemsAvailabilityFlags } from 'utils/featureFlags/menus';

import useStyles from './styles';

function Layout({ children, ...other }) {
  const location = useLocation();
  const isPathMenu = location.pathname.startsWith('/menus');

  const { openMdDrawer, modeDraft, isPublishMenuError } = useSidebarLayout();
  const { flags } = useFeatures();
  const { enableValidateItemsAvailability } = getValidateItemsAvailabilityFlags(flags);

  const classes = useStyles({
    isModeDraft: (modeDraft && isPathMenu) || (isPublishMenuError && enableValidateItemsAvailability),
    openMdSidebar: openMdDrawer,
  });

  const isInventoriesLocations = useMemo(() => {
    const isPathInventories = location.pathname.startsWith('/inventories');
    return isPathInventories;
  }, [location]);

  return (
    <Box display="flex" flexDirection="column" width="100%" {...other}>
      {!isInventoriesLocations && (
        <Box className={classes.topNavigation}>
          <TopNavigationBarStores />
        </Box>
      )}

      <Box className={clsx({ [classes.containerWorkspace]: !isInventoriesLocations })}>{children}</Box>
    </Box>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
};

export { default as ContentLayout } from './ContentLayout';
export { default as ViewPanelLayout } from './ViewPanelLayout';
export default Layout;
