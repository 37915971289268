import { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import type { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@design-system/IconButton';
import makeStyles from '@mui/styles/makeStyles';

import ChevronDown from '@experimental-components/IconsComponents/ChevronDownThin';
import Icon from '@experimental-components/TopNavigationBar/Icon';

import List from './List';
import ListItem from './ListItem';
import { NavigationType } from './types';

interface Props {
  children?: React.ReactNode;
  type: NavigationType;
  title: string;
  showChevron?: boolean;
  chevronDown: boolean;
  onClickChevron?: (element: HTMLElement) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.darkGrey[5],
    height: 56,
    padding: `0 ${theme.spacing(2)}`,
  },
  containerStore: {
    width: 'calc(100vw - 270px)',

    [theme.breakpoints.down(440)]: {
      width: 'calc(100vw - 170px)',
    },
  },
  infoSection: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(1),

    '& > h3': {
      paddingLeft: theme.spacing(2),
      fontSize: 16,
      fontWeight: 500,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    paddingLeft: theme.spacing(4),
  },
  buttonSection: {
    position: 'relative',
    height: '100%',
    borderLeft: `1px solid ${theme.palette.darkGrey[15]}`,
  },
  endIconRotate: {
    transform: 'rotate(180deg)',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  searchBoxContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
}));

function TopNavigationBar({
  children,
  chevronDown,
  type,
  title,
  showChevron = false,
  onClickChevron = () => {},
}: Props) {
  const classes = useStyles();
  const [elMenu, setMenu] = useState<HTMLElement | null>(null);
  const elementRef = useRef<HTMLElement | null>(null);

  function handleChevronClick() {
    if (elMenu) onClickChevron(elMenu);
  }

  useEffect(() => {
    setMenu(elementRef.current);
  }, []);

  return (
    <Box ref={elementRef} className={classes.container}>
      <Box
        className={clsx(classes.infoSection, { [classes.cursorPointer]: showChevron })}
        onClick={showChevron ? handleChevronClick : undefined}
      >
        <Box>
          <Icon type={type} />
        </Box>
        <Box component="h3">{title}</Box>
        {showChevron && (
          <Box ml={2}>
            <IconButton size="large">
              <ChevronDown className={clsx({ [classes.endIconRotate]: chevronDown })} />
            </IconButton>
          </Box>
        )}
      </Box>

      {children}
    </Box>
  );
}

TopNavigationBar.List = List;
TopNavigationBar.ListItem = ListItem;

export default TopNavigationBar;
