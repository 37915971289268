import makeStyles from '@mui/styles/makeStyles';
import type { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  buttonSection: {
    position: 'relative',
    height: '100%',
    borderLeft: `1px solid ${theme.palette.darkGrey[15]}`,
  },

  messageModeDraft: {
    background: theme.palette.error.light,
    display: 'flex',
    height: theme.typography.pxToRem(56),
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1rem',
    paddingInline: '2rem',
  },

  errorPublishMenu: {
    background: theme.palette.error.light,
    display: 'flex',
    height: theme.typography.pxToRem(56),
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: theme.typography.pxToRem(14),
    paddingInline: '2rem',
    color: theme.palette.darkGrey.main,
  },

  descriptionErrorPublish: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > :first-child': {
      marginRight: theme.spacing(1),
    },
  },

  publishLink: {
    textWrap: 'nowrap',
  },

  labelBtnOrganization: {
    textWrap: 'nowrap',

    [theme.breakpoints.down(440)]: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '70px',
    },
  },
}));

export default useStyles;
